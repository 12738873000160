<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-7 mb-3 mb-lg-0">
        <div class="row">
          <div class="col-lg-3 mb-2 mb-lg-0">
            <img class="footer__logo" src="../../../assets/images/logo-white.svg?v={{logoVersionNumber()}}"
              alt="Mother Earth - Logo" />
          </div>
          <div class="col-lg-3">
            <div class="footer__title" [routerLink]="['/pages/how-it-works/main']">Home</div>
          </div>
          <div class="col-lg-3">
            <div class="footer__title" [routerLink]="['/pages/about-us']">About Us</div>
          </div>
          <div class="col-lg-3">
            <div class="footer__title" [routerLink]="['/pages/how-it-works']">How it Works</div>
            <ul class="footer__list">
              <li><a [routerLink]="['/pages/faq']">FAQ</a></li>
            </ul>
          </div>

        </div>
      </div>
      <div class="col-lg-5">
        <div class="row">
          <div class="col-lg-5">
            <div class="footer__title" [routerLink]="['/pages/producers']">Producers</div>
          </div>
          <div class="col-lg-5">
            <div class="footer__title" [routerLink]="['/pages/contact-us']">Contact Us</div>
            <ul class="footer__list">
              <li><a (click)="openNewTab('/privacy-policy')">Privacy Policy</a></li>
            </ul>
          </div>
          <div class="col-lg-2">
            <div class="to-top" (click)="scrollToTop()">
              <i class="icon fa-solid fa-chevron-up"></i>
              <div class="text">To top</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row copy-text mt-2 mt-lg-5">
      <div class="col-lg-6 order-2 order-lg-1 mb-3 h8-regular"><span class="d-block d-lg-inline">Mother Earth Food ©
          2024 </span><span class="d-none d-lg-inline">|</span> <span class="d-block d-lg-inline">Powered by <a
            class="link" href="https://navego.tech" [target]="'_blank'">Navego</a></span></div>
      <div class="col-lg-6 order-1 order-lg-2 mb-3">
        <ul class="social-icons">
          <li><a href="https://www.facebook.com/motherearthfood/" target="_blank" aria-label="facebook link"><i
                class="fab fa-facebook"></i></a></li>
          <li><a href="https://www.instagram.com/mother.earth.food/" target="_blank" aria-label="instagram link"><i
                class="fab fa-instagram"></i></a></li>
        </ul>
      </div>
    </div>
  </div>
</footer>
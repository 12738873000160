<div #layoutOrder class="layout-order">
  <div class="layout-order__main" [ngClass]="{'w-100': !isMobile() && odooOrder()?.isSkipped}">

    <div class="mb-3">
      <!-- NEXT DELIVERY DATE -->
      <div class="order-info__item animated fadeIn mb-0 border-top-radius-0">
        <div class="order-info__item-icon">
          <i class="material-symbols-outlined">local_shipping</i>
        </div>
        <div class="order-info__item-value">
          <h5 class="order-info__item-title">
            @if (!deliveryInfo()) {
            <span class="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
            <span class="visually-hidden" role="status">Loading...</span>
            } @else {
            {{deliveryInfo()?.deliveryDateText}}
            }
          </h5>

          <p class="order-info__item-text h6-regular">
            @if (!deliveryInfo()) {
            <span class="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
            } @else {
            {{ deliveryInfo()?.deliveryWindow }}
            }
          </p>

          <p class="order-info__item-text h6-regular">
            @if (!deliveryInfo()) {
            <span class="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
            <span class="visually-hidden" role="status">Loading...</span>
            } @else {
            Edit before
            <span class="order-info__item-text__semibold">
              {{inpOrderSelected()?.cutoff}}
            </span>
            }
          </p>

        </div>
      </div>
      @if (odooOrder()?.id) {
      <div class="text-end">
        <a class="badge text-black" (click)="prevChangeDeliveryDate()">
          Change this Delivery Date
        </a>
      </div>
      }
    </div>

    <div class="order-info">
      <!-- DELIVERY ADDRESS -->
      @defer (on viewport; when this.signalsStoreService.sessionSignal()) {
      <div>
        <div class="order-info__item is-default mb-0">
          <div class="order-info__item-icon">
            <span class="material-symbols-outlined">home</span>
          </div>
          <div class="order-info__item-value">
            <div class="order-info__item-title">{{addressInfo()?.title}}</div>
            <span class="d-flex flex-column">
              <p class="p-0 m-0 ">
                {{ addressInfo()?.text }}
              </p>
            </span>
          </div>
        </div>
        <div class="text-end">
          <a class="badge text-black" [routerLink]="['/settings/account/deliveries']">
            Manage Deliveries
          </a>
        </div>
      </div>
      } @placeholder {
      <div class="placeholder-glow animated fadeIn">
        <div class="placeholder col-12 py-5 bg-secondary"></div>
      </div>
      }
      <!-- PAYMENT METHOD -->
      @defer (on viewport; when this.signalsStoreService.sessionSignal()) {
      <div>
        <div class="order-info__item has-action is-default mb-0">
          <div class="order-info__item-icon">
            <span class="material-symbols-outlined">credit_card</span>
          </div>
          <div class="order-info__item-value">
            <div class="order-info__item-title">Payment Method</div>
            <p class="card-text pt-0">**** ****
              **** {{ signalsStoreService.sessionSignal()?.creditCard?.number }}</p>
          </div>
          <div>
          </div>
        </div>
        <div class="text-end">
          <a class="badge text-black" [routerLink]="['/settings/billing/payment-method']">
            Manage Payments
          </a>
        </div>
      </div>
      } @placeholder {
      <div class="placeholder-glow animated fadeIn">
        <div class="placeholder col-12 py-5 bg-secondary"></div>
      </div>
      }
    </div>
    <hr>

    <div class="text-center text-sm-start">
      @if (odooOrder()?.isSkipped) {
      <h2 class="h2-semibold mb-2">You've skipped your order!</h2>
      <p class="">You will not be receiving your order this week.</p>
      } @else if(!marketStatus().isOpen) {
      <div class="closed-market-container animated fadeIn mt-0 mb-3">
        <div class="alert" role="alert">
          <span class="material-symbols-outlined mat-icon-fill icon">
            calendar_today
          </span>
          <div class="text-container">

            <p class="header">{{closedMarket().title}}</p>
            <p class="legend">{{closedMarket().legend}}</p>
          </div>
        </div>
      </div>
      }
    </div>

    @if (isTemporaryRouteChange()) {
    <app-empty-message [message]="temporaryRouteChangeMessage()"></app-empty-message>
    }

    @if(!marketStatus().isOpen) {
    <div class="closed-market-container animated fadeIn mt-0 mb-3">
      <div class="alert" role="alert">
        <span class="material-symbols-outlined mat-icon-fill icon">
          calendar_today
        </span>
        <div class="text-container">

          <p class="header">{{closedMarket().title}}</p>
          <p class="legend">{{closedMarket().legend}}</p>
        </div>
      </div>
    </div>
    }


    <!-- Undo delivery skip -->
    @if (isCheckedSkipWeekDelivery || odooOrder()?.isSkipped) {
    <section class="d-flex">
      <div class="animated fadeIn">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
            [(ngModel)]="isCheckedSkipWeekDelivery" (change)="onChangeSkipWeekDelivery()">
          <label class="form-check-label animated fadeIn h7-regular" for="flexSwitchCheckDefault">Skipped</label>
        </div>
      </div>
    </section>
    }

    <!-- Subscriptions -->
    <section class="row justify-content-between">
      <!-- REVIEW CART - PENDING CHANGES -->
      @if (hasPendingChanges()) {
      <div class="alert alert-warning d-flex align-items-center animated fadeIn" role="alert">
        <i class="fa fa-info-circle text-primary me-2"></i>
        <div class="h7-regular">
          Review your cart, then click <b>Update/Submit Order</b> to reserve all items you've selected
        </div>
      </div>
      }

      @if (products() && products()?.subscription && products()?.subscription?.length) {
      <div class="col-6 mb-2 mt-2">
        <h6 class="h6-regular">
          Subscriptions
        </h6>
      </div>
      <div class="col-6 mb-2 mt-2 text-end ">
        <a [routerLink]="['/settings/account/subscriptions']" class="badge text-black pe-0">Manage Subscriptions</a>
      </div>
      }

      <div class="col-12 card border-0 animated"
        [ngClass]="{'opacity-25 fadeIn': isCheckedSkipWeekDelivery && isConfirmedSkipWeekDelivery || odooOrder()?.isSkipped}">
        @if (products() && products()?.subscription && products()?.subscription?.length) {
        @for (product of products().subscription; let productIdx = $index; track productIdx) {
        <div class="product-la-carte">
          <div class="product-la-carte__img">
            <a [routerLink]="['/shop/'+(product?.productUrl || '')]">
              <img [src]="product?.img || 'assets/images/product-placeholder.webp'" class="thumb-img"
                (error)="handleImageError($event)">
            </a>
          </div>
          <div class="product-la-carte__left">
            <!-- <div class="row row-cols-auto"> -->
            <!-- <div class="col"> -->
            <!-- <div class="card-body p-0"> -->
            <a class="card-title" [routerLink]="['/shop/'+(product?.productUrl || '')]">
              <h5 class="card-title">{{ product.name }}</h5>
            </a>
            <p class="card-text pt-0 mb-0">
              @if (product?.variant?.attribute?.name) {
              {{ product?.variant?.attribute?.name }}: {{ product?.variant?.attribute?.value?.name }}
              } @else if (product?.package) {
              Package: {{ product?.package?.name }}
              }
              @if (product?.subscription) {
              {{ ((product?.package?.name || product?.variant?.attribute?.name) ? ' | ' : '') + 'Frequency: ' +
              product.subscription?.frequency?.name }}
              }
            </p>
            @if (product?.presentation) {
            <p class="card-text pt-0 my-1">
              {{ product.presentation.quantity }}
              {{ product.presentation.type }}
            </p>
            }
            <div class="row-field animated fadeIn">
              @if (mapQuantityChanges.get(product.firebaseMapKey + '_1')?.validations?.wasValueHigher10) {
              <small class="row-field__label">How many?</small>
              }
              <div class="row-field__input">
                <mat-form-field class="rounded-selector d-flex" subscriptSizing="dynamic">
                  @if (mapQuantityChanges.get(product.firebaseMapKey + '_1')?.validations?.wasValueHigher10){
                  <input type="text" matInput appNumberRestriction [minNumber]="1" [maxNumber]="100"
                    (keyup)="onKeyUp(product.firebaseMapKey || null, $event, true)" [value]="product.quantity">
                  } @else {
                  <mat-select
                    [disabled]="isEditingBundle && (bundleCloned()?.id+(bundleCloned()?.isSubscription || false).toString()) == (product?.id+product?.isSubscription)"
                    [value]="product.quantity" (selectionChange)="onQuantityChange(product, $event, true)">
                    @for (number of [1, 2, 3, 4, 5, 6, 7, 8, 9]; track $index) {
                    <mat-option [value]="number">{{ number }}</mat-option>
                    }
                    <mat-option value="10">10+</mat-option>
                  </mat-select>
                  }
                </mat-form-field>
              </div>
              <div class="row-field__action">
                @if (mapQuantityChanges.get(product.firebaseMapKey + '_1')?.validations?.isValueChanged) {
                <button type="button" class="btn btn-sm btn-primary mt-2 animated fadeIn"
                  (click)="updateQuantity(product, true)"
                  [disabled]="checkQuantityChange(product.firebaseMapKey, true)">
                  Update
                </button>
                }
              </div>
              @if (product?.hasPendingChanges && !product?.isInOdooOrder) {
              <div class="border-start btn-delete__wrap">
                <div class="btn-delete" (click)="deleteProduct(product, true)">
                  <span class="d-none d-sm-flex">Delete</span>
                  <span class="d-sm-none material-symbols-outlined">close</span>
                </div>
              </div>
              }
              @if (product?.bundle && product?.bundle?.items?.length) {

              <div class="border-start btn-delete__wrap">
                <small class="text-decoration-underline cursor-pointer">
                  @if (product.bundle.isLocked && !product.bundle.hasPremiumAddables) {
                  <span (click)="toggleCollapse(product.id+'_1')" class="link-underline">What's inside</span>
                  } @else {

                  @if (!isEditingBundle) {
                  <span (click)="gotToEditBundle(product, BundleEditionTypes.subscription)">Edit Bundle</span>
                  } @else {
                  <span (click)="gotToEditBundle(product, BundleEditionTypes.subscription)" class="link-underline">
                    Edit Bundle
                  </span>
                  }
                  }

                </small>
              </div>
              }

              @if (product?.isInOdooOrder) {
              <div class="col border-start btn-delete__wrap">
                <small class="text-decoration-underline cursor-pointer" (click)="skipProduct(product)">Skip</small>
              </div>
              }

            </div>
            <!-- </div> -->

            <!-- </div> -->
            <!-- </div> -->
          </div>
          <div class="product-la-carte__right">
            <h3 class="price">{{
              ((bundleCloned()?.id+(bundleCloned()?.isSubscription || false).toString()) ==
              (product.id+(product?.isSubscription || false).toString()) ? bundleCloned()?.totalPrice :
              product.totalPrice) | currency
              }}</h3>
            @if (product.hasPendingChanges) {
            <span class="badge bg-warning">Not yet saved</span>
            } @else {
            <span class="badge bg-success">In your order</span>
            }
          </div>
        </div>

        @if (product?.bundle && product?.bundle?.items?.length && bundleModifyType() === bundleModifyTypes.default) {
        <div class="row animated fadeIn" [ngbCollapse]="collapseStatuses[product.id+'_1']">
          <div class="col-12 m-3 bg-light">
            @for (bundleProduct of (getBundleEditable(product)?.bundle?.items); track $index) {
            @if (!bundleProduct.isRemoved) {
            <div class="row d-flex justify-content-between p-3 py-1 animated fadeIn">
              <div class="col-4">
                <h6 class="card-title">{{ bundleProduct.name }} {{bundleProduct.packageName ? (' | ' +
                  bundleProduct.packageName) : (bundleProduct.display ? (' | ' + bundleProduct.display) : '')}}</h6>
                @if (bundleProduct?.presentation) {
                <small class="card-text">
                  {{ bundleProduct.presentation.quantity }}
                  {{ bundleProduct.presentation.type }}
                </small>
                }
              </div>
              @if (!product?.bundle.isLocked) {
              <div class="col-4 d-flex justify-content-center align-content-center flex-wrap">
                <mat-form-field class="rounded-selector d-flex" subscriptSizing="dynamic">
                  @if (bundleProduct?.quantity >= 10) {
                  <input type="text" matInput [value]="bundleProduct.quantity"
                    (input)="onChangeInputBundleItem(bundleProduct, $event)">
                  } @else {
                  <mat-select [value]="bundleProduct.quantity"
                    (selectionChange)="onChangeQuantityBundleItem(bundleProduct, $event)">
                    @for (number of [1, 2, 3, 4, 5, 6, 7, 8, 9]; track $index) {
                    <mat-option [value]="number">{{ number }}</mat-option>
                    }
                    <mat-option value="10">10+</mat-option>
                  </mat-select>
                  }
                </mat-form-field>

              </div>
              <div class="col-4 d-flex justify-content-end align-content-center flex-wrap">
                <div class="row">
                  <div class="col-6 text-nowrap">
                    <h4 class="fw-bold m-0 p-0">{{ bundleProduct.price | currency }}</h4>
                  </div>
                  <div class="col-6 d-flex justify-content-end  align-content-center flex-wrap">
                    <i (click)="removeProductFromBundle(product.id, bundleProduct, product)" class="fa fa-trash"
                      aria-hidden="true"></i>
                  </div>
                </div>
              </div>
              }

            </div>
            }
            }
            @if (bundlesRemoved().size && bundlesRemoved().has(product.id)) {
            @for (bundleProduct of bundlesRemoved().get(product.id)?.values(); let bundleProductIdx = $index; track
            bundleProductIdx) {
            <div class="row d-flex justify-content-between p-3 py-1 opacity-50 animated fadeIn">
              <div class="col-6">
                <h6 class="card-title">{{ bundleProduct.name }} {{bundleProduct.packageName ? (' | ' +
                  bundleProduct.packageName) : (bundleProduct.display ? (' | ' + bundleProduct.display) : '')}}</h6>
                @if (bundleProduct?.presentation) {
                <small class="card-text">
                  {{ bundleProduct.presentation.quantity }}
                  {{ bundleProduct.presentation.type }}
                </small>
                }
              </div>
              <div class="col-6 d-flex justify-content-end align-content-center flex-wrap">
                <div class="row">
                  <div class="col-12 d-flex justify-content-end align-content-center flex-wrap">
                    <span (click)="restoreProductFromBundle(product.id,bundleProduct, product)"
                      class=" btn text-nowrap text-decoration-underline">Restore product</span>
                  </div>
                </div>
              </div>
            </div>
            }
            }
          </div>

          <div class="col-12 d-flex justify-content-center align-content-center flex-wrap">
            @if (product?.bundle.isLocked) {
            <button class="btn btn-sm btn-outline-secondary mx-2" (click)="toggleCollapse(product.id+'_1')">
              Close
            </button>
            } @else {
            <button class="btn btn-sm btn-outline-secondary mx-2" (click)="discardBundleChanges(product, true)">
              Discard changes
            </button>
            <button [disabled]="!hasPendingBundleChanges()" class="btn btn-sm btn-primary"
              (click)="saveBundleChanges(true)">
              Update bundle
            </button>
            }
          </div>
        </div>

        }

        }
        }
        @if (isCheckedSkipWeekDelivery && isConfirmedSkipWeekDelivery || odooOrder()?.isSkipped) {
        <div class="card-img-overlay text-center d-flex gap-3 flex-column"></div>
        }
      </div>
    </section>

    <hr>

    <!-- A la carte -->
    @if (products() && products()?.common && products()?.common?.length) {
    <section>
      <div class="mb-2 mt-2">
        <p>A la carte</p>
      </div>

      @for (product of products().common; track $index) {
      <div class="product-la-carte">
        <div class="product-la-carte__img">
          <a [routerLink]="['/shop/'+(product?.productUrl || '')]">
            <img [src]="product?.img || 'assets/images/product-placeholder.webp'" class="thumb-img"
              (error)="handleImageError($event)">
          </a>
        </div>
        <div class="product-la-carte__left">
          <a class="card-title" [routerLink]="['/shop/'+(product?.productUrl || '')]">
            {{ product.name }}
          </a>
          @if (product?.variant?.attribute?.name) {
          <p class="card-text pt-0 mb-0">
            {{ product?.variant?.attribute?.name }}: {{ product?.variant?.attribute?.value?.name }}
          </p>
          } @else if (product?.package) {
          <p class="card-text pt-0 mb-0">
            Package: {{ product?.package?.name }}
          </p>
          }
          @if (product?.presentation) {
          <p class="card-text my-1">
            {{ product.presentation.quantity }}
            {{ product.presentation.type }}
          </p>
          <!-- <p class="card-text"><small class="text-body-secondary">Last updated 3 mins ago</small></p> -->
          }
          <div class="row-field animated fadeIn">
            @if (mapQuantityChanges.get(product.firebaseMapKey + '_0')?.validations?.wasValueHigher10) {
            <label class="row-field__label">How many?</label>
            }
            <div class="row-field__input">
              <mat-form-field class="rounded-selector d-flex" subscriptSizing="dynamic">
                @if (showInputQuantity(product.firebaseMapKey, false)) {
                <input type="text" matInput appNumberRestriction [minNumber]="1" [maxNumber]="100"
                  (keyup)="onKeyUp(product.firebaseMapKey || null, $event, false)"
                  [value]="mapQuantityChanges.get(product.firebaseMapKey + '_0')?.quantity"
                  [readonly]="product.isPreOrder">
                } @else {
                <mat-select [value]="product.quantity" (selectionChange)="onQuantityChange(product, $event, false)"
                  [disabled]="product.isPreOrder">
                  @for (number of [1, 2, 3, 4, 5, 6, 7, 8, 9]; track $index) {
                  <mat-option [value]="number">{{ number }}</mat-option>
                  }
                  <mat-option value="10">10+</mat-option>
                </mat-select>
                }
              </mat-form-field>
            </div>
            <div class="row-field__action">
              @if (mapQuantityChanges.get(product.firebaseMapKey + '_0')?.validations?.isValueChanged &&
              !product.isPreOrder) {
              <button type="button" class="btn btn-primary" (click)="updateQuantity(product, false)"
                [disabled]="checkQuantityChange(product.firebaseMapKey, false)">
                Update
              </button>
              }
            </div>
            @if(!product.isPreOrder) {
            <div class="border-start btn-delete__wrap">
              <div class="btn-delete" (click)="deleteProduct(product)">
                <span class="d-none d-sm-flex">Delete</span>
                <span class="d-sm-none material-symbols-outlined">close</span>
              </div>
            </div>
            }
            @if (product?.bundle && product?.bundle?.items?.length) {

            <div class="border-start btn-delete__wrap">
              <small class="text-decoration-underline cursor-pointer">
                @if (product.bundle.isLocked && !product.bundle.hasPremiumAddables) {
                <span (click)="toggleCollapse(product.id+'_0')" class="link-underline">What's inside</span>
                } @else {

                @if (!isEditingBundle) {
                <span (click)="gotToEditBundle(product, BundleEditionTypes.aLaCarte)">Edit Bundle</span>
                } @else {
                <span (click)="gotToEditBundle(product, BundleEditionTypes.aLaCarte)" class="link-underline">
                  Edit Bundle
                </span>
                }
                }

              </small>
            </div>
            }

          </div>
        </div>
        <div class="product-la-carte__right">
          <h3 class="price">{{
            ((bundleCloned()?.id+(bundleCloned()?.isSubscription || false).toString()) ==
            (product.id+(product?.isSubscription || false).toString()) ? bundleCloned()?.totalPrice :
            product.totalPrice) | currency
            }}</h3>
          @if (product.hasPendingChanges) {
          <span class="badge bg-warning">Not yet saved</span>
          } @else {
          <span class="badge bg-success">In your order</span>
          }
        </div>
      </div>

      @if (product.isPreOrder) {
      <app-empty-message [message]="preOrderedProductDisclaimer()"></app-empty-message>
      }

      @if (product?.bundle && product?.bundle?.items?.length && bundleModifyType() === bundleModifyTypes.default) {
      <div class="row animated fadeIn" [ngbCollapse]="collapseStatuses[product.id+'_0']">
        <div class="col-12 m-3 bg-light">
          @for (bundleProduct of (getBundleEditable(product)?.bundle?.items); track $index) {
          @if (!bundleProduct.isRemoved) {
          <div class="row d-flex justify-content-between p-3 py-1 animated fadeIn">
            <div class="col-4">
              <h6 class="card-title">{{ bundleProduct.name }} {{bundleProduct.packageName ? (' | ' +
                bundleProduct.packageName) : (bundleProduct.display ? (' | ' + bundleProduct.display) : '')}}</h6>
              @if (bundleProduct?.presentation) {
              <small class="card-text">
                {{ bundleProduct.presentation.quantity }}
                {{ bundleProduct.presentation.type }}
              </small>
              }
            </div>
            @if (!product?.bundle.isLocked) {
            <div class="col-4 d-flex justify-content-center align-content-center flex-wrap">
              <mat-form-field class="rounded-selector d-flex" subscriptSizing="dynamic">
                @if (bundleProduct?.quantity >= 10) {
                <input type="text" matInput [value]="bundleProduct.quantity"
                  (input)="onChangeInputBundleItem(bundleProduct, $event)">
                } @else {
                <mat-select [value]="bundleProduct.quantity"
                  (selectionChange)="onChangeQuantityBundleItem(bundleProduct, $event)">
                  @for (number of [1, 2, 3, 4, 5, 6, 7, 8, 9]; track $index) {
                  <mat-option [value]="number">{{ number }}</mat-option>
                  }
                  <mat-option value="10">10+</mat-option>
                </mat-select>
                }
              </mat-form-field>
            </div>
            <div class="col-4 d-flex justify-content-end align-content-center flex-wrap">
              <div class="row">
                <div class="col-6 text-nowrap">
                  <h4 class="fw-bold m-0 p-0">{{ bundleProduct.price | currency }}</h4>
                </div>
                <div class="col-6 d-flex justify-content-end  align-content-center flex-wrap">
                  <i (click)="removeProductFromBundle(product.id, bundleProduct, product)" class="fa fa-trash"
                    aria-hidden="true"></i>
                </div>
              </div>
            </div>
            }

          </div>
          }
          }
          @if (bundlesRemoved().size && bundlesRemoved().has(product.id)) {
          @for (bundleProduct of bundlesRemoved().get(product.id)?.values(); let bundleProductIdx = $index; track
          bundleProductIdx) {
          <div class="row d-flex justify-content-between p-3 py-1 opacity-50 animated fadeIn">
            <div class="col-6">
              <h6 class="card-title">{{ bundleProduct.name }} {{bundleProduct.packageName ? (' | ' +
                bundleProduct.packageName) : (bundleProduct.display ? (' | ' + bundleProduct.display) : '')}}</h6>
              @if (bundleProduct?.presentation) {
              <small class="card-text">
                {{ bundleProduct.presentation.quantity }}
                {{ bundleProduct.presentation.type }}
              </small>
              }
            </div>
            <div class="col-6 d-flex justify-content-end align-content-center flex-wrap">
              <div class="row">
                <div class="col-12 d-flex justify-content-end align-content-center flex-wrap">
                  <span (click)="restoreProductFromBundle(product.id,bundleProduct, product)"
                    class=" btn text-nowrap text-decoration-underline">Restore product</span>
                </div>
              </div>
            </div>
          </div>
          }
          }
        </div>

        <div class="col-12 d-flex justify-content-center align-content-center flex-wrap">
          @if (product?.bundle.isLocked) {
          <button class="btn btn-sm btn-outline-secondary mx-2" (click)="toggleCollapse(product.id+'_0')">
            Close
          </button>
          } @else {
          <button class="btn btn-sm btn-outline-secondary mx-2" (click)="discardBundleChanges(product, false)">
            Discard changes
          </button>
          <button [disabled]="!hasPendingBundleChanges()" class="btn btn-sm btn-primary"
            (click)="saveBundleChanges(false)">
            Update bundle
          </button>
          }
        </div>
      </div>
      }
      }
    </section>
    <hr>
    }

    @if (carouselFavoritesProducts()?.length) {
    <div class="product-highlight">
      <h3 class="product-highlight__title">Your Favorites</h3>
      <div class="product-highlight__body">
        <app-carousel [items]="carouselFavoritesProducts()"></app-carousel>
      </div>
    </div>
    }
    @if (carouselBuyAgainProducts()?.length) {
    <div class="product-highlight">
      <h3 class="product-highlight__title">Buy Again</h3>
      <div class="product-highlight__body">
        <app-carousel [items]="carouselBuyAgainProducts()"></app-carousel>
      </div>
    </div>
    }
    @if (carouselSuggestedProducts()?.length) {
    <div class="product-highlight">
      <h3 class="product-highlight__title">Other people also added</h3>
      <div class="product-highlight__body">
        <app-carousel [items]="carouselSuggestedProducts()"></app-carousel>
      </div>
    </div>
    }
  </div>

  @if (odooOrder()?.isSkipped) {
  <!-- SKIPPED ORDER -->
  <div class="layout-order__sidebar p-4 animated fadeInRight" [ngClass]="{'d-none': !isMobile()}">
    <!-- INFORMATION MESSAGE -->
    @if (odooOrder()?.invoiceDetails?.donation?.amount) {
    <div class="card border-0 mt-4 animated fadeInRight">
      <div class="row justify-content-between">
        <div class="col text-center">
          <h5>Thank you for your donation!</h5>
          <p>
            You've donated
            <b>{{ (odooOrder()?.invoiceDetails?.donation?.amount) | currency }}</b>
            to help us feed our local community.
            <a routerLink="/pages/about-us/donations" target="_blank" class="fw-bold">Learn more</a>
            about our donation program.
          </p>
        </div>
      </div>
    </div>
    }
  </div>
  } @else if (!isConfirmedSkipWeekDelivery) {
  <!-- ORDER SUMMARY -->
  <div class="layout-order__sidebar animated fadeInRight">
    <div>
      <div>
        <h2 class="h2-semibold text-center">Order Summary</h2>
      </div>

      <div class="d-grid gap-2 mt-2 mb-3">
        @if (!isAllowedOrder()) {
        <div class="d-flex align-items-center alert alert-warning animated fadeIn" role="alert">
          <i class="fa fa-info-circle me-2"></i>
          <div class="h7-regular">
            You have not yet reached the minimum sub-total order amount <b>({{minOrder() | currency}})</b> to be able to
            save your order.
          </div>
        </div>
        }

      </div>
    </div>

    <!-- BILLING DETAIL -->
    <div class="card border-0 fw-bold">
      <!-- Subtotal -->
      <div class="row row-cols-2 justify-content-between">
        <div class="col">Subtotal</div>
        <div class="col text-end animated fadeIn">{{ subTotal().toFixed(2) | currency }}</div>
      </div>

      <!-- Get free delivery -->
      @if (deliveryFee() && showFreeDeliverySlider()) {
      <div class="row pt-2">
        <section class="d-flex align-items-center gap-2 justify-content-around order-scale">
          <small class="">Get free delivery</small>
          <mat-progress-bar mode="determinate" [value]="percentMinSpendForFreeDelivery()"></mat-progress-bar>
          <small class="">{{ minSpendForFreeDelivery() | currency }}</small>
        </section>
      </div>
      }

      <!-- Taxes -->
      <div class="row row-cols-2 pt-2 justify-content-between">
        <div class="col">Taxes</div>
        <div class="col text-end animated fadeIn">{{ taxes().toFixed(2) | currency }}</div>
      </div>

      <!-- Delivery Fee -->
      <div class="row row-cols-2 pt-2 justify-content-between">
        <div class="col">Delivery</div>
        <div class="col text-end animated fadeIn">
          @if (deliveryFee()) {
          <span class="animated fadeIn">{{ deliveryFee() | currency }}</span>
          } @else {
          <span class="animated fadeIn">FREE</span>
          }
        </div>
      </div>

      <!-- Get free delivery -->
      @if (hasMembershipFlow() && !signalsStoreService.sessionSignal()?.settings?.hasMembership) {
      <div class="text-end">
        <a [routerLink]="['/settings/billing/membership']" class="badge text-black" target="_blank">Get Free Delivery
          with Roots Rewards</a>
      </div>
      }
      <hr>

      <!-- Tip -->
      @if (shouldShowTipsAndDonations()) {
      <div class="row row-cols-2 pt-2 justify-content-between animated fadeInRight">
        <div class="col">
          Tip
          @if (odooOrder()?.paymentDetails?.tip?.isRecurrent) {
          <i class="fa fa-refresh" aria-hidden="true"></i>

          }
        </div>

        @if (tipAmount()) {
        <div class="col text-end animated fadeInRight">
          <div class="row d-flex justify-content-end">
            <div class="col-4 d-flex justify-content-end">
              <a (click)="openModalTip(updateTip)">Edit</a>
            </div>
            <div class="col-4 d-flex justify-content-center">
              {{ tipAmount() | currency }}
            </div>
            <div class="col-2">
              <i (click)="deleteTip()" class="fa fa-trash" aria-hidden="true"></i>
            </div>
          </div>
        </div>
        } @else {
        <div class="col text-end animated fadeInRight">
          <a (click)="openModalTip(createTip)" href="javascript:void(0)" class="badge text-black">Add</a>
        </div>
        }
      </div>
      }
      <!-- Donation -->
      @if (donationAmountVoluntary() && shouldShowTipsAndDonations()) {
      <div class="row row-cols-2 pt-2 justify-content-between animated fadeInRight">
        <div class="col">Donation
          @if (odooOrder()?.paymentDetails?.donation?.isRecurrent) {
          <i class="fa fa-refresh" aria-hidden="true"></i>
          }
        </div>
        <div class="col text-end">
          <div class="row d-flex justify-content-end">
            <div class="col-4 d-flex justify-content-end">
              <a (click)="openModalDonation(updateDonation)">Edit</a>
            </div>
            <div class="col-4 d-flex justify-content-center">
              {{ donationAmountVoluntary() | currency }}
            </div>
            <div class="col-2">
              <i (click)="deleteDonationVoluntary()" class="fa fa-trash" aria-hidden="true"></i>
            </div>
          </div>
        </div>
      </div>
      }

      <hr>

      <!-- Friend Referral -->
      @if (odooOrder()?.paymentDetails?.friendReferral) {
      <div class="row row-cols-2 justify-content-between">
        <div class="col">Friend Referral</div>
        <div class="col text-end">$0.00</div>
      </div>
      }

      <!-- Credits -->
      @if (creditsAmount()) {
      <div class="row row-cols-2 pt-2 justify-content-between">
        <div class="col">Applied credits</div>
        <div class="col text-end">{{ (creditsAmount()) | currency }}</div>
      </div>
      <!-- Credits -->
      <div class="row row-cols-2 pt-2 justify-content-between">
        <div class="col">Credit balance</div>
        <div class="col text-end">{{ (creditsBalance()) | currency }}</div>
      </div>
      }

      <!-- COUPON -->
      @if (!this.hasPendingChanges() && odooOrder()?.paymentDetails?.coupons?.length) {
      <div class="row row-cols-2 pt-2 justify-content-between animated fadeInRigth">
        <div class="col">{{ odooOrder()?.paymentDetails?.coupons[0]?.discount }}</div>
        <div class="col text-end">{{ odooOrder()?.paymentDetails?.coupons[0]?.amount ?
          (odooOrder()?.paymentDetails?.coupons[0]?.amount | currency) : 'FREE' }}</div>
      </div>
      <hr>
      } @else if (this.hasPendingChanges() && odooOrder()?.paymentDetails?.coupons?.length) {
      <div class="row pt-2 justify-content-between animated fadeInRigth">
        <div class="alert alert-warning d-flex align-items-center animated fadeIn" role="alert">
          <i class="fa fa-info-circle text-primary me-2"></i>
          <div class="h7-regular">
            Coupon shown after order submitted
          </div>
        </div>
      </div>
      <hr>
      }

      <!-- Total -->
      <div class="row row-cols-2 justify-content-between fw-bold fs-3">
        <div class="col">Total</div>
        <div class="col text-end fs-3 animated fadeIn">{{ total() | currency }}
        </div>
      </div>
    </div>

    <section>
      @if (odooOrder()?.id || (products()?.common?.length || products()?.subscription?.length)) {
      <div class="coupon-action">
        <div class="coupon-input">
          <div class="form">
            <!-- <label for="couponCode">Enter coupon or gift certificate code</label> -->
            <input type="text" [(ngModel)]="coupon" placeholder="Enter coupon or gift certificate code"
              class="form-control" id="couponCode">
          </div>
        </div>
        <div>
          <button (click)="applyCoupon()" class="btn btn-sm btn-outline-secondary">Apply</button>
        </div>
      </div>
      }

      @if (!donationAmountVoluntary() && hasVoluntaryDonationFlow()) {
      <div class="donation-action animated fadeInUp">
        <div class="">
          <small class="h7-regular">
            <a class="fw-bold" routerLink="/pages/about-us/donations" target="_blank">Learn more</a> about our donation
            program
          </small>
        </div>
        <div>
          <button class="btn btn-sm btn-outline-secondary fs-6" (click)="openModalDonation(createDonation)">Make a
            Donation
          </button>
        </div>
      </div>
      }
    </section>

    <div #orderActionWrap class="order-action-wrap d-grid">
      @if(isAllowedOrder() && hasPendingChanges()){
      <div class="order-action-alert alert alert-danger">
        <span class="alert-icon material-symbols-outlined">warning</span>IMPORTANT: You must click the Update/Submit
        order button on your shopping cart in order to place your order
      </div>
      }
      @if (odooOrder()?.id) {
      <button type="button" class="btn btn-secondary"
        [disabled]="!isAllowedOrder() || !hasPendingChanges() || isUpdating()" (click)="updateOrder(odooOrder()?.id)">
        {{
        isUpdating()
        ? 'Updating Order...'
        : 'Update/Submit Order'
        }}
      </button>
      } @else {
      <button type="button" class="btn btn-secondary"
        [disabled]="!isAllowedOrder() || isUpdating() || !hasPendingChanges()" (click)="updateOrder()">
        {{
        isUpdating()
        ? 'Updating Order...'
        : 'Update/Submit Order'
        }}
      </button>
      }
    </div>

    <!-- Skip deliveries -->
    @if (hasPreorderedProducts()) {
    <section class="pt-4">
      <app-empty-message [message]="preOrdersSkipDisclaimer()"></app-empty-message>
    </section>
    } @else {
    @if (!(isCheckedSkipWeekDelivery || odooOrder()?.isSkipped) && odooOrder()?.id) {
    <section class="d-flex justify-content-center">
      <div class="animated fadeIn">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault"
            [(ngModel)]="isCheckedSkipWeekDelivery" (change)="onChangeSkipWeekDelivery()">
          <label class="form-check-label animated fadeIn h7-regular" for="flexSwitchCheckDefault">Skip this week's
            delivery</label>
        </div>
      </div>
    </section>
    }
    }

  </div>
  }
</div>
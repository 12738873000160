import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, Signal, ViewChild, computed, inject, signal } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderHistoryService } from '../order-and-billing.service';
import { BillingDetailItem, OrderHistoryDetailResponse, OrderHistoryProductResponse } from '../order-and-billing.types';
import { ResolutionService } from '../../../../shared/resolution.service';
import { NotificationService } from '../../../../shared/notification/notification.service';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
  selector: 'app-order-detail',
  standalone: true,
  imports: [CommonModule, MatProgressBar],
  providers: [OrderHistoryService],
  templateUrl: './order-detail.component.html',
  styleUrl: './order-detail.component.scss'
})
export class OrderDetailComponent implements OnInit {

  @ViewChild('contentToPrint') contentToPrint!: ElementRef;

  private activatedRoute = inject(ActivatedRoute);
  private orderHistoryService = inject(OrderHistoryService);
  private resolutionService = inject(ResolutionService);
  private notificationService = inject(NotificationService);

  orderDetail: Signal<OrderHistoryDetailResponse | null> = computed(() => this.orderHistoryService.orderHistoryDetail());
  billingDetail: Signal<BillingDetailItem[]> = computed(() => this.orderHistoryService.billinDetail());
  isMobile = computed(() => this.resolutionService.isMobile());

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      const id = +params["order"]
      this.orderHistoryService.getOrderHistoryDetail(id);
    })
  }

  printInvoice() {
    if (!this.orderDetail()?.payment?.invoiceURL) return this.notificationService.show({ text: 'There is no invoice to see', type: 'warning' })
    window.open(this.orderDetail()?.payment.invoiceURL, '_blank');
  }

  viewMoreItems(delivery: OrderHistoryProductResponse) {
    delivery.isViewAllItems.update(value => !value);
  }

}

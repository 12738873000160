<div class="main-container__full animated fadeIn">
	<nav aria-label="breadcrumb">
		<ol class="breadcrumb">
			<li class="breadcrumb-item" aria-current="page">
				<a [routerLink]="['/shop']" class="icon-back"></a>
				Home
			</li>
			<li class="breadcrumb-item active" aria-current="page">
				<a href="javascript:void(0)" class="fw-bolder text-primary">{{productListType}}</a>
			</li>
		</ol>
	</nav>
	<h1 class="fw-bolder animated fadeInLeft mt-4">
		{{productListType}}
	</h1>
	<section class="d-grid col-12 my-4">
		<h5 class="fw-bolder text-body-tertiary opacity-50 animated fadeIn">A la carte</h5>
			<div class="product-card-grid">
        <div class="row">
				@defer (on immediate; when productsService.productsSelectionListSignal().onetime.length) {
					@if (productsService.productsSelectionListSignal().onetime) {

						@for (product of productsService.productsSelectionListSignal().onetime; track product.id) {
							@defer (on viewport; prefetch on immediate) {
								<div class="col-xl-3 col-lg-4 col-sm-6 animated fadeIn">
									<div class="product-card-grid__item">
										<app-product-card [card]="{ product }"></app-product-card>
									</div>
								</div>
							}
							@placeholder {
								<div class="col-xl-3 col-lg-4 col-sm-6 animated fadeInUp fadeIn mb-3">
									<div class="card position-relative border p-3 row-gap-3" aria-hidden="true">
										<span class="placeholder bg-secondary col-12"></span>
										<span class="placeholder bg-secondary col-6"></span>
										<span class="placeholder bg-secondary col-6"></span>
										<div class="placeholder bg-secondary col-12" style="height: 100px;"></div>
										<div class="card-body">
											<h5 class="card-title placeholder-glow">
											<span class="placeholder bg-secondary col-6"></span>
											</h5>
											<p class="card-text placeholder-glow">
											<span class="placeholder bg-secondary col-7"></span>
											<span class="placeholder bg-secondary col-4"></span>
											<span class="placeholder bg-secondary col-4"></span>
											<span class="placeholder bg-secondary col-6"></span>
											<span class="placeholder bg-secondary col-8"></span>
											</p>
											<a class="btn btn-secondary disabled placeholder col-12" aria-disabled="true"></a>
										</div>
									</div>
								</div>
							}
						}
					}
				}
				@placeholder { LOADING... }
        </div>
			</div>
	</section>
	<section class="d-grid col-12 my-4">
		<h5 class="fw-bolder text-body-tertiary opacity-50 animated fadeIn">Subscriptions</h5>

		<div class="container-fluid">
			<div
				class="row product-card-grid">
				@defer (on immediate; when productsService.productsSelectionListSignal().subscription.length) {
					@if (productsService.productsSelectionListSignal().subscription) {

						@for (product of productsService.productsSelectionListSignal().subscription; track product.id) {
							@defer (on viewport; prefetch on immediate) {
								<div class="col-xxl-3 col-xl-6 col-lg-6 animated fadeIn">
									<div class="product-card-grid__item">
										<app-product-card [card]="{ product }"></app-product-card>
									</div>
								</div>
							}
							@placeholder {
								<div class="col-xxl-3 col-xl-6 col-lg-6 animated fadeInUp fadeIn">
									<div class="card position-relative border p-3 row-gap-3" aria-hidden="true">
										<span class="placeholder bg-secondary col-12"></span>
										<span class="placeholder bg-secondary col-6"></span>
										<span class="placeholder bg-secondary col-6"></span>
										<div class="placeholder bg-secondary col-12" style="height: 100px;"></div>
										<div class="card-body">
											<h5 class="card-title placeholder-glow">
											<span class="placeholder bg-secondary col-6"></span>
											</h5>
											<p class="card-text placeholder-glow">
											<span class="placeholder bg-secondary col-7"></span>
											<span class="placeholder bg-secondary col-4"></span>
											<span class="placeholder bg-secondary col-4"></span>
											<span class="placeholder bg-secondary col-6"></span>
											<span class="placeholder bg-secondary col-8"></span>
											</p>
											<a class="btn btn-secondary disabled placeholder col-12" aria-disabled="true"></a>
										</div>
									</div>
								</div>
							}
						}
					}

				}
				@placeholder { LOADING... }
			</div>
		</div>
	</section>
</div>

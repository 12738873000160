<section class="page-wrap animated fadeIn">
  <div class="container">
    <h2 class="section-title"><span>Donation</span></h2>

    <div class="row mb-5">
      <div class="col-sm-5">
        <img src="assets/images/landing/donation/hurricane.webp" class="img-fluid mb-sm-0 mb-5" alt="Donate image">
      </div>
      <div class="col-sm-7">
        <h1 class="title__main text-secondary">AFTER THE STORM:</h1>
        <h2 class="mb-3">Help Sustain and Rebuild Thriving Food Systems</h2>
        <p>In late September, <strong>Hurricane Helene</strong> devastated our mountain home, affecting Asheville and
          surrounding communities. Since then, we’ve been out and about, knocking on doors, meeting our neighbors
          face-to-face, and understanding their urgent needs. Access to fresh food has become exceedingly difficult, but
          the relief we’ve provided has been warmly welcomed. From fresh produce to prepared meals, we’ve seen just how
          critical it is to support our community in this challenging time in the immediate, as well as in the days,
          weeks, months, and even years to come. Since the storm hit, we’ve mobilized quickly to provide relief while
          keeping local food flowing from farms to tables—nourishing both bodies and spirits.</p>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <h2 class="text-secondary mb-3">Our Response at Mother Earth Food</h2>
      </div>
      <div class="col-sm-7">
        <p class="text-col-2">At Mother Earth Food, we took swift action, pivoting our entire home delivery
          operation within 24 hours of the storm. Thanks to our community partners, including <strong>Smith Mill Works,
            Dr. Bronner’s, and Grassroots Aid Partnership</strong>, we were able to maintain cold storage and continue
          receiving fresh produce, proteins, dairy, and more from our local farmers. With the support of chefs, food
          trucks, and volunteers, we prepared meals to help feed those impacted by the storm. Together with
          <strong>Impact Health, Equal Plates Project, Manna Food Bank, ABCCM</strong>, and many more, we’ve been able
          to provide food to those in need. This is truly locals feeding locals. As we continue our relief efforts, we
          are also implementing longer-term strategies to sustain and support our regional recovery.
        </p>
      </div>
      <div class="col-sm-5">
        <img src="assets/images/landing/about/AboutUs_1_v2-min.webp" class="img-side is-height-3"
          alt="Mother Earth Hurricane Helene Support">
      </div>
    </div>


    <div class="border bg-light-subtle my-5 p-5">
      <div class="title is-center mb-0">
        <h3 class="title__main text-secondary">How You Can Help</h3>
      </div>
      <p class="h6-semibold text-center mb-5">Your support is vital in repairing and sustaining a resilient local food
        system, especially in the aftermath of Hurricane Helene. By working together, we can ensure continued access to
        fresh, healthy food for those affected, and strengthen our local farmers and producers in the process. Now, more
        than ever, we are reminded of how critically important our local food system is. We will always do everything we
        can to have Mother Earth Food in full service supporting this mission. We will keep dollars local, and we will
        all collaborate together. We believe local, sustainable, nutrient-dense food is a human birthright so that we
        can all thrive mentally, emotionally, physically, and spiritually.
      </p>

      <div class="row">
        <div class="col-12">
          <h3 class="text-center">Donate Now</h3>
          <p class="text-center">Please consider donating to our GoFundMe campaign or directly through Venmo. Every
            dollar helps us support our local farms and provide nutrient-dense nourishment to our community.</p>
        </div>
        <div class="col-md-6 mb-4">
          <div class="d-flex gap-4 align-items-center  justify-content-center flex-column flex-md-row">
            <a href="https://gofund.me/2793a30d" target="_blank"><img src="assets/images/landing/donation/gofundme.svg"
                style="height: 40px;" alt="GoFund Helene Support"></a>
            <div>
              <h6 class="text-center text-md-start">Go Fund Me</h6>
              <a href="https://gofund.me/2793a30d" target="_blank">https://gofund.me/2793a30d</a>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="d-flex gap-4 align-items-center  justify-content-center flex-column flex-md-row">
            <a href="https://venmo.com/u/motherearthfood29" target="_blank"><img
                src="assets/images/landing/donation/venmo.svg" style="height: 30px;" alt="GoFund Helene Support"></a>
            <div>
              <h6 class="text-center text-md-start">Venmo Handle</h6>
              <a href="https://venmo.com/u/motherearthfood29" target="_blank">&#64;motherearthfood29</a>
            </div>
          </div>
        </div>
      </div>

      <hr class="my-5">

      <div>
        <h3 class="text-center">Helene Relief Produce Boxes</h3>
        <p class="text-center mb-5">
          We are now offering Helene Relief Produce Boxes. Purchase this box from us and we will pack and distribute to
          our WNC community members in need. The Helene Relief Produce Box will contain 10 local, organic items, fresh
          from our small family farms. If you're outside our area, you can purchase a box to be donated to a family in
          need through our network of community partners. The Helene Relief Produce Boxes will help our WNC community
          members in need and will be distributed in coordination with our community partners. When we pay our small
          family farms in full for donation programs it keeps dollars in our local economy and food system, which is
          incredibly important for the rebuild ahead of us. This initiative is about even more than just feeding people
          now—it’s about sustaining and revitalizing our local food system for the future.
        </p>

        <h5 class="text-center">Asheville Locals with Mother Earth Food Account</h5>

        @defer (on immediate; when productSignal()) {
        @if (productSignal()) {
        @defer (on viewport; prefetch on immediate) {
        <div class="mt-4 d-grid mx-auto col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-12 animated fadeIn"
          id="{{productSignal().id}}">
          <div class="product-card-grid__item">
            <app-product-card [card]="{ product: productSignal() }">
            </app-product-card>
          </div>
        </div>
        } @placeholder {
        <div class="mt-4 d-grid mx-auto col-xxl-3 col-lg-4 col-md-4 col-sm-6 col-12 animated fadeInUp fadeIn mb-3"
          id="{{productSignal().id}}">
          <div class="card position-relative border p-3 row-gap-3" aria-hidden="true">
            <span class="placeholder bg-secondary col-12"></span>
            <span class="placeholder bg-secondary col-6"></span>
            <span class="placeholder bg-secondary col-6"></span>
            <div class="placeholder bg-secondary col-12" style="height: 100px;"></div>
            <div class="card-body">
              <h5 class="card-title placeholder-glow">
                <span class="placeholder bg-secondary col-6"></span>
              </h5>
              <p class="card-text placeholder-glow">
                <span class="placeholder bg-secondary col-7"></span>
                <span class="placeholder bg-secondary col-4"></span>
                <span class="placeholder bg-secondary col-4"></span>
                <span class="placeholder bg-secondary col-6"></span>
                <span class="placeholder bg-secondary col-8"></span>
              </p>
              <a class="btn btn-secondary disabled placeholder col-12" aria-disabled="true"></a>
            </div>
          </div>
        </div>
        }
        }

        }
        @placeholder { LOADING... }

      </div>

      <hr class="my-5">

      <div>
        <h5 class="text-center">Outside of Asheville</h5>
        <p class="text-center">Purchase via Venmo &#64;motherearthfood29 (link is <a
            href="https://venmo.com/u/motherearthfood29" target="_blank"
            class="lg-url">https://venmo.com/u/motherearthfood29</a>)<br>
          with the memo “Hurricane Relief Produce Box.” Each box is $34.99.
        </p>
      </div>
    </div>
    <hr class="my-5">

    <div class="row mb-5 d-flex align-items-center">
      <div class="col-sm-6">
        <h3>Join Us in Rebuilding Our Community</h3>
        <p>Even before the storm, access to fresh, healthy food was challenging at best for many in Western North
          Carolina. Now, we have an even greater need to respond to the immediate crisis and work toward a sustainable,
          long-term food system for our region. Your contribution makes it possible for us to keep meeting this need.
          And if you’re not able to give, please share our story to help spread the word.
        </p>
        <h6>Veggie LOVE!</h6>
      </div>
      <div class="col-sm-6">
        <div class="envelop-wrap">
          <div class="inner">
            <h6 class="mb-5">To make a donation by mail, <br>please make your check payable to:</h6>
            Mother Earth Food<br>
            PO Box 36<br>
            Enka, NC 28728-0036
          </div>
        </div>
      </div>
    </div>



    <div class="donation-wrap my-5 p-5">
      <div class="row">
        <div class="col-12">
          <h2 class="text-center mb-5 text-secondary h2-bold">Donate Now</h2>
        </div>
        <div class="col-md-6 mb-4">
          <div class="d-flex gap-4 align-items-center justify-content-center flex-column flex-md-row">
            <a href="https://gofund.me/2793a30d" target="_blank"><img src="assets/images/landing/donation/gofundme.svg"
                style="height: 40px;" alt="GoFund Helene Support"></a>
            <div>
              <h6 class="text-center text-md-start">Go Fund Me</h6>
              <a href="https://gofund.me/2793a30d" target="_blank">https://gofund.me/2793a30d</a>
            </div>
          </div>
        </div>
        <div class="col-md-6 mb-4">
          <div class="d-flex gap-4 align-items-center  justify-content-center flex-column flex-md-row">
            <a href="https://venmo.com/u/motherearthfood29" target="_blank"><img
                src="assets/images/landing/donation/venmo.svg" style="height: 30px;" alt="GoFund Helene Support"></a>
            <div>
              <h6 class="text-center text-md-start">Venmo Handle</h6>
              <a href="https://venmo.com/u/motherearthfood29" target="_blank">&#64;motherearthfood29</a>
            </div>
          </div>
        </div>
      </div>
      <p class=" text-center mt-5 mb-0">Thank you for helping us nourish our community and sustain a strong, resilient
        food system. Together, we can rise above the storm.</p>
    </div>

    <h3 class="text-center">#CommunitySupport #HurricaneHeleneRecovery #WNCStrong #localshelpinglocals #AshevilleStrong
    </h3>

  </div>
</section>

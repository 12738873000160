import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CreditCardService {
    regex = [
        {
            title: 'amex',
            value: /^3[47][0-9]{13}$/,
            securityCodeLen: 4
        },
        {
            title: 'BCGlobal',
            value: /^(6541|6556)[0-9]{12}$/,
            securityCodeLen: 3
        },
        {
            title: 'carteBlanche',
            value: /^389[0-9]{11}$/,
            securityCodeLen: 3
        },
        {
            title: 'dinersClub',
            value: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
            securityCodeLen: 3
        },
        {
            title: 'discover',
            value:
                /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/,
                securityCodeLen: 3
        },
        {
            title: 'instaPayment',
            value: /^63[7-9][0-9]{13}$/,
            securityCodeLen: 3
        },
        {
            title: 'JCB',
            value: /^(?:2131|1800|35\d{3})\d{11}$/,
            securityCodeLen: 3
        },
        {
            title: 'koreanLocal',
            value: /^9[0-9]{15}$/,
            securityCodeLen: 3
        },
        {
            title: 'laser',
            value: /^(6304|6706|6709|6771)[0-9]{12,15}$/,
            securityCodeLen: 3
        },
        {
            title: 'maestro',
            value: /^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$/,
            securityCodeLen: 3
        },
        {
            title: 'mastercard',
            value:
                /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
                securityCodeLen: 3
        },
        {
            title: 'solo',
            value: /^(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15}$/,
            securityCodeLen: 3
        },
        {
            title: 'switchc',
            value:
                /^(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13}$/,
                securityCodeLen: 3
        },
        {
            title: 'unionPa',
            value: /^(62[0-9]{14,17})$/,
            securityCodeLen: 3
        },
        {
            title: 'visa',
            value: /^4[0-9]{12}(?:[0-9]{3})?$/,
            securityCodeLen: 3
        },
    ];

    constructor() { }

    typeCard(value: string) {
        for (const pattern of this.regex)
            if (pattern.value.test(value))
                return pattern;

        return null;
    }
}

import { Component, computed, inject, signal, WritableSignal } from '@angular/core';
import { FormGroup, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResolutionService } from '../../../shared/resolution.service';
import { SignalsStoreService } from '../../../shared/signals-store.service';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InvoiceHistoryService } from './invoice-history.service';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator'
import { InvoiceHistory } from './invoice-history.types';
import { finalize, tap } from 'rxjs';
import { EmptyMessageComponent } from '../../../shared/empty-message/empty-message.component';

@Component({
  selector: 'app-invoice-history',
  standalone: true,
  providers: [InvoiceHistoryService],
  imports: [
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgbModule,
    MatProgressBar,
    MatPaginatorModule,
    EmptyMessageComponent
  ],
  templateUrl: './invoice-history.component.html',
  styleUrl: './invoice-history.component.scss'
})
export class InvoiceHistoryComponent {
  private invoiceHistoryService = inject(InvoiceHistoryService);
  private resolutionsService = inject(ResolutionService);
  private signalsStoreService = inject(SignalsStoreService);

  invoicesLength = computed(() => this.invoiceHistoryService.invoicesLength());

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  invoiceHistory = computed(() => this.invoiceHistoryService.invoiceHistory());
  credits: WritableSignal<number> = signal(0);

  isMobile = computed(() => this.resolutionsService.isMobile());

  isLimitedUser = computed(() => this.signalsStoreService.isLimitedUser());

  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25];

  pageEvent!: PageEvent;

  emptyMessage = 'You have no invoice history at this moment.';

  handlePageEvent(e: PageEvent) {
    this.pageEvent = e;
    this.pageIndex = this.pageSize !== e.pageSize ? 0 : e.pageIndex;
    this.pageSize = e.pageSize;
    this.invoiceHistoryService.getInvoiceHistory(this.pageIndex, this.pageSize);
  }

  ngOnInit(): void {
    this.invoiceHistoryService.getInvoiceHistory(this.pageIndex, this.pageSize);
  }

  goToInvoice(invoice: InvoiceHistory) {
    invoice.isLoading = true;
    this.invoiceHistoryService.getInvoiceDetails(invoice.id).pipe(
      finalize(() => invoice.isLoading = false)
    ).subscribe();
  }

}

<section class="page-wrap animated fadeIn" >
  <div class="container">
    <div class="title is-center is-full-width px-5">
      <h2 class="title__main mb-3">Frequently Asked Questions</h2>
      <p class="h5-regular mb-3 text-justify">
        Below are FAQs related to account set up, ordering, and billing. If your question isn’t answered, or if more questions arise, please reach out to our Customer Care team at
        <a href="mailto:terra@motherearthfood.com">terra&#64;motherearthfood.com</a>
      </p>
    </div>
    <div ngbAccordion [closeOthers]="true">
      @for (item of faq; track item) {
        <div ngbAccordionItem [collapsed]="true">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton>{{ item.question }}</button>
          </h2>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <div [innerHTML]="sanitizeHtml(item.answer)">
              </div>
            </div>
          </div>
        </div>
      }
    </div>

  </div>
</section>


import { Routes } from "@angular/router";
import { DeliveriesComponent } from "./account/deliveries/deliveries.component";
import { FriendReferralsComponent } from "./account/friend-referrals/friend-referrals.component";
import { ProfileComponent } from "./account/profile/profile.component";
import { SubscriptionsComponent } from "./account/subscriptions/subscriptions.component";
import { MembershipComponent } from "./billing/membership/membership.component";
import { OrderAndBillingComponent } from "./billing/order-and-billing/order-and-billing.component";
import { OrderDetailComponent } from "./billing/order-and-billing/order-detail/order-detail.component";
import { PaymentMethodComponent } from "./billing/payment-method/payment-method.component";
import { SettingsComponent } from "./settings.component";
import { checkMembershipPermissions } from "../shared/guards/auth-guard.guard";
import { InvoiceHistoryComponent } from "./billing/invoice-history/invoice-history.component";
import { maintenanceGuard } from "../shared/guards/auth-guard.guard";

export const settingsRoutes: Routes = [
    {
        path: 'settings',
        title: 'Settings',
        component: SettingsComponent,
        canActivate: [maintenanceGuard, checkMembershipPermissions],
        canActivateChild: [maintenanceGuard],
        children: [
            {
                path: 'account/profile',
                title: 'Profile settings',
                component: ProfileComponent,
            },
            {
                path: 'account/subscriptions',
                title: 'Subscription manager',
                component: SubscriptionsComponent,
            },
            {
                path: 'account/deliveries',
                title: 'Deliveries',
                component: DeliveriesComponent,
            },
            {
                path: 'account/friend-referrals',
                title: 'Friend referrals',
                component: FriendReferralsComponent,
            },
            {
                path: 'billing/payment-method',
                title: 'Payment method settings',
                component: PaymentMethodComponent,
            },
            {
                path: 'billing/membership',
                title: 'Membership',
                component: MembershipComponent,
            },
            {
                path: 'billing/order-and-billing',
                title: 'Order & billing',
                component: OrderAndBillingComponent,
            },
            {
                path: 'billing/order-and-billing/:order',
                title: 'Order detail',
                component: OrderDetailComponent,
            },
            {
                path: 'billing/invoice-history',
                title: 'Invoice History',
                component: InvoiceHistoryComponent,
            }
        ]
    },
];
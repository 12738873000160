<section class="page-wrap animated fadeIn">
  <div class="container">
    <h1 class="section-title"><span>Delivery Areas</span></h1>

    <div class="row">
      <div class="col-xl-7 col-lg-5 col-md-6 d-none d-sm-block">
        <div class="bg-image-wrap d-flex justify-content-start p-5"
             style="background-image: url('assets/images/landing/delivery-area/bg.jpg')" alt="delivery Areas">
        </div>
      </div>
      <div class="col-xl-5 col-lg-7 col-md-6">
        <div class="d-flex flex-column bg-white p-3 bg-opacity-50 rounded-1 align-items-start justify-content-center">
          <h2 class="text-center text-md-start h2-semibold">Where We Deliver:</h2>
          <ul class="ul-location-tag">
            <li> Arden, NC</li>
            <li> Asheville, NC</li>
            <li> Black Mountain, NC</li>
            <li> Candler, NC</li>
            <li> Fletcher, NC</li>
            <li> Leicester, NC</li>
            <li> Swannanoa, NC</li>
            <li> Woodfin, NC</li>
            <li> Weaverville, NC</li>
          </ul>
        </div>
      </div>
    </div>
    <hr class="my-5">
    <div class="row">
      <iframe src="https://www.google.com/maps/d/embed?mid=1ALvlXmCE1niYITtV6ShE1aVdAPU6HDU&hl=en&ehbc=2E312F"
              width="640" height="480"></iframe>
    </div>
    <div class="row mt-5">
      <div class="col-md-7">
        <h2 class="text-md-start h3-semibold">Check if We Deliver to Your Area:</h2>

        <ol class="ol-counter">
          <li>
            Expand the Map:
            <ul>
              <li>Locate the Google Map in the upper right corner of the page.</li>
              <li>Click on the map to expand it for a better view.</li>
            </ul>
          </li>
          <li>
            Search for Your Address:
            <ul>
              <li>Once the map is expanded, use the search feature (marked by a magnifying glass icon).</li>
              <li>Enter your address in the search bar.</li>
            </ul>
          </li>
          <li>
            Verify Delivery Availability:
            <ul>
              <li>The map will zoom in on your location and display whether your address is within our delivery area.
              </li>
            </ul>
          </li>
        </ol>

        <h4 class="h5-semibold mb-5">
          If your address is within the green highlighted area on the map, we will deliver to you! If not, feel free to
          reach out to us for further assistance.
          <a href="mailto:terra@motherearthfood.com">Customer Care</a>
        </h4>

      </div>
      <div class="col-md-5">
        <div class="outside-box">
          <h4 class="text-center h4-semibold mb-5">If Your Address Is Outside Our Delivery Area:</h4>

          <p>Don't worry! We're constantly expanding our delivery zones and may reach your neighborhood soon. In the
            meantime, you have a few options:</p>
          <ul>
            <li>Warehouse Pickup: You can choose to pick up your order at our Asheville warehouse.</li>
            <li>Alternate Address: If you have another address, we can consider it for delivery.</li>
          </ul>
          <p>If you have any questions or need assistance, please contact our Customer Care team.</p>
          <div class="d-flex justify-content-center">
            <a class="btn btn-primary" href="mailto:terra@motherearthfood.com">Customer Care</a>
          </div>
        </div>
      </div>
    </div>
    <hr class="my-5">
    <h3 class="text-center h2-semibold mb-4">DELIVERY PROCESS OVERVIEW</h3>
    <div class="row has-animation fadeInUp">
      <div class="col-md-4">
        <div class="delivery-step">
          <span class="icon">1</span>
          <div class="detail">
            <div class="text">
              <h5 class="delivery-step__title">Available Delivery Days:</h5>
              <div class="table-grid__title">We deliver on</div>
              <div class="table-grid is-day">
                <div class="table-grid__item">Tuesday</div>
                <div class="table-grid__item">Wednesday</div>
                <div class="table-grid__item">Thursday</div>
                <div class="table-grid__item">Friday</div>
                <div class="table-grid__item">Saturday</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="delivery-step">
          <span class="icon">2</span>
          <div class="detail">
            <div class="text">
              <h5 class="delivery-step__title">Delivery Schedule</h5>
              <div class="table-grid__title">
              </div>
              <div class="table-grid">
                <div class="table-grid__item">Tuesday Deliveries<br> 7 AM – 6 PM</div>
                <div class="table-grid__item">Wednesday Deliveries<br> 7 AM – 6 PM</div>
                <div class="table-grid__item">Thursday Deliveries<br> 7 AM – 6 PM</div>
                <div class="table-grid__item">Friday Deliveries<br> 7 AM – 6 PM</div>
                <div class="table-grid__item">Saturday Deliveries<br> 7 AM – 3 PM</div>
              </div>
              <div class="h8-regular">
                *You'll receive more precise delivery ETAs before your scheduled delivery day.
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="delivery-step">
          <span class="icon">3</span>
          <div class="detail">
            <div class="text">
              <h5 class="delivery-step__title">Ordering Deadlines</h5>
              <div class="table-grid__title">The ordering window opens every Friday at noon. Be sure to place your order
                by the following deadlines
              </div>
              <div class="table-grid">
                <div class="table-grid__item">Tuesday Deliveries<br> Order by Sunday at 11:30 PM</div>
                <div class="table-grid__item">Wednesday Deliveries<br> Order by Monday at 11:30 PM</div>
                <div class="table-grid__item">Thursday Deliveries<br> Order by Tuesday at 11:30 PM</div>
                <div class="table-grid__item">Friday Deliveries<br> Order by Wednesday at 11:30 PM</div>
                <div class="table-grid__item">Saturday Deliveries<br> Order by Thursday at 11:30 PM</div>
              </div>
              <div class="h8-regular">
                This streamlined process ensures that your orders are delivered fresh and on time!
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>

import { afterNextRender, Component, inject, signal, WritableSignal } from '@angular/core';
import { LocalStorageService } from '../shared/local-storage.service';
import { LOCALSTORAGE_KEYS } from '../shared/constants/databases';
import { MAINTENANCE_CONF } from '../shared/constants/maintenance';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-maintenance',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './maintenance.component.html',
  styleUrl: './maintenance.component.scss'
})
export class MaintenanceComponent {
  private localStorageService = inject(LocalStorageService);

  title: WritableSignal<string> = signal('');
  message: WritableSignal<string> = signal('');

  constructor() {
    afterNextRender(() => {
      this.title.set(this.localStorageService.get(LOCALSTORAGE_KEYS.MAINTENANCE_TITLE) || MAINTENANCE_CONF.title);
      this.message.set(this.localStorageService.get(LOCALSTORAGE_KEYS.MAINTENANCE_MESSAGE) || MAINTENANCE_CONF.message);
    })
  }
}

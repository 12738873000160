@if (isCustomBoxSignupFlow()) {
<header class="bg-white top-header" [ngClass]="{'top-header--hidden-sub-nav': !shouldShowCategoriesBar()}">
  <nav class="navbar navbar-expand-lg navbar-light nav-main">
    <a class="navbar-brand animated fadeInLeft" [class.mx-auto]="isCustomBoxSignupFlow()" href="javascript:void(0)">
      <img src="assets/images/logo-main.svg?v={{logoVersionNumber()}}" alt="Mother Earth Logo" loading="lazy" />
    </a>
  </nav>
</header>
} @else {
<!--Main Navigation-->
<header class="bg-white top-header" [ngClass]="{'top-header--hidden-sub-nav': !shouldShowCategoriesBar()}">
  <nav class="navbar navbar-expand-lg navbar-light nav-main">
    <!-- Container wrapper -->
    <!-- Toggle button -->
    @if (!isCustomBoxSignupFlow()) {
    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
      aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
      <span class="material-symbols-outlined">menu</span>
    </button>
    }

    <!-- Navbar brand -->
    <a class="navbar-brand animated fadeInLeft" [class.mx-auto]="isCustomBoxSignupFlow()" [routerLink]="['/shop']"
      href="#">
      <img src="assets/images/logo-main.svg?v={{logoVersionNumber()}}" alt="Mother Earth Logo" loading="lazy" />
    </a>

    @if (!isCustomBoxSignupFlow()) {
    <div class="d-lg-none">
      <button class="search-toggler show-on-shop" (click)="toggleSearchMobile()">
        <span class="material-symbols-outlined">search</span>
      </button>
    </div>

    <div class="offcanvas offcanvas-start d-lg-none mobile-menu" [class.hidden-mobile-footer]="!isShowingMobileFooter()"
      data-bs-backdrop="true" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-end flex-grow-1">
          @for (page of landingPages(); track $index) {
          <li class="nav-item">
            <a class="nav-link" [routerLink]="[page.route]" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar">{{page.name}}</a>
            @if (page.submenu) {
            <ul class="sub-menu">
              @for (item of page.submenu; track $index) {
              @if(item.url) {
              <li><a class="nav-link" [href]="item.url">{{item.name}}</a></li>
              }
              @else {
              <li><a class="nav-link" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
                  aria-controls="offcanvasNavbar" [routerLink]="[item.route]">{{item.name}}</a></li>
              }
              }
            </ul>
            }
          </li>
          }
        </ul>
        <hr>

        @if (!signalsStoreService.hasSession()) {
        <ul class="navbar-nav justify-content-end flex-grow-1 gap-2">
          <li>
            <button routerLink="/login" class="btn btn-outline-primary w-100" type="button">Log In</button>
          </li>
          <li>
            <button routerLink="/signup" class="btn btn-primary w-100" type="button">
              Sign Up
            </button>
          </li>
        </ul>
        } @else {
        <ul class="navbar-nav justify-content-end flex-grow-1 mobile-menu__sub">
          @for (parent of settingsPages(); track $index) {
          <p class="mobile-menu__title">
            <span class="icon material-symbols-outlined">
              {{parent.icon}}
            </span>
            <span>{{parent.name}}</span>
          </p>
          @for (page of parent.children; track $index) {
          <li>
            <a class="nav-link" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar" [routerLink]="[page.route]">{{page.name2 &&
              !(session()?.settings?.requireAddress) ? page.name2 : page.name}}</a>
          </li>
          }
          }
          <hr>
          <li>
            <a class="nav-link with-action" href="#" (click)="logOut()">
              <p class="m-0">{{ userName() }}</p>
              <span class="material-symbols-outlined">login</span>
            </a>
          </li>
        </ul>
        }

      </div>
    </div>

    <!-- Collapsible wrapper -->
    <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
      <!-- Center elements -->
      <ul class="navbar-nav mx-auto justify-content-center nav-main animated fadeInDown">
        @for (page of landingPages(); track $index) {
        @if (!page.mobile) {
        <li class="nav-item" [class.has-sub-menu]="page.submenu">
          <a class="nav-link fw-semibold" [routerLink]="[page.route]">{{page.name}}</a>
          @if (page.submenu) {
          <ul class="sub-menu">
            @for (item of page.submenu; track $index) {
            @if(item.url) {
            <li><a class="nav-link" [href]="item.url">{{item.name}}</a></li>
            }
            @else {
            <li><a class="nav-link" [routerLink]="[item.route]">{{item.name}}</a></li>
            }
            }
          </ul>
          }
        </li>
        }
        }
      </ul>

      @defer (on viewport; when isSessionLoaded()) {
      @if (!signalsStoreService.hasSession()) {
      <div class="navbar-nav justify-content-end animated fadeIn">
        <button routerLink="/login" class="btn btn-outline-secondary me-2" type="button">Log In</button>
        <button routerLink="/signup" class="btn btn-secondary" type="button"> Sign Up
        </button>
      </div>
      } @else {
      <!-- Right elements -->
      <ul class="navbar-nav justify-content-end animated fadeIn">
        <!-- Icon -->
        @for (element of rightElements(); track $index) {
        <li class="nav-item nav-item--icon">
          <a class="nav-link fw-semibold" href="javascript:void(0)" routerLinkActive="active-link"
            [routerLink]="[element.route]" [ngbTooltip]="element.tooltip" placement="bottom">
            <div class="position-relative">
              <span class="material-symbols-outlined">
                {{element.icon}}
              </span>
              @if (element.showBadge && showBadgeStatus()) {
              @if (hasFirebaseOrders()) {
              <span
                class="position-absolute top-0 start-100 translate-middle badge rounded-pill quanity-badge red-quantity-badge">
                <span class="material-symbols-outlined">
                  exclamation
                </span>
              </span>
              } @else if (hasOdooOrders()) {
              <span
                class="position-absolute top-0 start-100 translate-middle badge rounded-pill quanity-badge green-quantity-badge">
                <span class="material-symbols-outlined">
                  check
                </span>
              </span>
              }
              }
            </div>
          </a>
        </li>
        }
        <div class="vr border border-primary mx-2"></div>
        <li class="nav-item dropdown d-flex align-items-center">
          <a class="nav-link fw-semibold dropdown-toggle dropdown-toggle-split" href="#" role="button"
            data-bs-toggle="dropdown" aria-expanded="false" data-bs-reference="parent">
            {{ userName() }}
          </a>
          <ul class="dropdown-menu dropdown-menu-end">
            @for (parent of settingsPages(); track $index) {
            <h6 class="dropdown-header">
              <span class="icon material-symbols-outlined">
                {{parent.icon}}
              </span>
              <span>{{parent.name}}</span>
            </h6>
            @for (page of parent.children; track $index) {
            <li>
              <a class="dropdown-item" [routerLink]="[page.route]">{{page.name2 &&
                !(session()?.settings?.requireAddress) ? page.name2 : page.name}}</a>
            </li>
            }
            }
            <div class="dropdown-divider"></div>
            <li (click)="logOut()">
              <a class="nav-link with-action" href="#">
                <span class="material-symbols-outlined rotate-icon">
                  login
                </span>
                <span>Log out</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
      }
      } @placeholder {
      <div class="placeholder-glow col-1 animated fadeIn">
        <li class="placeholder col-12 bg-secondary"></li>
      </div>
      }

    </div>
    }
  </nav>

  @if (!isCustomBoxSignupFlow()) {
  <!-- Navbar Inferior -->
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary nav-category nav-category--desktop animated fadeIn">
    <!-- Centered links -->
    @if (isShowingSearchBar()) {
    <app-search-bar class="w-100 h-100"></app-search-bar>
    } @else {
    <div class="nav-content animated fadeIn">
      <ul class="navbar-nav d-flex align-items-center categories-container">
        @if (menuItems().length) {
        @for (item of menuItems(); track $index) {
        <li class="nav-item animated fadeIn">
          <a class="nav-link" href="javascript:void(0)"
            [class.text-secondary]="signalsStoreService.selectedCategory().name.toLowerCase() === item.name.toLowerCase()"
            [class.fw-medium]="signalsStoreService.selectedCategory().name.toLowerCase() === item.name.toLowerCase()"
            (click)="triggerCategoryInfoFetch(item)">{{ item.name }}</a>
        </li>
        }
        <li class="nav-item">
          <button class="btn btn-sm nav-category--icon" type="button" (click)="toggleSearchBar()" title="Search">
            <span class="material-symbols-outlined">search</span>
          </button>
        </li>
        }
      </ul>
    </div>
    }
  </nav>

  <!-- Mobile navbar -->
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary nav-category nav-category--mobile animated fadeIn">
    @if (isShowingSearchMobile()) {
    <div class="category-search--mobile">
      <app-search-bar></app-search-bar>
    </div>
    } @else {
    <mat-form-field>
      <mat-label>Category</mat-label>
      <mat-select #matSelect [disabled]="isContentLoading()" [value]="signalsStoreService.selectedCategory().name">
        @if (menuItems().length) {
        @for (item of menuItems(); track $index) {
        <mat-option [value]="item.name" (click)="triggerCategoryInfoFetch(item)">{{ item.name }}</mat-option>
        }
        }
      </mat-select>
    </mat-form-field>
    }
    <div class="category-menu show-on-shop">
      @if (isShowingSearchMobile()) {
      <button class="category-menu__icon" (click)="toggleSearchMobile(true)" aria-label="Close Search">
        <span class="material-symbols-outlined">close</span>
      </button>
      } @else {
      <button class="category-menu__icon btn-filter-mobile" (click)="toggleCategorySideMenu()"
        [class.is-open]="!isSidebarOpen()" aria-label="Show/Hide Category Menu">
        <span class="fas fa-sliders icon-sort fs-5"></span>
        <span class="material-symbols-outlined icon-close">close</span>
      </button>
      }
    </div>
  </nav>
  }

</header>
<!--Main Navigation-->
}
<div id="scroll-to-top-ref" class="subscribe-bar">
  Earn $50 in credit for every referral you make!
  @if (hasSession()) {
    <div class="subscribe-wrap">
      <button class="btn btn-secondary btn-sm" [routerLink]="'/settings/account/friend-referrals'">Refer a friend</button>
    </div>
  }
</div>

<section  class="animated fadeInUp section-hero">

  <div class="video-wrapper animated fadeInUp">
    <div class="container position-absolute z-1 section-padding-lg animated fadeInDown px-3">
      <div class="row align-items-center">
        <div class="col-lg-6">
          <div class="title is-left">
            <h1 class="title__main">Mother Earth Food: Nourishing communities, cultivating connection</h1>
            <p class="title__sub">Bringing farmers market freshness to your doorstep with home delivery convenience</p>
          </div>
          <div class="spacer"></div>
          <a class="btn btn-lg btn-secondary" routerLink='/signup' >Join Us</a>
        </div>
      </div>
    </div>

    <video
      *ngIf="videoUrl() && !isMobile()"
      autoplay
      muted
      loop
      alt="Take a tour of Mother Earth Food's local farms and meet the farmers who grow their fresh and sustainable food."
      aria-label="Take a tour of Mother Earth Food's local farms and meet the farmers who grow their fresh and sustainable food.">
      <source [src]="videoUrl()" type="video/mp4">
      Your browser does not support the video element.
    </video>
  </div>

</section>

<section class="my-5 px-3">
  <div class="container">
    <div class="title is-center is-full-width">
      <h2 class="title__main">Community, Resilience, Dedication</h2>
      <p class="text-justify"><strong>The local food system is not just about what we eat;</strong> it's a beacon of hope, a symbol of resilience, and a testament to the strength of our communities. In every locally sourced box from Mother Earth Food, you'll find a story of dedication, hard work, and passion. <strong>It's a story told by the hands that sow the seeds, nurture the crops, and harvest the fruits of their labor.</strong> It's a story etched into the soil from which our food springs forth.</p>
    </div>
  </div>
</section>

<hr class="hr-small">

<section class="py-5 section-img-grid px-3">
  <div class="container">
    <div class="title is-center is-full-width">
      <h2 class="title__main mb-5">Soulful Sustainable Connection</h2>
    </div>
    <div class="img-grid-collage">
      <img class="img-main" src="assets/images/landing/home/soulful/Home_Soulful_1-min.webp" alt="Organic and sustainable farming"/>
      <img class="img-sub-1" src="assets/images/landing/home/soulful/Home_Soulful_2-min.webp" alt="Local farm and products"/>
      <img class="img-sub-2" src="assets/images/landing/home/soulful/Home_Soulful_3-min.webp" alt="Free range chicken"/>
      <img class="img-sub-3" src="assets/images/landing/home/soulful/Home_Soulful_4-min.webp" alt="Sustainable practices"/>
      <blockquote class="quote-1 is-right">Our local food system is a beacon of hope, resilience, and dedication.</blockquote>
      <blockquote class="quote-2 is-right">It's more than just food; it nourishes our souls and connects us to something greater.</blockquote>
    </div>
    <div class="text-justify">
      But the <strong>local food system is more than just sustenance for our bodies;</strong>
      it nourishes our souls. It's a reminder that we are part of something greater—a web of interconnected lives, <strong>each playing a vital role in feeding our communities and supporting our neighbor.</strong> When we support our local food system, we're not just choosing fresh, delicious ingredients but also sustainability, ethical practices. <strong>This important choice also ensures that future generations can savor the same flavors we cherish today.</strong>
    </div>
  </div>
</section>

<hr class="hr-small">

<section class="section-how has-padding-block-5 px-3">
  <div class="container">
    <div class="title is-center is-full-width">
      <h2 class="title__main">How it Works</h2>
      <p class="text-justify"><strong>We partner closely with local farmers for fresh, sustainable produce.</strong> Our direct relationships ensure top quality in every delivery. With Mother Earth Food, <strong>you support farmers nourishing our community and land for generations.</strong></p>
    </div>
    <div class="row has-animation fadeInUp">
      <div class="col-md-3">
        <div class="how-step">
          <span class="icon">1</span>
          <div class="detail">
            <div class="text">Create a FREE account and shop online</div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="how-step">
          <span class="icon">2</span>
          <div class="detail">
            <div class="text">Customize your produce box</div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="how-step">
          <span class="icon">3</span>
          <div class="detail">
            <div class="text">Enhance your order with additional items</div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="how-step has-no-dash">
          <span class="icon">4</span>
          <div class="detail">
            <div class="text">We deliver weekly to your door</div>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-3 d-flex justify-content-center">
      <a class="btn btn-secondary" routerLink='/signup'>Join Us</a>
    </div>
  </div>
</section>

<hr class="hr-small">

<section class="section-commitment has-padding-block-5 px-3">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-6 has-animation fadeInRight">
        <img src="assets/images/landing/home/Home_JoinUs-min.webp" class="img-fluid" alt="What sets us apart"/>
      </div>
      <div class="col-md-6 has-animation fadeInLeft">
        <h4 class="text-center text-md-start">Join us in celebrating and supporting our local food system.</h4>
        <p class="text-justify">So, let's honor this remarkable system.
          <b>Let's celebrate the farmers who toil under the sun,</b>
          the makers who craft culinary wonders, and the consumers who choose local with intention.
          <b>Let's savor the flavors of community and support the mission of sustainability.</b>
          Together, we can build a resilient, vibrant, and nourishing local food system that sustains our bodies and spirits.
          <b>In every bite, we taste the essence of unity, the power of choice, and the hope of a better, more connected world.</b>
        </p>
        <div class="d-flex d-md-block justify-content-center">
          <a class="btn btn-secondary" routerLink='/shop'>Shop Now</a>
        </div>

      </div>
    </div>
  </div>
</section>

<hr class="hr-small">

<section class="has-padding-block-5 px-3">
  <div class="container">
    <div class="title is-center is-full-width">
      <h2 class="title__main">Producers</h2>
      <p class="text-justify">With Mother Earth Food, you're not just getting groceries — <strong>you're supporting the hardworking farmers who nourish our community and sustain our land for generations to come.</strong></p>
    </div>
    <div class="grid-masonry">
      <div class="grid-masonry__sub">
        <div class="quote-1 is-primary">
          <blockquote class="is-boxed">From crisp vegetables to tender meats and artisanal cheeses, we support hardworking farmers who nourish our community and sustain our land for generations to come.</blockquote>
        </div>
        <div class="img-sub-1"><img src="assets/images/landing/home/producers/Home_Producers_1-min.webp" class="img-fluid" alt="Supporting the hardworking farmers"/></div>
      </div>
      <div class="grid-masonry__sub">
        <div class="img-sub-2"><img src="assets/images/landing/home/producers/Home_Producers_2-min.webp" class="img-fluid" alt="Close partnerships with local farmers"/></div>
        <div class="quote-2 is-secondary">
          <blockquote class="is-boxed">We cultivate close partnerships with local farmers to ensure the highest quality and utmost freshness in every delivery</blockquote>
        </div>
      </div>
      <div class="img-main"><img src="assets/images/landing/home/producers/Home_Producers_3-min.webp" class="img-fluid" alt="Utmost freshness in every delivery"/></div>
    </div>

    <div class="mt-3 d-flex justify-content-center">
      <a class="btn btn-secondary" routerLink='/pages/producers'>Our Producers</a>
    </div>

  </div>
</section>


<app-footer></app-footer>

import { computed, inject, Injectable, Signal, signal, WritableSignal } from "@angular/core";
import { ResolutionService } from "../shared/resolution.service";

@Injectable({
    providedIn: 'root'
})
export class ShopService {

    #resolutionService = inject(ResolutionService);

    #categoryDesktopBanner: WritableSignal<string> = signal('');
    #categoryMobileBanner: WritableSignal<string> = signal('');

    categoryBanner: Signal<string> = computed(() => {
        const isSmallMobile = this.#resolutionService.isSmallMobile();
        const desktopBanner = this.#categoryDesktopBanner();
        const mobileBanner = this.#categoryMobileBanner();
        return isSmallMobile ? mobileBanner : desktopBanner;
    })

    setUpBannersFormProductsResponse(banners: { mobile: string, desktop: string }) {
        this.#categoryDesktopBanner.set(banners?.desktop || '');
        this.#categoryMobileBanner.set(banners?.mobile || '');
    }

    clearBanners() {
        this.#categoryDesktopBanner.set('');
        this.#categoryMobileBanner.set('');
    }
}
import { Component, Inject, OnInit, PLATFORM_ID, afterNextRender, computed, inject, signal, } from '@angular/core';
import { HeaderComponent } from '../shared/header/header.component';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { SignalsStoreService } from '../shared/signals-store.service';
import { ProductCardComponent } from '../shared/product-card/product-card.component';
import { SidebarComponent } from '../shared/sidebar/sidebar.component';
import { ProductsService } from '../product/products.service';
import { CartPreviewComponent } from '../cart-preview/cart-preview.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { toObservable } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterLink } from '@angular/router';
import { StockService } from '../stock/stock.service';
import { MEDIUM } from "../../scss/responsive/responsive";
import { HeaderService } from '../shared/header/header.service';
import { ResolutionService } from '../shared/resolution.service';
import { EmptyMessageComponent } from '../shared/empty-message/empty-message.component';
import { FooterComponent } from "../shared/footer/footer.component";
import { MenuService } from '../shared/menu.service';
import { ShopService } from './shop.service';

@Component({
  selector: 'app-shop',
  standalone: true,
  templateUrl: './shop.component.html',
  styleUrl: './shop.component.scss',
  imports: [
    CommonModule,
    SidebarComponent,
    ProductCardComponent,
    NgbModule,
    MatProgressBarModule,
    EmptyMessageComponent,
  ]
})
export class ShopComponent implements OnInit {

  private productsService = inject(ProductsService);
  private headerService = inject(HeaderService);
  private resolutionService = inject(ResolutionService);
  #menuService = inject(MenuService);
  #shopService = inject(ShopService);

  categoryBanner = computed(() => this.#shopService.categoryBanner());

  signalsStore = inject(SignalsStoreService);
  stockService = inject(StockService);

  isSidebarOpen = this.signalsStore.isSidebarOpen;
  hasSession = this.signalsStore.hasSession;
  selectedCategory = this.signalsStore.selectedCategory;

  isCollapseButtonHovered = signal(false);
  lastSubcategory: string | null = null;
  productsSignal = computed(() => {
    const sortedProducts = this.productsService.productsSignal();
    this.lastSubcategory = null;
    return sortedProducts.map((product: any) => {
      if (product.subcategory.name !== this.lastSubcategory) {
        product.showSubcategory = true;
        this.lastSubcategory = product.subcategory.name;
      } else {
        product.showSubcategory = false;
      }
      return product;
    });
  });
  categoriesSignal: any = computed(() => this.productsService.productsSignal());
  marketStatus = computed(() => this.signalsStore.marketStatus());
  closedMarket = computed(() => {
    const im = this.isMobile();
    const ms = this.marketStatus();
    return {
      title: !ms.isOpen && im ?
        `Our Online Farmer's Market Open ${ms.openingDay.toUpperCase()} Morning!` :
        `Our Online Farmer's Market Open ${ms.openingDay.toUpperCase()} Morning!`,
      legend: `We're busy sourcing next week's availability! Check back Friday to begin shopping.`
    };
  })
  sidebarChanged = toObservable(this.signalsStore.sidebarFilterChanged);

  isContentLoading = computed(() => this.stockService.isLoading() || this.productsService.isLoading());
  isContentLoaded = computed(() => this.stockService.isLoaded() || this.productsService.isLoaded());

  isMobile = computed(() => this.resolutionService.isMobile());

  emptyMessage = 'No products were found.';

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    afterNextRender(() => {
      const isMobile = window.innerWidth <= MEDIUM;
      if (isMobile) this.signalsStore.isSidebarOpen.set(false);
    });
  }

  ngOnInit(): void {
    this.sidebarChanged
      .pipe(tap(() => this.productsService.filterBySidebarChange()))
      .subscribe();
    this.headerService.producerRedirection();

    if (!isPlatformBrowser(this.platformId)) return;
    const selectedProductId = localStorage.getItem('selectedProductId');
    if (selectedProductId != null) {
      const intervalId = setInterval(() => {
        const scrollToTop = document.getElementById(selectedProductId!.toString());
        if (scrollToTop) {
          scrollToTop.scrollIntoView({ block: 'start' });
          localStorage.removeItem('selectedProductId');
          clearInterval(intervalId);
        }
      }, 1000);
    }
  }

  toggleSidebar() {
    this.signalsStore.isSidebarOpen.update((currentValue) => !currentValue);
  }

  toggleCollapseBarHover(hovered: boolean) {
    this.isCollapseButtonHovered.set(hovered);
  }

  scrollToTop(): void {
    const scrollToTop = document.getElementById('scroll-to-top-ref');
    if (scrollToTop) {
      scrollToTop.scrollIntoView({ block: 'start' });
    }
  }

  getSubCategoryBanner(categoryId: number, subCategoryId: number): string {

    const category = this.#menuService
      .menu()
      .find(x => x.id === categoryId);

    if (!category)
      return '';

    const subcategory = category.subCategories
      .find((x: any) => x.id === subCategoryId);

    return !subcategory
      ? ''
      : !subcategory.banners
        ? ''
        : subcategory.banners[this.isMobile() ? 'mobile' : 'desktop'];
  }
}

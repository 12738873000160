import { Component, WritableSignal, afterNextRender, inject, signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AccountRecoveryService } from '../account-recovery.service';
import { NotificationService } from '../../../shared/notification/notification.service';
import { tap } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LocalStorageService } from '../../../shared/local-storage.service';
import { LOCALSTORAGE_KEYS } from '../../../shared/constants/databases';

@Component({
  selector: 'app-check-email',
  standalone: true,
  imports: [FormsModule, CommonModule, RouterLink],
  templateUrl: './check-email.component.html'
})
export class CheckEmailComponent {
  private accountRecoveryService = inject(AccountRecoveryService);
  private notificationService = inject(NotificationService);
  private localStorageService = inject(LocalStorageService);
  private router = inject(Router);

  email: WritableSignal<string> = signal('');
  invalid: WritableSignal<boolean> = signal(false);

  constructor() {
    afterNextRender(() => {
      const storedEmail: string | null = this.localStorageService.get(LOCALSTORAGE_KEYS.NO_PASSWORD_USER_EMAIL);
      if (storedEmail) {
        this.email.set(storedEmail);
        this.validateEmail(storedEmail);
      }
    })
  }

  checkEmail() {
    if (!this.email)
      return this.notificationService.show({ text: 'Please write a valid email', type: 'error' });

    this.accountRecoveryService.generateCode(this.email()).pipe(
      tap(() => this.localStorageService.remove(LOCALSTORAGE_KEYS.NO_PASSWORD_USER_EMAIL)),
      tap(() => this.router.navigate(['/account-recovery/check-code']))
    ).subscribe();
  }

  validateEmail(event: any): void {
    if (!event?.target?.value) return
    const value = event.target.value;
    // Regular expression to validate a basic email format
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,6}$/;
    this.invalid.set(!emailRegex.test(value));
  }
}

<div class="card mb-3">

	<div class="row row-cols-auto g-0">
	  <div class="col">
		<a [routerLink]="['/product/'+card().product?.id]">
			<!-- TODO hard coded for client -->
			<img [src]="defaultCard.product?.img || 'assets/images/product-placeholder.webp'" class="card-img-top" style="width:110px; height: 110px;">
		</a>
	  </div>
	  <div class="col-7">
		<div class="card-body p-0 ps-2">
			<!-- Tags Icons -->
			<!-- <ul class="list-group list-group-flush">
				<li class="list-group-item pt-0 ps-0">
					<i class="pe-2 fa-solid fa-wheat-awn-circle-exclamation"
						ngbTooltip="Some dietary info about this icon">
					</i>
					<i class="pe-2 fa-brands fa-envira"
						ngbTooltip="Some dietary info about this icon">
					</i>
					<i class="pe-2 fa-solid fa-leaf"
						ngbTooltip="Some dietary info about this icon">
					</i>
				</li>
			</ul> -->

			<!-- Fav Icon -->
			<label [for]="'isFavorite'+card().product?.id" class="custom-checkbox position-absolute z-0 pt-0 top-0 end-0">
				<input type="checkbox" [id]="'isFavorite'+card().product?.id"/>
				<i class="fa-heart fa-regular text-danger"></i>
				<i class="fa-heart fa-solid text-danger"></i>
			</label>

			<div class="row">
				<div class="col" style="min-height: 2.5em">
					<small class="fs-6">{{card().product?.name}}</small>
				</div>
			</div>
			<!-- <p class="card-text lh-1 mb-0"><small class="text-body-secondary">{{ card().product?.producer?.name}}</small></p> -->
			<div class="row pt-1">
				<div class="col">
					<h6 class="card-title text-primary">{{ card().product?.price | currency }}</h6>
				</div>
				<!-- <div class="col">
					<mat-form-field class="rounded-selector d-flex" subscriptSizing="dynamic">
						@if(hasSelectedCustomQuantity){
							<input type="text"
								matInput
								[disabled]="hasProductInCart()"
								[value]="productInCart().quantity || selectedQuantity()"
								(input)="onInputChange($event)"
							>
						}
						@else{
							<mat-select
								[disabled]="hasProductInCart()"
								[value]="productInCart().quantity || selectedQuantity()"
								(selectionChange)="onQuantityChange($event)"
							>
								@for(number of [1,2,3,4,5,6,7,8,9]; track $index){
									<mat-option [value]="number">{{ number }}</mat-option>
								}
								<mat-option value="10">10+</mat-option>
							</mat-select>
						}
					</mat-form-field>
				</div> -->
			</div>

			<button class="btn btn-secondary btn-sm">Add to cart</button>
		  <!-- <p class="card-text"><small class="text-body-secondary">Last updated 3 mins ago</small></p> -->
		</div>
	  </div>
	</div>
  </div>

import { Component, inject, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-delivery-areas',
  standalone: true,
  imports: [],
  templateUrl: './delivery-area.component.html',
  styleUrl: './delivery-area.component.scss'
})
export class DeliveryAreaComponent implements OnInit {
  private meta = inject(Meta);
  private title = inject(Title);

  ngOnInit(): void {
    this.setUpSEOTags()
  }

  private setUpSEOTags() {
    const { baseTitle, baseURL, type } = environment.config.seo;
    const url = 'pages/delivery-areas'
    const component = 'Delivery Areas'
    const title = `${component} | ${baseTitle}`;

    // Page title
    this.title.setTitle(title);

    // Open Graph for social networks:
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ property: 'og:url', content: `${baseURL}${url}` });
    this.meta.addTag({ property: 'og:type', content: type });
  }
}

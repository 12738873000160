import { Component, inject } from '@angular/core';
import { SignalsStoreService } from '../signals-store.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { SidebarService } from './sidebar.service';
import { SidebarCheckList } from './sidebar.types';
import { ShopComponent } from '../../shop/shop.component';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [MatCheckboxModule, FormsModule, ShopComponent],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
  signalsStore = inject(SignalsStoreService);
  sidebarService = inject(SidebarService);

  constructor(public shopComponent: ShopComponent) { }

  toggleSidebar() {
    this.signalsStore.isSidebarOpen.update(currentValue => !currentValue);
  }

  handleCheckChange(item: SidebarCheckList, index: number) {
    item.checked = item.sublist != null && item.sublist.every(t => t.checked);
    this.updateSidebarData(item, index);
    this.shopComponent.scrollToTop();
  }

  private updateSidebarData(item: SidebarCheckList, index: number) {
    const sidebar = this.sidebarService.sidebar();
    sidebar[index] = { ...item };
    this.sidebarService.sidebar.update(() => [...sidebar]);
    this.signalsStore.sidebarFilterChanged.set(sidebar);
  }
}

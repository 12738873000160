import { Component, Signal, computed, inject } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { MatListModule } from '@angular/material/list';
import { CommonModule, Location } from '@angular/common';
import { SignalsStoreService } from '../shared/signals-store.service';
import { SIDEBAR_SETTINGS_PAGES } from './settings.lists';
import { SidebarSettingsParentPage } from './settings.types';

@Component({
    selector: 'app-settings',
    standalone: true,
    imports: [RouterOutlet, MatListModule, RouterLink, RouterLinkActive, CommonModule],
    templateUrl: './settings.component.html',
    styleUrl: './settings.component.scss'
})
export class SettingsComponent {

    private signalsStoreService = inject(SignalsStoreService);

    location = inject(Location)

    itemsList: Signal<SidebarSettingsParentPage[]> = computed(() => this.setUpSettingsPages())

    isShowingMobileFooter = this.signalsStoreService.isShowingMobileFooter;

    session = computed(() => this.signalsStoreService.sessionSignal());

    goBack() {
        this.location.back();
    }

    private setUpSettingsPages(): SidebarSettingsParentPage[] {
        const permissions: any = this.signalsStoreService.permissions();
        if (!permissions) return []
        const items: any = JSON.parse(JSON.stringify(SIDEBAR_SETTINGS_PAGES));
        const list: SidebarSettingsParentPage[] = items.map((p: any) => {
            const children = p.items.filter((c: any) => {
                const keys = c.permissionName.split('.');
                let permission = permissions;
                for (const key of keys) {
                    permission = permission[key]
                }
                return permission.allowed
            });
            p.items = children || [];
            return p
        }).filter((p: any) => p.items?.length);

        return list;
    }
}

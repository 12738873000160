<app-notification></app-notification>
<section class="page-wrap animated fadeIn" >
  <div class="container">
    <h2 class="section-title"><span>Contact Us</span></h2>
    <div class="title is-full-width text-justify">
      <p>Have a question about ordering, account management, billing, how it all works, or product information? Please be sure to check out our FAQ page first!</p>
    </div>

    <div class="row mb-3">
      <div class="col-md-6">
        <div class="row">
          <div class="col-12">
            <p>If you have an immediate issue with the quality or delivery of your order, please reach out directly here.</p>
          </div>
          <div class="col-12">
            <div class="ul-icon-list has-no-circle">
              <span class="list-icon material-symbols-outlined">home</span>
              <div class="list-content">
                <h6>Address</h6>
                <p>29 Hawk Hill Rd, Asheville, NC 28806, United States</p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="ul-icon-list has-no-circle">
              <span class="list-icon material-symbols-outlined">mail</span>
              <div class="list-content">
                <h6>Email</h6>
                <p><a href="mailto:terra@motherearthfood.com">terra&#64;motherearthfood.com</a></p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="ul-icon-list has-no-circle">
              <span class="list-icon material-symbols-outlined">call</span>
              <div class="list-content">
                <h6>Phone (Day time)</h6>
                <p>(828) 275 3500</p>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="ul-icon-list has-no-circle">
              <span class="list-icon material-symbols-outlined">schedule</span>
              <div class="list-content">
                <h6>Pick Up Hours</h6>
                 <table>
                   <tr><td class="pe-4">Sunday </td><td><span class="badge bg-danger">closed</span></td></tr>
                   <tr><td class="pe-4">Monday </td><td><span class="badge bg-danger">closed</span></td></tr>
                   <tr><td class="pe-4">Tuesday </td><td>10am - 3pm</td></tr>
                   <tr><td class="pe-4">Wednesday </td><td>10am - 3pm </td></tr>
                   <tr><td class="pe-4">Thursday </td><td>10am - 3pm</td></tr>
                   <tr><td class="pe-4">Friday </td><td>10am - 6pm</td></tr>
                   <tr><td class="pe-4">Saturday </td><td>9am - 12pm</td></tr>
                 </table>
              </div>
            </div>
          </div>
        </div>
        <div class="map-wrap">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3244.5661480269014!2d-82.63473898843843!3d35.589098834915035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88598c117e36fabd%3A0xfa28f8ed16ecb617!2s29%20Hawk%20Hill%20Rd%2C%20Asheville%2C%20NC%2028806%2C%20USA!5e0!3m2!1sen!2snp!4v1719327747560!5m2!1sen!2snp"  style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <div class="col-md-6 mt-3 mt-md-0">
        
        <form class="contact-form" (submit)="submitForm($event)">
          <p class="text-center text-sm-start">For any other comments, questions, and inquiries - please send us a message below.</p>
          <div class="row">
            <div class="col-6">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="first-name"
                  name="firstName"
                  placeholder="First name"
                  [class.is-invalid]="firstNameValidation.error"
                  [(ngModel)]="formData.firstName"
                  (input)="validateField('firstName', $event)"
                  (blur)="validateField('firstName', $event)">
                <label for="first-name">First Name</label>
                <div *ngIf="firstNameValidation.error"
                  class="feedback">
                  <i class="fas fa-xmark"></i>
                  {{ firstNameValidation.error }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="last-name"
                  name="lastName"
                  placeholder="Last name"
                  [class.is-invalid]="lastNameValidation.error"
                  [(ngModel)]="formData.lastName"
                  (input)="validateField('lastName', $event)"
                  (blur)="validateField('lastName', $event)">
                <label for="last-name">Last Name</label>
                <div *ngIf="lastNameValidation.error"
                  class="feedback">
                  <i class="fas fa-xmark"></i>
                  {{ lastNameValidation.error }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="form-floating mb-3">
                <input
                  type="email"
                  class="form-control"
                  id="email-address"
                  name="email"
                  placeholder="Email"
                  [class.is-invalid]="emailValidation.error"
                  [(ngModel)]="formData.email"
                  (input)="validateField('email', $event)"
                  (blur)="validateField('email', $event)">
                <label for="email-address">Email</label>
                <div *ngIf="emailValidation.error"
                  class="feedback">
                  <i class="fas fa-xmark"></i>
                  {{ emailValidation.error }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  appOnlyNumber
                  [maxlength]="10"
                  class="form-control"
                  id="phone-number"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  [class.is-invalid]="phoneNumberValidation.error"
                  [(ngModel)]="formData.phoneNumber"
                  (input)="validateField('phoneNumber', $event)" 
                  (blur)="validateField('phoneNumber', $event)">
                <label for="phone-number">Phone Number</label>
                <div *ngIf="phoneNumberValidation.error"
                  class="feedback">
                  <i class="fas fa-xmark"></i>
                  {{ phoneNumberValidation.error }}
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-floating mb-3">
                <input
                  type="text"
                  class="form-control"
                  id="subject"
                  name="subject"
                  placeholder="Subject"
                  [class.is-invalid]="subjectValidation.error"
                  [(ngModel)]="formData.subject"
                  (input)="validateField('subject', $event)"
                  (blur)="validateField('subject', $event)">
                <label for="subject">Subject</label>
                <div *ngIf="subjectValidation.error"
                  class="feedback">
                  <i class="fas fa-xmark"></i>
                  {{ subjectValidation.error }}
                </div>
              </div>
              <div class="form-floating mb-3">
                <textarea
                  class="form-control"
                  id="message"
                  name="message"
                  placeholder="Message"
                  [class.is-invalid]="messageValidation.error"
                  [(ngModel)]="formData.message"
                  (input)="validateField('message', $event)"
                  (blur)="validateField('message', $event)"></textarea>
                <label for="message">Message</label>
                <div *ngIf="messageValidation.error"
                  class="feedback">
                  <i class="fas fa-xmark"></i>
                  {{ messageValidation.error }}
                </div>
              </div>
            </div>
          </div>
          <div>
            <button class="btn btn-secondary" [disabled]="isFormInvalid()"> Send Message</button>
          </div>
        </form>
      </div>
    </div>


  </div>
</section>

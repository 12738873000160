import { Component, OnInit, inject } from '@angular/core';
import { ProductListTypes } from '../shared/product-selection-list/product-selection-list.types';
import { ProductSelectionListComponent } from '../shared/product-selection-list/product-selection-list.component';
import { ProductsService } from '../product/products.service';

@Component({
  selector: 'app-buy-again-products',
  standalone: true,
  imports: [ProductSelectionListComponent],
  template: `
    <app-product-selection-list [productListType]="productListTypes.buyAgain"></app-product-selection-list>
  `
})
export class BuyAgainProductsComponent implements OnInit {
  private productService = inject(ProductsService);

  productListTypes = ProductListTypes;

  ngOnInit(): void {
    this.productService.getBuyAgain().subscribe();
  }
}

<div [classList]="containerClassToAplly()">
    <app-notification></app-notification>

    @if (showAppHeader()) {
        <app-header class="sticky-top header"></app-header>
    }
    @if (showAppCartPreview()) {
        <app-cart-preview
            class="cart-preview"
            [class.offcanvas-opened]="isSidebarOpen()">
        </app-cart-preview>
    }
    <div
        class="main-container" appScrollToTop
        [class.header-showing]="showAppHeader()"
        [class.header-showing-hidden-sub-nav]="!shouldShowCategoriesBar()"
        [class.cart-preview-showing]="showAppCartPreview()"
        [class.mobile-main-container]="showMobileFooter()"
        [class.hidden-mobile-footer]="!showMobileFooter()"
    >
        <router-outlet/>
    </div>

    @if (showJustAddedProduct()) {
        <app-product-added-notification
            class="cart-preview just-added-product-notification">
        </app-product-added-notification>
    }

    @if (showMobileFooter()) {
        <app-mobile-footer-menu></app-mobile-footer-menu>
    }

</div>

import { Component, ViewChild, WritableSignal, afterNextRender, computed, inject, signal } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AuthService } from '../auth.service';
import { PasswordCondition } from './login.types';
import { NotificationService } from '../../shared/notification/notification.service';
import { Router, RouterLink } from '@angular/router';
import { SignalsStoreService } from '../../shared/signals-store.service';
import { environment } from '../../../environments/environment';
import { ResolutionService } from '../../shared/resolution.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    RouterLink
  ],
  providers: [AuthService, NotificationService],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})

export class LoginComponent {
  authService = inject(AuthService);
  router = inject(Router);
  private signalsStoreService = inject(SignalsStoreService);
  private resolutionService = inject(ResolutionService);

  @ViewChild('emailCtrl') emailCtrl!: NgModel;
  @ViewChild('passwordCtrl') passwordCtrl!: NgModel;

  email: WritableSignal<string> = signal('');
  password: WritableSignal<string> = signal('');
  hasExtendedSession: boolean = false;
  isMobile = computed(() => this.resolutionService.isMobile());

  hidePassword = true;

  logoVersionNumber = computed(() => this.signalsStoreService.logoVersionNumber());

  showTempPasswordResetFlow = signal(environment.config.flows.login.tempResetPassword);

  contactEmail = signal('')

  passwordConditions: PasswordCondition[] = [
    { text: 'At least 8 characters', key: () => this.isMinLengthValid() },
    { text: 'At least 1 Uppercase (A-Z)', key: () => this.hasUpperCase() },
    { text: 'At least 1 Lowercase (a-z)', key: () => this.hasLowerCase() },
    { text: 'At least 1 Symbol', key: () => this.hasSpecialChar() },
    { text: 'At least 1 Number (0-9)', key: () => this.hasNumber() },
    // { text: 'Do not include spaces', key: () => this.hasNotEmptySpaces() },
  ];

  constructor() {
    afterNextRender(() => {
      this.contactEmail.set(environment.config.contactEmail);
    })
  }

  onEmailChange(text: string) {
    this.email.set(text.toLowerCase());

    if (this.isEmailValid() && !this.emailCtrl.control.errors)
      this.emailCtrl.control?.setErrors(null);
    else if (this.isEmailValid() || !text.length)
      this.emailCtrl.control?.setErrors({ ...this.emailCtrl.control?.errors, email: false });
    else if (!this.isEmailValid() && text.length)
      this.emailCtrl.control?.setErrors({ ...this.emailCtrl.control?.errors, email: true });
  }

  onPasswordChange(text: string) {
    this.password.set(text);

    if (!this.isPasswordValid() && text.length)
      this.passwordCtrl.control?.setErrors({ password: true });
    else
      this.passwordCtrl.control?.setErrors(null);
  }

  isEmailValid(): boolean {
    const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/;
    return emailPattern.test(this.email());
  }

  isPasswordValid(): boolean {
    return this.passwordConditions.every(condition => condition.key());
  }

  isMinLengthValid(): boolean {
    return this.password().length >= 8;
  }

  hasUpperCase(): boolean {
    return /[A-Z]/.test(this.password());
  }

  hasLowerCase(): boolean {
    return /[a-z]/.test(this.password());
  }

  hasSpecialChar(): boolean {
    return /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(this.password());
  }

  hasNumber(): boolean {
    return /\d/.test(this.password());
  }

  // hasNotEmptySpaces(): boolean {
  //   return !((this.password() || '').includes(' '));
  // }


  onSubmit(event: Event) {
    event.preventDefault();

    if (!this.isEmailValid() || !this.isPasswordValid()) return

    this.authService.login(this.email(), this.password(), this.hasExtendedSession)
  }

  goToSignUp() {
    this.router.navigate(['/signup']);
  }

}

import { Component, OnInit, Signal, WritableSignal, computed, inject, signal, Renderer2, AfterViewInit, ElementRef, ViewChild, HostListener, afterNextRender, input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { Router, RouterLink } from '@angular/router';
import { CarouselComponent } from "../shared/carousel/carousel.component";
import { ProductsService } from '../product/products.service';
import { OrderService } from '../shared/order.service';
import { CommonModule } from '@angular/common';
import { DeliveriesService } from '../settings/account/deliveries/deliveries.service';
import { DeliveryInformation } from '../settings/account/deliveries/intarfaces';
import { FirebaseOrder, SignalsStoreService } from '../shared/signals-store.service';
import { ModalContentService } from '../shared/modal-content/modal-content.service';
import { ModalContentTypes } from '../shared/constants/modal-content-types';
import { BundleEditionType, BundleEditionTypes, OrderResponse, ProductOrder, RELATED_ORDER_PRODUCTS } from '../shared/types/order.type';
import { LocalStorageService } from '../shared/local-storage.service';
import { Session } from '../shared/types/session.type';
import { NgbCollapse, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { generateMapKeyFromValues, isANoDeliveryDayUser, isANoPaymentMethodUser, isNoAddressUser } from '../shared/common/utils';
import { ResolutionService } from "../shared/resolution.service";
import { NumberRestrictionDirective } from '../shared/directives/number-restriction.directive';
import { environment } from '../../environments/environment';
import { TipsDonationsService } from '../shared/tips-donations.service';
import { CanDeactivateType } from '../shared/guards/deactivate/can-deactivate.types';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BundleModifyTypes } from '../shared/types/flows-config.types';
import { SubscriptionsService } from '../settings/account/subscriptions/subscriptions.service';
import { DateTime } from 'luxon';
import { MEDIUM } from "../../scss/responsive/responsive";
import { OrderTab } from '../orders/orders.types';
import { EmptyMessageComponent } from '../shared/empty-message/empty-message.component';
import { finalize, tap } from 'rxjs';
import { LOCALSTORAGE_KEYS } from '../shared/constants/databases';
import { formatDateToReadableString } from '../shared/utils/formatting';
import { BundleItems } from '../product/product.types';

@Component({
  selector: 'app-order',
  standalone: true,
  templateUrl: './order.component.html',
  styleUrl: './order.component.scss',
  providers: [SubscriptionsService],
  imports: [
    CommonModule,
    RouterLink,
    FormsModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatInputModule,
    CarouselComponent,
    NgbCollapse,
    NumberRestrictionDirective,
    MatProgressBarModule,
    EmptyMessageComponent
  ]
})

export class OrderComponent implements OnInit, AfterViewInit {
  @ViewChild('layoutOrder', { static: false }) layoutOrder!: ElementRef;
  @ViewChild('orderActionWrap', { static: false }) orderActionWrap!: ElementRef;
  private mutationObserver!: MutationObserver;
  private renderer = inject(Renderer2);

  private modalContentService = inject(ModalContentService);
  private productsService = inject(ProductsService);
  deliveriesService = inject(DeliveriesService)
  localStorageService = inject(LocalStorageService);
  orderService = inject(OrderService);
  tipsDonationsService = inject(TipsDonationsService);
  signalsStoreService = inject(SignalsStoreService);
  private router = inject(Router);
  private subscriptionsService = inject(SubscriptionsService);
  private activeModal = inject(NgbModal);

  private resolutionService = inject(ResolutionService);
  isMobile = computed(() => this.resolutionService.isMobile());

  BundleEditionTypes = BundleEditionTypes;
  updateDonation = ModalContentTypes.UPDATE_DONATION;
  createDonation = ModalContentTypes.CREATE_DONATION;
  updateTip = ModalContentTypes.UPDATE_TIP;
  createTip = ModalContentTypes.CREATE_TIP;

  addressInfo: Signal<any | null> = computed(() => this.setUpAddressInfo())
  bundleCloned: WritableSignal<any> = signal(null);
  bundlesChanged: WritableSignal<Map<number, Map<number, Partial<BundleItems>>>> = signal(new Map());
  bundlesCloned: WritableSignal<Map<number, ProductOrder>> = signal(new Map());
  bundlesRemoved: WritableSignal<Map<number, Map<number, BundleItems>>> = signal(new Map());
  carouselBuyAgainProducts: Signal<any | undefined> = computed(() => this.setUpCarouselItems(RELATED_ORDER_PRODUCTS.BUY_AGAIN, false));
  carouselFavoritesProducts: Signal<any | undefined> = computed(() => this.setUpCarouselItems(RELATED_ORDER_PRODUCTS.FAVORITES, false));
  carouselSuggestedProducts: Signal<any | undefined> = computed(() => this.setUpCarouselItems(RELATED_ORDER_PRODUCTS.SUGGESTED));
  collapseStatuses: { [key: string]: boolean } = {};
  coupon: WritableSignal<string> = signal(''); // actionTrigger
  creditsAmount: Signal<number> = computed(() => +this.getCredits());
  creditsBalance: Signal<number> = computed(() => this.getCreditsBalance());
  odooOrder: Signal<any | null> = computed(() => this.getOdooOrder());
  deliveryFee: Signal<number> = computed(() => +this.calculateDeliveryFee());
  hiddenCarouselProducts: Signal<number[]> = computed(() => this.signalsStoreService.hiddenCarouselProducts());

  minSpendForFreeDelivery: Signal<number> = computed(() => this.odooOrder()?.paymentDetails?.deliveryFee?.minSpend || 0);
  percentMinSpendForFreeDelivery: Signal<number> = computed(() => this.calculatePercentForFreeDelivery());

  deliveryInfo: Signal<any | null> = computed(() => this.setUpDeliveryInfo());
  donationAmount: WritableSignal<number> = signal(0);
  shouldShowTipsAndDonations: Signal<boolean> = computed(() => {
    const p = this.products();
    return p?.subscription?.length || p?.common?.length
  })
  tipAmount: Signal<number> = computed(() => this.getTipAmount());
  donationAmountVoluntary: Signal<number> = computed(() => this.getDonationVoluntaryAmount());

  firebaseOrder: Signal<any | null> = computed(() => this.setUpFirebaseOrder());
  hasPendingBundleChanges: WritableSignal<boolean> = signal(false)
  hasSelectedCustomQuantity: boolean = false;

  isAllowedOrder: Signal<boolean> = computed(() => this.hasMinOrder());
  hasPendingChanges: Signal<boolean> = computed(() => this.checkPendingChanges());

  isCheckedSkipWeekDelivery: boolean = false;
  isConfirmedSkipWeekDelivery: boolean = false;
  isContentLoaded: Signal<any> = computed(() => this.signalsStoreService.isContentLoaded());
  isEditingBundle: boolean = false;
  mapQuantityChanges: any = new Map()
  products: Signal<any | null> = computed(() => this.setUpProducts());
  subTotal: Signal<number> = computed(() => +this.getSubTotal());
  taxes: Signal<number> = computed(() => +this.getTaxes());
  total: Signal<number> = computed(() => +this.getTotal());
  isTemporaryRouteChange = signal(false);
  temporaryRouteChangeMessage = signal('');
  //#region flows config
  hasMembershipFlow = signal(environment.config.flows.membership);
  showFreeDeliverySlider = signal(environment.config.flows.order.showFreeDeliverySlider);
  hasVoluntaryDonationFlow = signal(environment.config.flows.order.voluntaryDonationFlow);
  bundleModifyType = signal(environment.config.flows.bundles.modifyType);
  //#endregion

  allowPageExit = false;

  bundleModifyTypes = BundleModifyTypes;

  userChooseContinueShopping: boolean = false;

  /**
   * This property is isued to validate if the users can submit thier order despite not having reached the minimum price to save the order
   * because the bundle has not been published (its price is equal to zero).
   */
  orderHasUnpublishedBundle: Signal<boolean> = computed(() => {
    const products = this.products();
    const existsUnpublishedSubscriptionBundle = products?.subscription.some((p: any) => p?.bundle?.items && p.totalPrice === 0) || false;
    const existsUnpublishedAlaCarteBundle = products?.common.some((p: any) => p?.bundle?.items && p.totalPrice === 0) || false;
    return existsUnpublishedAlaCarteBundle || existsUnpublishedSubscriptionBundle;
  });

  marketStatus = computed(() => this.signalsStoreService.marketStatus());
  closedMarket = computed(() => {
    const im = this.isMobile();
    const ms = this.marketStatus();
    return {
      title: !ms.isOpen && im ?
        `THE PFW MARKET OPENS AT ${ms.openingHour.toUpperCase()} ON ${ms.openingDay.toUpperCase()}` :
        `THE PHILLY FOODWORKS MARKET OPENS AT ${ms.openingHour.toUpperCase()} ON ${ms.openingDay.toUpperCase()}`,
      legend: 'Come back then to add our freshest selections and peak season products!'
    };
  })

  inpOdooOrder = input.required<OrderResponse>();
  inpOrderSelected = input.required<OrderTab | null>();

  minOrder = input.required<number>();
  isUpdating = signal(false);

  contactEmail = signal('');
  preOrdersSkipDisclaimer = computed(() => {
    const ce = this.contactEmail();
    if (!ce) return '';
    return `You cannot skip this week’s order as it contains pre-ordered products. For assistance, please contact us at ${ce}.`
  })
  preOrderedProductDisclaimer = signal('This pre-ordered product cannot be modified or removed.')
  hasPreorderedProducts = computed(() => {
    const odooOrder = this.odooOrder();
    return (odooOrder?.products?.common || []).some((p: { isPreOrder: boolean; }) => p.isPreOrder);
  });

  async canDeactivate(): Promise<CanDeactivateType> {
    const canExit = await this.canExitOrder();
    return canExit || this.userChooseContinueShopping;
  }

  private async canExitOrder(): Promise<CanDeactivateType> {
    return new Promise(async (resolve, reject) => {
      const hasMinOrder = this.isAllowedOrder()
      const hasPendingProducts = this.hasPendingChanges()

      if (this.allowPageExit) {
        this.allowPageExit = false;
        return resolve(true);
      }

      if (hasMinOrder && hasPendingProducts) {
        const canExit = await this.openModalOrderNotSubmitted() as boolean;
        return resolve(canExit);
      }

      return resolve(true);
    })
  }

  ngOnInit(): void {

    if (this.odooOrder() && this.odooOrder().deliveryInfo?.deliveryDate)
      this.orderService.getStock(this.odooOrder().deliveryInfo.deliveryDate);

    this.setUpDeliveryInfo();
  }
  ngAfterViewInit(): void {
    this.mutationObserver = new MutationObserver((mutations) => {
      this.setPaddingBottom();
    });

    this.#setMutationObserver();

    setTimeout(() => {
      this.setPaddingBottom();
    }, 1);
  }

  constructor() {
    afterNextRender(() => {
      this.#validateTemporaryRoute();
      this.contactEmail.set(environment.config.contactEmail);
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    this.setPaddingBottom();
  }

  isIphone(): boolean {
    return /iPhone/i.test(navigator.userAgent);
  }

  setPaddingBottom(): void {
    if ((window.innerWidth < MEDIUM) || this.isIphone()) {
      if (!this.orderActionWrap?.nativeElement) return;
      // setTimeout(() => {
      const orderActionWrapHeight = this.orderActionWrap.nativeElement.offsetHeight;
      // Set the padding-bottom to the height of .order-action-wrap
      this.renderer.setStyle(
        this.layoutOrder.nativeElement,
        'padding-bottom',
        `${orderActionWrapHeight}px`
      );
      // }, 2000);
    }
    else {
      if (!this.layoutOrder?.nativeElement) return;
      this.renderer.removeStyle(this.layoutOrder.nativeElement, 'padding-bottom');
    }
  }

  checkPendingChanges() {
    return (!!(this.products() &&
      [...this.products()?.common, ...this.products()?.subscription].some(product => product.hasPendingChanges)) || this.hasPendingBundleChanges());
  }

  getTipAmount() {
    const odooTipAmount = this.odooOrder()?.paymentDetails?.tip?.amount || 0
    const firebaseTipAmount = this.firebaseOrder()?.paymentDetails?.tip?.amount || 0
    const showTipOrDonation = this.shouldShowTipsAndDonations();
    if (!showTipOrDonation) return 0
    return firebaseTipAmount || odooTipAmount || 0
  }

  getDonationVoluntaryAmount() {
    const odooDonationAmount = this.odooOrder()?.paymentDetails?.donation?.amount || 0
    const firebaseDonationAmount = this.firebaseOrder()?.paymentDetails?.donation?.amount || 0
    const showTipOrDonation = this.shouldShowTipsAndDonations();
    if (!showTipOrDonation) return 0
    return firebaseDonationAmount || odooDonationAmount || 0
  }

  private calculateProductTotalPrice(product: any): number {
    let totalPrice = 0;
    if (product?.bundle?.items) {
      if (product.bundle.isFixed) {
        totalPrice = +product.price;
        const premiumItems = product?.bundle?.items?.filter((pi: any) => pi.isPremiumAddon);
        if (premiumItems?.length) {
          totalPrice += premiumItems.reduce((total: number, bundleItem: any) => {
            const bundleItemPrice = (+bundleItem?.price * +bundleItem?.quantity) || 0
            return total + bundleItemPrice
          }, 0);
        }
      } else if (product?.bundle?.items?.length)
        totalPrice = product.bundle.items.reduce((total: number, bundleItem: any) => {
          const bundleItemPrice = (bundleItem?.isRemoved ? 0 : (+bundleItem?.price * +bundleItem?.quantity) || 0)
          return total + bundleItemPrice
        }, 0);
    }
    if (totalPrice === 0)
      totalPrice = product.price

    return totalPrice * product.quantity;
  }

  private processProducts(products: any[], subscription: boolean): any[] {
    return products.map(product => {
      const totalPrice = this.calculateProductTotalPrice(product);
      this.collapseStatuses[`${product.id}${subscription ? '_1' : '_0'}`] = true;
      return { ...product, totalPrice };
    });
  }

  getOdooOrder() {
    const odooOrder = this.inpOdooOrder();

    if (!odooOrder || !odooOrder?.products?.common || !odooOrder?.products?.subscription)
      return odooOrder

    this.isCheckedSkipWeekDelivery = odooOrder?.isSkipped || false;
    odooOrder.products.common = this.processProducts(odooOrder?.products?.common || [], false);
    odooOrder.products.subscription = this.processProducts(odooOrder?.products?.subscription || [], true);

    return odooOrder
  }

  private setUpFirebaseOrder() {

    const firebaseOrderAux = this.signalsStoreService
      .firebaseOrder()
      .find(x => x.deliveryDate === this.inpOrderSelected()?.originalDeliveryDate);

    if (!firebaseOrderAux)
      return null

    if (!firebaseOrderAux.products)
      return firebaseOrderAux;

    firebaseOrderAux.products.common = this.processProducts(firebaseOrderAux.products.common || [], false);
    firebaseOrderAux.products.subscription = this.processProducts(firebaseOrderAux.products.subscription || [], true);

    return firebaseOrderAux;
  }

  private setUpProducts(): any {

    const odooOrder = this.odooOrder();
    const firebaseOrder = this.firebaseOrder();

    const { common: odooCommonProducts = [], subscription: odooSubscriptionProducts = [] } = odooOrder?.products || {};
    const { common: fbCommonProducts = [], subscription: fbSubscriptionProducts = [] } = firebaseOrder?.products || {};

    const commonProducts = this.combineArrays(fbCommonProducts, odooCommonProducts, false)
    const subscriptionProducts = this.combineArrays(fbSubscriptionProducts, odooSubscriptionProducts, true)

    return {
      common: commonProducts.sort((a, b) => (b.updatedAt || 0) - (a.updatedAt || 0)),
      subscription: subscriptionProducts.sort((a, b) => (b.updatedAt || 0) - (a.updatedAt || 0)),
    }
  }

  private combineArrays(fbProducts: any[], odooProducts: any[], isSubscription: boolean): any[] {
    // Create a map of fbProducts based on ID for efficient lookup
    const fbProductsMap = new Map();
    fbProducts.forEach(product => {
      product.isSubscription = isSubscription
      let key = product.variant.id;
      if (product.package?.id) {
        key += `_${product.package.id}`;
      }
      fbProductsMap.set(key.toString(), product);
    });

    // Combine the products
    const combinedProducts: any[] = [];
    odooProducts.forEach(product => {
      product.isSubscription = isSubscription
      const variantId = product.variant.id;
      const packageId = product.package?.id || null;
      const fbMapKey = `${variantId}${packageId ? `_${packageId}` : ''}`;
      product['isInOdooOrder'] = true;
      const productData = {
        previousValue: product.quantity,
        quantity: product.quantity,
        validations: {
          isInputValid: false,
          isValueChanged: false,
          wasValueHigher10: product.quantity >= 10,
        },
      }
      const quantityChangesMapKey = generateMapKeyFromValues([fbMapKey, isSubscription]);
      product['firebaseMapKey'] = fbMapKey;
      if (fbProductsMap.has(fbMapKey)) {
        // If there's a match, take quantity from fbProducts
        const fbProduct = fbProductsMap.get(fbMapKey);
        product.quantity = fbProduct.quantity;
        productData.quantity = fbProduct.quantity;
        productData.validations.wasValueHigher10 = fbProduct.quantity >= 10
        product.bundle = fbProduct.bundle
        product.totalPrice = fbProduct.totalPrice
        product['hasPendingChanges'] = true;
        product.updatedAt = fbProduct.updatedAt;
        combinedProducts.push(product);
      } else {
        // If there's no match, take information from odooProducts
        product['hasPendingChanges'] = false;
        combinedProducts.push(product);
      }

      this.mapQuantityChanges.set(quantityChangesMapKey, JSON.parse(JSON.stringify(productData)))
    });

    // Add elements from fbProducts if includeFromFb is true
    fbProducts.forEach(product => {
      if (!odooProducts.some(odooProduct => product.package?.id && odooProduct.package?.id ? (product.package.id === odooProduct.package.id) : (odooProduct.variant.id === product.variant.id))) {
        product['hasPendingChanges'] = true;
        product['isInOdooOrder'] = false;

        const productData = {
          previousValue: product.quantity,
          quantity: product.quantity,
          validations: {
            isInputValid: false,
            isValueChanged: false,
            wasValueHigher10: product.quantity >= 10,
          },
        }
        const variantId = product.variant.id;
        const packageId = product.package?.id || null;
        const fbMapKey = `${variantId}${packageId ? `_${packageId}` : ''}`;
        const mapKey = generateMapKeyFromValues([fbMapKey, isSubscription]);
        this.mapQuantityChanges.set(mapKey, JSON.parse(JSON.stringify(productData)))
        product['firebaseMapKey'] = fbMapKey;
        combinedProducts.push(product);
      }
    });

    return combinedProducts;
  }

  private getCredits() {
    return +this.odooOrder()?.paymentDetails?.credits || 0
  }

  private getSubTotal() {
    const allProducts = [
      ...this.products()?.common ?? [],
      ...this.products()?.subscription ?? []
    ]

    if (!allProducts.length)
      return 0.00
    // Calculate the subtotal of the unsaved products in the current order
    const subtotalFromCurrentOrder = allProducts.reduce((total: number, product: any) => {
      return total + this.calculateProductTotalPrice(product);
    }, 0);
    const couponAmount = this.checkPendingChanges() ? 0 : this.odooOrder()?.paymentDetails?.coupons?.[0]?.amount || 0
    const subTotal = +subtotalFromCurrentOrder - (-couponAmount)
    return subTotal > 0 ? subTotal : 0;
  }

  private getTaxes() {
    if (!this.products())
      return 0

    const allProducts = [...this.products().common, ...this.products().subscription]
    const totalTaxes = allProducts.reduce((total: number, product: any) => {
      let bundleTotalPriceWithoutTax = 0;
      let bundleTotalTax = 0;

      if (product?.bundle?.items?.length) {
        product.bundle.items.forEach((bundleItem: any) => {
          const itemPrice = (+bundleItem.price * +bundleItem.quantity);
          const itemTax = !bundleItem?.taxes ? 0 : (bundleItem.taxes?.reduce((total: number, item: any) =>
            total + +(item?.percentage)
            , 0) / 100)

          bundleTotalPriceWithoutTax += itemPrice;
          bundleTotalTax += (itemPrice * (itemTax));
        });
      }

      let productTaxPercent = 0

      if (!product?.bundle?.items?.length) {
        productTaxPercent = product.taxes?.reduce((total: number, item: any) =>
          total + +(item?.percentage)
          , 0) || 0
      }

      const productPrice = bundleTotalPriceWithoutTax
        ? bundleTotalPriceWithoutTax * +product.quantity
        : product.price
      const productTotalPrice = +productPrice * +product.quantity
      const productTaxAmount = (+productTaxPercent * +productTotalPrice / 100)
      const finalPrice = +(total + productTaxAmount + (bundleTotalTax * +product.quantity)).toFixed(2);

      return finalPrice;
    }, 0);

    return totalTaxes;
  }

  private getTotal(): number {
    const prevTotalAmount = this.subTotal() + this.deliveryFee() + this.taxes() + this.tipAmount() + this.donationAmountVoluntary()
    const totalAmount = (+prevTotalAmount - this.creditsAmount()) || 0

    return totalAmount >= 0 ? totalAmount : 0
  }

  private getCreditsBalance() {
    const creditsAmount = this.creditsAmount()
    if (!creditsAmount)
      return 0

    const prevTotalAmount = this.subTotal() + this.deliveryFee() + this.taxes() + this.tipAmount() + this.donationAmountVoluntary()
    const totalAmountWithoutCredits = prevTotalAmount + creditsAmount;
    const balance = (creditsAmount - totalAmountWithoutCredits) + creditsAmount;

    return (balance > 0) ? balance : 0
  }

  private hasMinOrder(): boolean {

    const couponAmount = (this.checkPendingChanges() ? 0 : this.odooOrder()?.paymentDetails?.coupons?.[0]?.amount || 0) * -1
    return ((this.subTotal() + couponAmount) >= this.minOrder()) || this.orderHasUnpublishedBundle();
  }

  onChangeSkipWeekDelivery() {

    this.isConfirmedSkipWeekDelivery = this.odooOrder()?.isSkipped ? false : true;
    if (this.isCheckedSkipWeekDelivery || !this.odooOrder()?.isSkipped) {
      if (environment.config.flows.order.askForDonationOnSkip)
        return this.openModalDonationSkippable();
      return this.openModalSkipWithDonationFlow();
    }

    const args = {
      orderId: this.odooOrder()?.id,
      isSkipping: false,
      donationAmount: 0,
    }

    this.orderService
      .skipOrder(args, true, this.odooOrder().deliveryInfo.deliveryDate)
      .subscribe({
        next: (res) => {
          const isOrderSkipped = res?.data?.order?.isSkipped || false;
          this.isCheckedSkipWeekDelivery = isOrderSkipped;
          this.isConfirmedSkipWeekDelivery = isOrderSkipped;

          setTimeout(() => {
            this.#setMutationObserver();
          }, 500);
        }
      })
  }

  onChangeInput(event: any, product: any, isSubscription: boolean = false) {
    product.quantity = +event.target.value;

    const productMapped = product
    const productKey = isSubscription ? `subscription` : `common`;
    const firebaseProductsOrder = this.firebaseOrder()?.products?.[productKey] ?? [];
    const newValue = this.updateProductInFirebase(productMapped, firebaseProductsOrder);
    const payload = {
      subKeyPath: `orderProducts.products.${productKey}`,
      newValue
    }

    this.orderService.editSubKey(payload, this.odooOrder()?.deliveryInfo.deliveryDate);
  }

  onChangeQuantity(target: any, product: any, isSubscription: boolean = false, bundleProduct?: any) {
    if (!bundleProduct)
      product.quantity = +target.value;
    else {
      bundleProduct.quantity = +target.value;
      if (!product)
        return
    }

    const productMapped = product
    if (product?.bundle?.items)
      productMapped.bundle = product.bundle

    const productKey = isSubscription ? `subscription` : `common`;
    const firebaseProductsOrder = this.firebaseOrder()?.products?.[productKey] ?? [];
    const newValue = this.updateProductInFirebase(productMapped, firebaseProductsOrder);
    const payload = {
      subKeyPath: `orderProducts.products.${productKey}`,
      newValue
    }

    this.orderService.editSubKey(payload, this.odooOrder()?.deliveryInfo.deliveryDate);
  }

  private validateInput(fbMapKey: any, inputValue: number, isSubscription: boolean): void {
    const mapKey = generateMapKeyFromValues([fbMapKey, isSubscription]);
    const productData = this.mapQuantityChanges.get(mapKey);
    const regex = /^\d+$/;
    if (!productData) return

    productData.validations.isInputValid = regex.test(inputValue.toString());
  }

  private checkValueChange(fbMapKey: number, inputValue: number, isSubscription: boolean, validatePreviousValue: boolean = true): void {
    const mapKey = generateMapKeyFromValues([fbMapKey, isSubscription]);
    const productData = this.mapQuantityChanges.get(mapKey);
    if (!productData) return

    if (productData?.validations.isValueChanged || !validatePreviousValue) return
    productData.validations.isValueChanged = inputValue != productData?.previousValue;
  }

  onKeyUp(fbMapKey: any, event: any, isSubscription: boolean): void {
    const { value } = event?.target
    this.validateInput(fbMapKey, +value, isSubscription);
    this.checkValueChange(fbMapKey, +value, isSubscription);

    const mapKey = generateMapKeyFromValues([fbMapKey, isSubscription]);
    const productData = this.mapQuantityChanges.get(mapKey);
    if (!productData?.validations?.isInputValid)
      return

    if (+value >= 10)
      productData.validations.wasValueHigher10 = true;

    productData.quantity = +value
  }

  onQuantityChange(product: any, target: any, isSubscription: boolean) {
    product.isASubscription = isSubscription;
    const mapKey = generateMapKeyFromValues([product.firebaseMapKey, isSubscription]);
    const productData = this.mapQuantityChanges.get(mapKey);
    if (!productData) return

    if (+target?.value >= 10)
      productData.validations.wasValueHigher10 = true;

    productData.quantity = +target?.value
    this.validateInput(product.firebaseMapKey, +target?.value, isSubscription);

    let validatePreviousValue = true;
    if (+target?.value <= 9) {
      this.updateQuantity(product, isSubscription);
      validatePreviousValue = false;
    }

    this.checkValueChange(product.firebaseMapKey, +target?.value, isSubscription, validatePreviousValue);
  }

  updateQuantity(product: any, isSubscription: boolean = false) {
    product.isASubscription = isSubscription;
    const mapKey = generateMapKeyFromValues([product.firebaseMapKey, isSubscription]);
    const productData = this.mapQuantityChanges.get(mapKey);
    if (!productData) return

    const productMapped = product
    productMapped.quantity = productData.quantity;

    if (product?.bundle?.items)
      productMapped.bundle = product.bundle

    const productKey = isSubscription ? `subscription` : `common`;
    const firebaseProductsOrder = this.firebaseOrder()?.products?.[productKey] ?? [];
    const newValue = this.updateProductInFirebase(productMapped, firebaseProductsOrder);

    const { originalDeliveryDate } = this.inpOrderSelected() ?? {};

    if (!originalDeliveryDate)
      return;

    if (!this.firebaseOrder()) {
      // Should create the firebase order:
      this.orderService.addProductToFirebaseOrder(productMapped, false, true, originalDeliveryDate);
    } else {

      const payload = {
        subKeyPath: `orderProducts.products.${productKey}`,
        newValue
      }

      this.orderService.editSubKey(payload, originalDeliveryDate);
    }
  }

  showInputQuantity(firebaseMapKey: any, isSubscription: boolean) {
    const mapKey = generateMapKeyFromValues([firebaseMapKey, isSubscription]);
    const productData = this.mapQuantityChanges.get(mapKey);
    return productData?.validations?.wasValueHigher10 || productData?.quantity >= 10
      || (productData?.validations?.isValueChanged && productData?.quantity >= 10)
  }

  checkQuantityChange(firebaseMapKey: any, isSubscription: boolean) {
    const mapKey = generateMapKeyFromValues([firebaseMapKey, isSubscription]);
    const productData = this.mapQuantityChanges.get(mapKey);
    return !productData?.validations?.isInputValid || !productData?.validations?.isValueChanged
  }

  onChangeInputBundleItem(bundleProduct: any, event?: any) {
    this.bundleCloned.update((productItem: any) => {
      if (!productItem || !productItem?.bundle || !productItem?.bundle?.items?.length) return

      let totalPrice = 0;
      productItem.bundle.items.forEach((bundle: any) => {
        if (event.target && bundle.id === bundleProduct.id)
          bundle.quantity = +event.target.value

        if (!bundle.isRemoved)
          totalPrice += (bundle?.price ? (+bundle.price * +bundle.quantity) : 0)
      })

      productItem.totalPrice = +totalPrice * +productItem.quantity;

      return productItem
    })
    this.hasPendingBundleChanges.set(true)
  }

  onChangeQuantityBundleItem(bundleProduct: any, target?: any) {
    this.bundleCloned.update((productItem: any) => {
      if (!productItem || !productItem?.bundle || !productItem?.bundle?.items?.length) return

      let totalPrice = 0;
      productItem.bundle.items.forEach((bundle: any) => {
        if (target && bundle.id === bundleProduct.id)
          bundle.quantity = +target.value

        if (!bundle.isRemoved)
          totalPrice += (bundle?.price ? (+bundle.price * +bundle.quantity) : 0)
      })

      productItem.totalPrice = +totalPrice * +productItem.quantity;

      return productItem
    })
    this.hasPendingBundleChanges.set(true)
  }

  openModalTip(action: string) {
    this.signalsStoreService.odooTipAmount.set(this.tipAmount());
    this.modalContentService.openModal(ModalContentTypes.TIP)
      .closed
      .subscribe((res) => {
        if (!res?.amount) return;

        const payload = {
          amount: res?.amount,
          isRecurrent: res?.isRecurrent,
          applyToAllOrders: res?.applyToAllOrders
        }

        if (action === this.createTip) {
          this.tipsDonationsService.create(payload, ModalContentTypes.TIP, this.inpOrderSelected()?.originalDeliveryDate ?? '');
        } else {
          this.tipsDonationsService.update(payload, ModalContentTypes.TIP, this.inpOrderSelected()?.originalDeliveryDate ?? '');
        }
      })
  }

  openModalDonation(action: string) {
    if (this.donationAmountVoluntary())
      this.signalsStoreService.odooDonationAmountVoluntary.set(this.donationAmountVoluntary());

    this.modalContentService.openModal(ModalContentTypes.DONATION)
      .closed
      .subscribe((res) => {
        if (!res?.amount) return;

        const payload = {
          amount: res?.amount,
          isRecurrent: res?.isRecurrent,
          applyToAllOrders: res?.applyToAllOrders
        }

        if (action === ModalContentTypes.CREATE_DONATION) {
          this.tipsDonationsService.create(payload, ModalContentTypes.DONATION, this.inpOrderSelected()?.originalDeliveryDate ?? '');
        } else if (action === ModalContentTypes.UPDATE_DONATION) {
          this.tipsDonationsService.update(payload, ModalContentTypes.DONATION, this.inpOrderSelected()?.originalDeliveryDate ?? '');
        }
      })
  }

  deleteDonationVoluntary() {

    if (this.odooOrder()?.paymentDetails?.donation?.isRecurrent) {
      this.modalContentService.openModal(ModalContentTypes.DELETE_TIP_AND_DONATION)
        .closed
        .subscribe((res) => {
          const applyToAllOrders = Number(res?.applyToAllOrders);
          this.tipsDonationsService
            .delete(
              applyToAllOrders,
              ModalContentTypes.DONATION,
              this.inpOrderSelected()?.originalDeliveryDate ?? ''
            );
        })
    } else {
      this.tipsDonationsService.delete(
        0,
        ModalContentTypes.DONATION,
        this.inpOrderSelected()?.originalDeliveryDate ?? ''
      );
    }
  }

  deleteTip() {
    if (this.odooOrder()?.paymentDetails?.tip?.isRecurrent) {
      this.modalContentService.openModal(ModalContentTypes.DELETE_TIP_AND_DONATION)
        .closed
        .subscribe((res) => {
          const applyToAllOrders = Number(res?.applyToAllOrders);
          this.tipsDonationsService.delete(
            applyToAllOrders,
            ModalContentTypes.TIP,
            this.inpOrderSelected()?.originalDeliveryDate ?? ''
          );
        });
    } else {
      this.tipsDonationsService.delete(
        0,
        ModalContentTypes.TIP,
        this.inpOrderSelected()?.originalDeliveryDate ?? ''
      );
    }
  }

  openModalDonationSkippable() {
    this.signalsStoreService.totalOrderAmount.set(this.odooOrder()?.paymentDetails?.subTotal || 0)
    this.modalContentService.openModal(ModalContentTypes.DONATION_SKIPPABLE).closed
      .subscribe((res) => {
        if (!('isDonationCanceled' in res) || res?.isDonationCanceled)
          this.updateSkipWeekDeliveryFlags(false);
        else {
          const args = {
            orderId: this.odooOrder()?.id,
            isSkipping: true,
            donationAmount: res?.donationAmount || 0,
          }
          this.orderService.skipOrder(args, true, this.odooOrder().deliveryInfo.deliveryDate)
            .subscribe({
              next: (skipRes) => {
                const isOrderSkipped = skipRes?.data?.order?.isSkipped || false;
                this.updateSkipWeekDeliveryFlags(isOrderSkipped);
              }
            });
        }
      })
  }

  openModalSkipWithDonationFlow() {
    this.modalContentService.openModal(ModalContentTypes.CONFIRMATION, {
      title: 'Are you sure?',
      textContent: `You are about to skip your delivery and you will not be receiving your order this week.  Please note that any customized or a la carte products will need to be re-added in future orders.`,
      cancelButtonText: 'Cancel',
      confirmButtonText: 'Confirm',
    }).closed
      .subscribe((res: { confirm: boolean } | null) => {
        if (!res?.confirm) return this.updateSkipWeekDeliveryFlags(false);
        const args = {
          orderId: this.odooOrder()?.id,
          isSkipping: true,
          donationAmount: 0,
        }
        this.orderService
          .skipOrder(args, true, this.odooOrder().deliveryInfo.deliveryDate)
          .subscribe({
            next: (skipRes) => {
              const isOrderSkipped = skipRes?.data?.order?.isSkipped || false;
              this.updateSkipWeekDeliveryFlags(isOrderSkipped);
            }
          });
      })
  }

  private updateSkipWeekDeliveryFlags(isOrderSkipped: boolean) {
    this.isCheckedSkipWeekDelivery = isOrderSkipped;
    this.isConfirmedSkipWeekDelivery = isOrderSkipped;
  }

  private async openModalOrderNotSubmitted() {
    return new Promise((resolve, reject) => {
      this.modalContentService.openModal(ModalContentTypes.CHECK_SUBMIT_ORDER, { closeable: true })
        .closed
        .subscribe((res) => {
          if (!res?.submitOrder) {
            this.orderService.openModalOrderNotSubmitted.set(false)

            if (res.canDeactivate) {
              this.userChooseContinueShopping = true;
              return resolve(true);
            }
            return resolve(false);
          }

          this.updateOrder(this.odooOrder()?.id);

          return resolve(false);
        })
    })
  }

  private updateProductInFirebase(product: any, productList: any[]): any[] {
    let isProductInList: boolean = false;
    const newProductList = productList.map((productItem) => {
      if ((productItem.package?.id && product.package?.id && productItem.package.id == product.package.id) || ((!productItem.package?.id || !product.package?.id) && productItem.variant.id === product.variant.id)) {
        isProductInList = true;
        return product;
      }
      return productItem;
    });

    product.updatedAt = DateTime.now().toMillis();

    if (!isProductInList)
      newProductList.push(product)

    return newProductList
  }

  private removeProductFromArray(product: any, productList: any[]): any[] {
    return productList.filter((productItem) => productItem.package?.id && product.package?.id ? product.package.id !== productItem.package.id : productItem.variant.id !== product.variant.id);
  }

  parseNumber(num: any) {
    return isNaN(num) ? 0 : num
  }

  deleteAllProducts(isCommonKey: boolean = false) {
    const productKey = !isCommonKey ? `subscription` : `common`;
    const updatedProducts: any = [];
    const payload = {
      subKeyPath: `orderProducts.products.${productKey}`,
      newValue: updatedProducts
    }

    this.orderService.editSubKey(payload, this.odooOrder()?.deliveryInfo.deliveryDate);
  }

  deleteProduct(product: any, isSubscription: boolean = false) {

    const deliveryDate = this.inpOrderSelected()?.originalDeliveryDate;

    if (!deliveryDate)
      return;

    if (!product.hasPendingChanges) {
      this.orderService
        .deleteProductOrder(this.odooOrder().id, product.lineId, deliveryDate)
        .subscribe();
      return
    }

    const productKey = isSubscription ? `subscription` : `common`;

    const updateData: any = {}

    if (this.firebaseOrder()?.products) {
      const productsGroup = this.firebaseOrder()?.products?.[productKey]
      const productsGroupUpdated = this.removeProductFromArray(product, productsGroup);
      if (productsGroupUpdated.length !== productsGroup.length) {
        let subKeyPath2 = `orderProducts.products.${productKey}`
        updateData[subKeyPath2] = productsGroupUpdated
      }
    }

    const payload = {
      updateData
    }
    this.orderService.editSubKeys(payload, deliveryDate, this.firebaseOrder());
    this.orderService.trackAddedOrDeletedProductLog(product, true, deliveryDate);
    if (product.lineId) {
      this.orderService.deleteProductOrder(this.odooOrder().id, product.lineId, deliveryDate).subscribe();
    }
  }

  removeProductFromBundle(productId: number, bundleItem: BundleItems, product: ProductOrder) {
    bundleItem.isRemoved = true;

    if (!this.bundlesRemoved().size) {
      const bundlesRemoved = new Map<number, Map<number, BundleItems>>();
      const bundleRemoved = new Map<number, BundleItems>();
      bundleRemoved.set(bundleItem.id, bundleItem);
      bundlesRemoved.set(productId, bundleRemoved);
      this.bundlesRemoved.set(bundlesRemoved)
    }
    else {
      this.bundlesRemoved.update(productsMap => {
        if (!productsMap.has(productId)) {
          const bundleRemoved = new Map<number, BundleItems>();
          bundleRemoved.set(productId, bundleItem);
          productsMap.set(productId, bundleRemoved)
        }
        else {
          if (!productsMap.get(productId)?.has(bundleItem.id))
            productsMap.get(productId)?.set(bundleItem.id, bundleItem)
        }

        return productsMap
      })
    }

    if (!this.bundlesChanged().size) {
      const bundlesChanged = new Map<number, Map<number, BundleItems>>();
      const bundleChanged = new Map<number, BundleItems>();
      bundleChanged.set(bundleItem.id, bundleItem);
      bundlesChanged.set(productId, bundleChanged);
      this.bundlesChanged.set(bundlesChanged)
    }
    else {
      this.bundlesChanged.update(productsMap => {
        if (!productsMap.has(productId)) {
          const bundleChanged = new Map<number, Partial<BundleItems>>();
          bundleChanged.set(productId, { id: bundleItem.id, isRemoved: true });
          productsMap.set(productId, bundleChanged)
        }
        else {
          if (!productsMap.get(productId)?.has(bundleItem.id))
            productsMap.get(productId)?.set(bundleItem.id, bundleItem)
        }

        return productsMap
      })

    }

    this.onChangeQuantityBundleItem(product)
    this.hasPendingBundleChanges.set(true)

  }

  restoreProductFromBundle(productId: number, bundle: BundleItems, product: ProductOrder) {
    bundle.isRemoved = false;

    // Find product in original bundle items:
    const productBundleItem = product.bundle?.items.find(e => e.id === bundle.id);
    if (productBundleItem) productBundleItem.isRemoved = false;

    if (!this.bundlesRemoved().size)
      return

    this.bundlesRemoved.update(productsMap => {
      if (productsMap.has(productId)) {
        if (productsMap.get(productId)?.has(bundle.id)) {
          if (!productsMap.get(productId)?.size)
            productsMap.delete(productId)
          else
            productsMap.get(productId)?.delete(bundle.id)
        }
      }

      return productsMap
    })

    if (!this.bundlesChanged().size)
      return

    this.bundlesChanged.update(productsMap => {
      if (productsMap.has(productId)) {
        if (productsMap.get(productId)?.has(bundle.id)) {
          if (!productsMap.get(productId)?.size)
            productsMap.delete(productId)
          else
            productsMap.get(productId)?.delete(bundle.id)
        }
      }

      return productsMap
    })

    this.onChangeQuantityBundleItem(product)
    this.hasPendingBundleChanges.set(true)
  }

  discardBundleChanges(product: ProductOrder, subscription: boolean) {
    this.bundlesChanged.set(new Map())
    this.bundlesRemoved.set(new Map())
    this.bundleCloned.set(null)
    this.isEditingBundle = false;
    this.hasPendingBundleChanges.set(false)
    this.toggleCollapse(`${product.id}${subscription ? '_1' : '_0'}`);

  }

  saveBundleChanges(subscription: boolean) {
    const product = this.bundleCloned();
    const productMapped = product
    if (product?.bundle?.items)
      productMapped.bundle = product.bundle

    const productKey = product?.isASubscription || product?.isSubscription ? `subscription` : `common`;

    let subKeyPathProducts = `orderProducts.products.${productKey}`
    const updateData: any = {};
    updateData[subKeyPathProducts] = [productMapped];

    const removeBundleFromFirebaseTrigger = () =>
      this.#removeBundleFromFirebase(productKey, product, updateData, subKeyPathProducts, subscription)

    // TODO WE NEED A BETTER WAY TO VALIDATE IF ALL PRODUCTS WERE REMOVED FROM BUNDLE
    if (!product.totalPrice) {
      if (product.lineId) {
        this.orderService
          .deleteProductOrder(this.odooOrder().id, product.lineId, this.odooOrder().deliveryInfo.deliveryDate)
          .pipe(
            tap(() => removeBundleFromFirebaseTrigger)
          ).subscribe();
      }
    } else {
      const order = { products: { [productKey]: [productMapped] } } as FirebaseOrder;
      this.orderService.updateOrder(
        { order, orderId: this.odooOrder()?.id || null, isUpdatingBundle: true, showDefaultMessage: false },
        this.odooOrder().deliveryInfo.deliveryDate
      ).pipe(
        tap(() => removeBundleFromFirebaseTrigger())
      ).subscribe();
    }

  }

  #addBundleChangesToOrder(order: any) {
    const product = this.bundleCloned();
    const productMapped = product
    if (product?.bundle?.items)
      productMapped.bundle = product.bundle

    const productKey = product?.isASubscription || product?.isSubscription ? `subscription` : `common`;
    if (order?.products?.[productKey].length) {
      const existsIndex = order.products[productKey].findIndex((p: any) => p.variantId === productMapped.variantId);
      if (existsIndex > -1) {
        order.products[productKey][existsIndex] = productMapped;
      } else {
        order.products[productKey].push(productMapped);
      }
    } else {
      if (order.products) {
        order.products = {
          ...order.products,
          [productKey]: [productMapped]
        }
      }
      else {
        order = {
          ...order,
          products: {
            [productKey]: [productMapped]
          }
        };
      }
    }

    return order;
  }

  #removeBundleFromFirebase(productKey: string, product: any, updateData: any, subKeyPathProducts: string, subscription: boolean) {
    this.hasPendingBundleChanges.set(false);
    const productsGroup = this.firebaseOrder()?.products?.[productKey] || [];
    if (!productsGroup.length) return;
    const productsGroupUpdated = this.removeProductFromArray(product, productsGroup);
    if (productsGroupUpdated.length !== productsGroup.length)
      updateData[subKeyPathProducts] = productsGroupUpdated;
    const payload = { updateData }
    this.orderService.editSubKeys(payload, this.odooOrder()?.deliveryInfo.deliveryDate, this.firebaseOrder());
    this.bundleCloned.set(null)
    this.isEditingBundle = false;
    this.toggleCollapse(`${product.id}${subscription ? '_1' : '_0'}`);
  }

  cloneBundle(product: ProductOrder, bundleEditionType: BundleEditionType) {
    this.isEditingBundle = true;
    this.bundleCloned.set(JSON.parse(JSON.stringify(product)));
    this.collapseAll();
    this.toggleCollapse(`${product.id}${bundleEditionType === BundleEditionTypes.subscription ? '_1' : '_0'}`);

    this.bundlesRemoved.set(new Map())
    this.bundlesChanged.set(new Map())
    for (const bundleItem of this.bundleCloned().bundle.items) {
      if (bundleItem.isRemoved) {
        this.removeProductFromBundle(this.bundleCloned().id, bundleItem, this.bundleCloned())
      }
    }
  }

  private collapseAll() {
    for (const key in this.collapseStatuses) {
      if (Object.prototype.hasOwnProperty.call(this.collapseStatuses, key)) {
        this.collapseStatuses[key] = true;
      }
    }
  }

  private calculateDeliveryFee(): number {
    // TODO If the client doesnt have a order created, with this logic , it will show FREE, and after the SUBMIT ORDER, then we have the specific amount.
    if (!this.odooOrder()?.paymentDetails)
      return 0

    const { amount, minSpend } = this.odooOrder()?.paymentDetails?.deliveryFee ?? { amount: 0, minSpend: 0 }

    if (this.subTotal() >= minSpend)
      return 0

    return +amount;
  }

  private calculatePercentForFreeDelivery() {
    const subtotal = this.subTotal();
    const minSpend = this.minSpendForFreeDelivery();
    return (subtotal * 100) / minSpend;
  }

  private setUpAddressInfo() {
    const session: Session = this.signalsStoreService.sessionSignal() as any;

    if (!session) return null;

    const { street, city, zip: zipCode, stateCode: state } = session.address;
    const { location, name } = session?.pickUpInfo || {};

    const isAddressRequired = this.signalsStoreService.isAddressRequired();
    return {
      title: isAddressRequired ? 'Delivery Address' : 'Pick Up Address',
      text: isAddressRequired ? [street, city, state, zipCode].join(', ') : `${name} ${location ? `at ${location}` : ''}`
    };
  }

  private setUpDeliveryInfo() {
    if (!this.deliveriesService.deliveryZoneInfo()) return null
    const { deliveryDate, cutoffDate, cutoffTime, deliveryWindow } = (this.deliveriesService.deliveryZoneInfo()?.order || this.deliveriesService.deliveryZoneInfo()) as DeliveryInformation;
    const deliveryDateFormatted = formatDateToReadableString(deliveryDate)
    const cutoffDateFormatted = formatDateToReadableString(cutoffDate)
    const deliveryPickupDateText = this.signalsStoreService.isAddressRequired() ? 'Delivery Date: ' : 'Pick Up Date: '
    return {
      deliveryWindow,
      deliveryDateText: `${this.odooOrder()?.isSkipped ? 'Next ' : ''}${deliveryPickupDateText}${this.inpOrderSelected()?.deliveryDate}`,
      cutoffDateText: `${cutoffDateFormatted.dayName} ${cutoffDateFormatted.month} ${cutoffDateFormatted.day} at ${cutoffTime}`,
      thanksMessage: this.signalsStoreService.isAddressRequired() ? 'Get excited for your delivery, which will arrive on:' : 'Your order will be ready for pick-up on:'
    }
  }

  private setUpProduct(): void {
    return this.productsService.productSignal();
  }


  private setUpCarouselItems(orderProductType: string, isOrderSuggestedProduct: boolean = true) {
    const hiddenProducts: number[] = this.hiddenCarouselProducts();
    if (this.odooOrder()?.isSkipped || this.isMobile()) return [];
    const selectedOrderProductType = Object.values(RELATED_ORDER_PRODUCTS).includes(orderProductType as RELATED_ORDER_PRODUCTS) ? orderProductType
      : RELATED_ORDER_PRODUCTS.SUGGESTED;

    if (!this.isContentLoaded || !this.odooOrder()?.relatedProducts?.[selectedOrderProductType] || !this.odooOrder()?.relatedProducts?.[selectedOrderProductType]?.length)
      if (!this.odooOrder()?.relatedProducts?.[selectedOrderProductType] || !this.odooOrder()?.relatedProducts?.[selectedOrderProductType]?.length)
        return []

    let items = this.odooOrder()?.relatedProducts?.[selectedOrderProductType];

    if (isOrderSuggestedProduct) {
      const allProductsId = [...this.products().common, ...this.products().subscription].map((product: any) => product.id)
      items = items.filter((product: any) => !allProductsId.includes(product.id));
    }

    if (hiddenProducts.length)
      items = items.filter((product: any) => hiddenProducts.indexOf(product.variantId) === -1)


    items = items.map((product: any) => {
      product.image = 'assets/images/logo-main.svg';
      product.name = product.name;
      if (typeof product?.price === 'number')
        product.price = product.price.toFixed(2);

      return ({
        content: {
          orderId: this.odooOrder()?.id,
          product: { ...product, isSubscription: false },
          settings: { isCardInOrderPage: true, hideWhenWasOOS: true }
        },
      })
    })

    return items
  }

  toggleCollapse(id: string) {
    this.collapseStatuses[id] = !this.collapseStatuses[id];
  }

  getBundleEditable(product: ProductOrder): any {
    if (this.bundleCloned() && this.bundleCloned()?.id === product.id)
      return this.bundleCloned() as ProductOrder;
    else
      return product as ProductOrder
  }

  updateOrder(odooOrderId?: number) {
    if (this.isUpdating())
      return;

    this.isUpdating.set(true);

    if (isANoDeliveryDayUser(this.localStorageService, this.modalContentService))
      return this.isUpdating.set(false);
    if (isANoPaymentMethodUser(this.localStorageService, this.modalContentService, this.activeModal))
      return this.isUpdating.set(false);
    if (isNoAddressUser(this.localStorageService, this.modalContentService, this.activeModal, this.router))
      return this.isUpdating.set(false);

    let order = { ...this.firebaseOrder() };

    if (this.bundleCloned() && this.hasPendingBundleChanges()) {
      order = this.#addBundleChangesToOrder(order);
    }

    this.orderService.updateOrder({
      order,
      firebaseOrderSignal: this.signalsStoreService.firebaseOrder,
      orderId: odooOrderId,
      deliveryInfo: this.deliveryInfo(),
      showDefaultMessage: false
    }, this.inpOrderSelected()?.originalDeliveryDate ?? '').pipe(
      tap(() => {
        if (this.bundleCloned() && this.hasPendingBundleChanges()) {
          const product = this.bundleCloned();
          this.discardBundleChanges(product, product.isASubscription);
        }
      }),
      finalize(() => this.isUpdating.set(false))
    ).subscribe()
  }

  applyCoupon() {
    if (this.odooOrder()?.id)
      this.orderService.applyCouponWithOrder(this.coupon, this.odooOrder().deliveryInfo.deliveryDate, this.odooOrder().id);
    else
      this.orderService.updateOrder({
        order: this.firebaseOrder(),
        firebaseOrderSignal: this.signalsStoreService.firebaseOrder,
        coupon: this.coupon,
        deliveryInfo: this.deliveryInfo()
      }, this.odooOrder().deliveryInfo.deliveryDate).subscribe();
  }

  gotToEditBundle(product: any, bundleEditioType: BundleEditionType) {
    if (this.bundleModifyType() === this.bundleModifyTypes.default) {
      this.cloneBundle(product, bundleEditioType)
    } else {
      this.allowPageExit = true;
      this.router.navigate([`/shop/custom-box/${bundleEditioType}/${product.id}`]);
    }
  }

  skipProduct(product: any) {
    this.subscriptionsService.update(product.subscription.id, { skip: true }, true).pipe(
      tap(() => this.orderService.getOrder())
    ).subscribe()
  }

  handleImageError(event: Event) {
    (event.target as HTMLImageElement).src = 'assets/images/product-placeholder.png';
  }

  prevChangeDeliveryDate() {

    if (!this.odooOrder()?.id)
      return;

    this.modalContentService
      .openModal(ModalContentTypes.CHANGE_DELIVERY_DATE, {
        title: 'Manage deliveries',
        textContent: `Would you like to update this delivery to another day?`,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Confirm',
        deliveryDate: this.inpOrderSelected()?.originalDeliveryDate,
        orderId: this.odooOrder().id
      })
      .closed
      .subscribe(result => {
        if (!result || !result.deliveryDate) return;
        this.#changeDeliveryDate(result.deliveryDate);
      });
  }

  #changeDeliveryDate(newDate: string) {
    this.orderService
      .changeDeliveryDate(this.odooOrder().id, newDate);
  }

  #validateTemporaryRoute() {

    const currentSessionInfo: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION);
    if (!currentSessionInfo)
      return;

    const { temporaryChange } = currentSessionInfo?.deliveryInfo ?? {};
    this.isTemporaryRouteChange.set(!!temporaryChange?.active);

    if (this.isTemporaryRouteChange())
      this.temporaryRouteChangeMessage.set(temporaryChange?.message ?? '');
  }

  #setMutationObserver() {
    if (this.orderActionWrap && this.orderActionWrap.nativeElement) {
      this.mutationObserver.observe(this.orderActionWrap.nativeElement, {
        childList: true,
        subtree: true
      });
    }
  }
}

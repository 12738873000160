import { Component, OnInit, inject } from '@angular/core';
import { ProductSelectionListComponent } from '../shared/product-selection-list/product-selection-list.component';
import { ProductListTypes } from '../shared/product-selection-list/product-selection-list.types';
import { ProductsService } from '../product/products.service';

@Component({
  selector: 'app-fav-products',
  standalone: true,
  imports: [ProductSelectionListComponent],
  template: `
    <app-product-selection-list [productListType]="productListTypes.fav"></app-product-selection-list>
  `
})
export class FavProductsComponent implements OnInit {
  private productService = inject(ProductsService);

  productListTypes = ProductListTypes;

  ngOnInit(): void {
    this.productService.getFavorites().subscribe();
  }
}

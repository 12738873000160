<div class="container-fluid animated fadeIn">
  <h1>Memberships</h1>
  <div>
    @defer (on immediate; when !membershipService.loading()) {
      @if (membership()?.id && !membership()?.cardStandard) {
          <div class="card w-100 px-3 pt-3 border-0">
            <div class="card-body row">
              <div class="col-md-4 p-0 pb-5 p-md-3 p-lg-5 overflow-hidden ">
                <img class="img-round" [src]="membership()?.cardImage"/>
              </div>
              <div class="col-md-6">
                <h3 class="card-title mb-0">Roots Rewards Member</h3>
                <small class="text-body-tertiary">Since {{ membership()?.since }}</small>
                <h4 class="mt-4">Benefits</h4>
                <ul class="nav flex-column">
                  @for (benefit of membership()?.cardBenefits; track $index) {
                    <li class="nav-item px-2 d-flex align-items-center gap-3 mt-2">
                      <i class="fas fa-check"></i>
                      <span>{{ benefit }}</span>
                    </li>
                  }
                </ul>


                @if (membership()?.isCancelled) {
                  <p class="mt-5 mb-0">Your membership will be active until {{ membership()?.endDate }}</p>
                } @else {
                  <p class="mt-5 mb-0">Your next billing date is {{ membership()?.nextBillingDate }}</p>
                }
                <b>{{ (membership()?.cardCost() || 0) | currency }}/month</b>
              </div>
            </div>
          </div>

        @if (membership()?.isCancelled) {
          <div class="d-flex mt-2 justify-content-center">
            <button type="button" class="btn btn-primary" (click)="subscribe(0, true)">Renew membership</button>
          </div>
        } @else {
          <div class="d-flex mt-2 justify-content-center">
            <button type="button" class="btn btn-primary" (click)="openModalCancelMembership()">Cancel membership
            </button>
          </div>
        }
      } @else {
        <div class="d-grid mt-4 gap-5">
            @for (card of membershipCardsData(); track $index) {
                <div class="card w-100 h-100 px-3 py-3 shadow" [ngClass]="{'bg-info bg-opacity-10': card.order === 1}">
                  <h5 class="text-primary d-flex align-items-center gap-2 fw-bold">
                    @if (card.id === membership()?.id || card.cardStandard) {
                      <span class="material-symbols-outlined mat-icon-fill">verified</span>
                      {{ card.name }} member
                    }
                  </h5>
                  <h1 class="card-title text-center">{{ card.name }}</h1>

                  <div class="card-body">
                    <div class="row">
                    <div class="col-lg-6 mb-1">
                      <img class="img-section" [src]="card.image"/>
                    </div>
                    <div class="col-lg-6">
                      <p class="card-text">{{ card.description }}</p>
                      <h3 class="text-center my-4">{{ card?.cardCost() || 0 | currency }} / month</h3>
                      <p class="card-text">Here's what you can expect from
                        your {{ card.name }} {{ card.name.toLowerCase().indexOf("membership") > -1 ? '' : 'Membership' }}
                        :</p>

                      <div  class="row">
                        @for (benefitsGroup of card.cardBenefits; track $index) {
                            <ul class="col nav flex-column gap-1">
                              @for (benefit of benefitsGroup; track $index) {
                                <li class="nav-item px-2 d-flex align-items-center gap-3">
                                  <i class="fas fa-check"></i>
                                  <span>{{ benefit }}</span>
                                </li>
                              }
                            </ul>
                        }
                      </div>

                    </div>

                    <div class="d-grid col-md-6 mx-auto align-items-center mt-3">
                      @if (card.id !== membership()?.id && !card.cardStandard) {
                        <button class="btn btn-primary" (click)="openModalMembershipDeposit(card)">Become a Member
                        </button>
                      }
                    </div>
                    </div>
                  </div>
                </div>
            }
          </div>
      }
    }
  </div>
</div>

<section class="page-wrap animated fadeIn">
<div class="container">
  <div class="title is-center is-full-width ">
    <h2 class="title__main mb-3">How It Works</h2>
    <p class="mb-5 text-justify"><b>We partner closely with local farmers for fresh, sustainable produce.</b> Our direct relationships ensure top quality
      in every delivery. With Mother Earth Food, <b>you support farmers nourishing our community and land for
        generations.</b></p>
  </div>

  <div class="step-vertical-grid">
    <div class="step-img"><img src="/assets/images/landing/how/how-1.webp" alt="Our local and regional farm partners"/></div>
    <div class="step-number is-first">
      <div class="number">1</div>
    </div>
    <div class="step-info">
      <div class="count">Step #1</div>
      <div class="step-content-flex">
        <div class="step-text">
          <div class="title">Select a Farm Box</div>
          <div class="desc">Choose from our variety of produce boxes, each filled with the season's best from our local and regional farm partners. Contents change weekly to ensure freshness and reflect the best of what's available.</div>
        </div>
      </div>
    </div>
  </div>
  <div class="step-vertical-grid change-order">
    <div class="step-img"><img src="/assets/images/landing/how/how-2.webp" alt="Customize your box"/></div>
    <div class="step-number">
      <div class="number">2</div>
    </div>
    <div class="step-info">
      <div class="count">Step #2</div>
      <div class="step-content-flex">
        <div class="step-text">
          <div class="title">Make It Your Own</div>
          <div class="desc">Customize your box by selecting your preferred items. Prefer lettuce over kale? No problem! You have the flexibility to tailor your box to your taste preferences. Plus, if you'll be away, you can easily skip any week.</div>
        </div>
      </div>
    </div>
  </div>
  <div class="step-vertical-grid">
    <div class="step-img"><img src="/assets/images/landing/how/how-3.webp" alt="Continue Shopping"/></div>
    <div class="step-number">
      <div class="number">3</div>
    </div>
    <div class="step-info">
      <div class="count">Step #3</div>
      <div class="step-content-flex">
        <div class="step-text">
          <div class="title">Continue Shopping</div>
          <div class="desc">Enhance your order with additional items like local meats, rotating entrées, freshly baked goods, and more! You can also subscribe to weekly staples like eggs and milk, ensuring you always have what you need.</div>
        </div>
      </div>
    </div>
  </div>
  <div class="step-vertical-grid change-order">
    <div class="step-img"><img src="/assets/images/landing/how/how-4.webp" alt="We deliver your order in recyclable packaging"/></div>
    <div class="step-number is-last">
      <div class="number">4</div>
    </div>
    <div class="step-info">
      <div class="count">Step #4</div>
      <div class="step-content-flex">
        <div class="step-text">
          <div class="title">We Deliver, You Enjoy</div>
          <div class="desc">Sit back and relax as we deliver your order in recyclable packaging— no need to be home for delivery. With Mother Earth Food, you can enjoy seasonal and sustainable food with the people you love, knowing that every bite supports local farmers and promotes environmental stewardship.</div>
        </div>
      </div>
    </div>
  </div>


  <div class="d-flex align-items-center justify-content-center">
    <button class="btn btn-primary" routerLink="/signup">Join us</button>
  </div>
</div>
</section>

import { FormControl } from "@angular/forms";

export interface UpdateSubscriptionPayload {
  frequency?: number;
  quantity?: number;
  skip?: boolean;
  unskip?: boolean;
  startDate?: string;
}

export interface UpdateAllSubscriptionsPayload {
  pause: boolean;
  datePauseStart: string;
  datePauseEnd: string;
  startDates: { lineId: number, date: string | null }[];
}

export enum ChangeType {
  cancel = 'cancel',
  pause = 'pause',
  resume = 'resume',
  update = 'update',
  skip = 'skip',
}

export interface Product {
  id: number;
  name: string;
  productUrl: string;
  price: number;
  producer: string;
  category: {
    id: number;
    name: string;
    minSpend: number;
  };
  subCategory: {
    id: number;
    name: string;
    minSpend: number;
  };
  quantity: number;
  variantId: number;
  isBundle: boolean;
}

interface PauseDate {
  startDate: string;
  endDate: string;
  nextDeliveryAfterPause: string;
}

export interface Subscription {
  id: number;
  product: Product;
  existsInOrder: boolean;
  frequency: number;
  nextDelivery: string;
  isPauseActive: boolean;
  price: number;
  active: boolean;
  pauseDate: PauseDate;
  skipDate: string;
  quantity?: number;
  shownNextDelivery?: string;
  previous?: {
    quantity: number;
    frequencyId: number;
  },
  dateAvailables?: string[];
  nextDeliveryBeforePause?: string;
}

export interface SubscriptionPayload {
  variantId: number;
  packageId?: number;
  quantity: number;
  frequencyId: number | string;
  startDate: string,
}

interface NewDeliveryDate {
  newDeliveryDate: FormControl<string | null>;
}

export type SubscriptionResume = Subscription & NewDeliveryDate;

export type GroupSubscription = {
  frequencyId: number;
  frequency: string;
  nextDeliveryDate: string;
  subscriptions: SubscriptionResume[];
};

import { Component, computed, inject } from '@angular/core';
import { RouterLink, RouterLinkActive } from "@angular/router";
import { ResolutionService } from '../resolution.service';
import { SignalsStoreService } from "../signals-store.service";
import { OrderService } from '../order.service';

@Component({
  selector: 'app-mobile-footer-menu',
  standalone: true,
  imports: [RouterLink, RouterLinkActive],
  templateUrl: './mobile-footer-menu.component.html',
  styleUrl: './mobile-footer-menu.component.scss'
})
export class MobileFooterMenuComponent {
  signalsStoreService = inject(SignalsStoreService);
  #orderService = inject(OrderService);
  private resolutionService = inject(ResolutionService);
  isMobile = computed(() => this.resolutionService.isMobile());
  hasOdooOrders = computed(() => this.#orderService.odooOrder()?.length ?? 0);
  hasFirebaseOrders = computed(() => {

    if (!this.signalsStoreService.firebaseOrder()?.length)
      return false;

    return this.signalsStoreService
      .firebaseOrder()
      .some(x => x.products.common.length || x.products.subscription.length);
  });
  showBadgeStatus = computed(() => this.#orderService.hasGetOddooOrders() && this.signalsStoreService.hasGetFirebaseOrders());
}

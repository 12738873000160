import { Component, HostListener, OnInit, afterNextRender, computed, inject, signal } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificationsComponent } from './shared/notification/notification.component';
import { HeaderComponent } from './shared/header/header.component';
import { CommonModule } from '@angular/common';
import { MobileFooterMenuComponent } from './shared/mobile-footer-menu/mobile-footer-menu.component';
import { SignalsStoreService } from './shared/signals-store.service';
import { CartPreviewComponent } from './cart-preview/cart-preview.component';
import { AppService } from './app.service';
import { ResolutionService } from './shared/resolution.service';
import { ProductAddedNotificationComponent } from './shared/product-added-notification/product-added-notification.component';
import { toObservable } from '@angular/core/rxjs-interop';
import { combineLatest, tap } from 'rxjs';
import { environment } from '../environments/environment';
import { LocalStorageService } from './shared/local-storage.service';
import { LOCALSTORAGE_KEYS } from './shared/constants/databases';
import { OrderService } from './shared/order.service';
import { KlaviyoService } from './shared/klaviyo/klaviyo.service';
import { KLAVIYOEVENTS } from './shared/klaviyo/events';
import { Session } from './shared/types/session.type';
import { ScrollToTopDirective } from './shared/directives/scroll-to-top.directive';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    MatProgressBarModule,
    NgbModule,
    NotificationsComponent,
    HeaderComponent,
    CommonModule,
    MobileFooterMenuComponent,
    CartPreviewComponent,
    ProductAddedNotificationComponent,
    ScrollToTopDirective
  ],
  providers: [AppService],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'ecommerce';

  private signalStoreService = inject(SignalsStoreService);
  private router = inject(Router);
  private activatedRoute = inject(ActivatedRoute);
  private appService = inject(AppService);
  private resolutionService = inject(ResolutionService);
  private localStorageService = inject(LocalStorageService);
  private orderService = inject(OrderService);
  private klaviyoService = inject(KlaviyoService);

  private containerClassesToApply = {
    layoutSignup: 'layout-signup',
    layoutStatic: 'layout-static',
    layoutShop: 'layout-shop',
    layoutSettings: 'layout-settings',
    layoutLogin: 'layout-login',
  };

  private containerClassesXRout: any = {
    '/': this.containerClassesToApply.layoutStatic,
    '/pages': this.containerClassesToApply.layoutStatic,
    '/login': this.containerClassesToApply.layoutLogin,
    '/account-recovery': this.containerClassesToApply.layoutLogin,
    '/terms-and-conditions': this.containerClassesToApply.layoutLogin,
    '/settings': this.containerClassesToApply.layoutSettings,
    '/signup': this.containerClassesToApply.layoutSignup,
    '/shop': this.containerClassesToApply.layoutShop,
    '/favorites': this.containerClassesToApply.layoutShop,
    '/buy-again': this.containerClassesToApply.layoutShop,
    '/order': this.containerClassesToApply.layoutShop,
  }

  showAppHeader = signal(false);
  containerClassToAplly = signal('');

  hasSession = this.signalStoreService.hasSession;
  isSidebarOpen = this.signalStoreService.isSidebarOpen;

  showCartPreview = this.signalStoreService.showCartPreview;

  showAppCartPreview = computed(() => this.setUpCartPreviewDisplay());
  showJustAddedProduct = computed(() => this.setUpJustAddedProductDisplay());

  shouldShowCategoriesBar = computed(() => this.signalStoreService.shouldShowCategoriesBar());

  isMobile = computed(() => this.resolutionService.isMobile());
  routeSignal = signal(this.router.url);
  permissionsSignal = computed(() => this.signalStoreService.permissions());

  showMobileFooter = this.signalStoreService.isShowingMobileFooter;

  private isMobiles$ = toObservable(this.isMobile);
  private routeSignal$ = toObservable(this.routeSignal);
  private permissionsSignal$ = toObservable(this.permissionsSignal);

  constructor() {
    afterNextRender(() => {
      this.appService.getMaintenanceState();
    })
  }

  @HostListener('document:click', ['$event'])
  handleClick(event: any) {
    if (this.showJustAddedProduct()) {
      if (event.target.className.indexOf('just-added-product-notification') > -1) {
        this.signalStoreService.justAddedProduct.set(null)
      }
    }
  }

  ngOnInit(): void {
    // this.appService.getMaintenanceState();
    this.setUpRouteSubscription();
    this.activatedRoute.queryParams.subscribe(params => this.appService.checkMarketingReferral(params));
    this.appService.addClassToBody(environment.config.bodyClass);

    combineLatest([this.isMobiles$, this.routeSignal$, this.permissionsSignal$]).pipe(
      tap(() => this.validateCategoriesBarDisplay())
    ).subscribe()

    this.trackKlaviyoLoginEvent();
  }

  private setUpRouteSubscription() {
    this.router.events.subscribe(() => {
      this.showAppHeader.set(this.validateRoutesForHeader());
      this.showCartPreview.set(this.valiteRoutesForCartPreview());
      this.containerClassToAplly.set(this.setUpContainerClass());
      this.routeSignal.set(this.router.url);
    });
  }

  private setUpContainerClass(): string {
    const currentUrl = this.router.url.split('?')[0];
    for (let key in this.containerClassesXRout) {
      if (key === '/' ? currentUrl === key : currentUrl.includes(key)) {
        return this.containerClassesXRout[key];
      }
    }
    return this.containerClassesToApply.layoutShop; // This class by default;
  }


  validateRoutesForHeader(): boolean {
    const allowedRoutes = [
      '/',
      '/order',
      '/pages',
      '/product',
      '/settings',
      '/shop',
      '/favorites',
      '/buy-again',
      '/cart'
    ];
    return this.validateRoutesToShowComponent(allowedRoutes);
  }

  valiteRoutesForCartPreview(): boolean {
    const allowedRoutes = [
      '/product',
      '/shop',
      '/favorites',
      '/buy-again'
    ];
    return this.validateRoutesToShowComponent(allowedRoutes, 'custom-box');
  }

  validateRoutesToShowComponent(allowedRoutes: string[], notAllowedRoute: string = 'null'): boolean {
    const currentUrl = this.routeSignal();
    const cleanedRoute = currentUrl.split('?')[0];
    return allowedRoutes.some(route => (route === '/' ? cleanedRoute === route : cleanedRoute.includes(route)) && !cleanedRoute.includes(notAllowedRoute));
  }

  private setUpCartPreviewDisplay(): boolean {

    if (!this.signalStoreService.showCartPreviewForBusiness())
      return false;

    return false;
    // const hasSession = this.signalStoreService.hasSession();
    // const order = this.signalStoreService.firebaseOrder();
    // const odooOrder = this.orderService.odooOrder();
    // const hasProducts = order?.products?.common?.length || order?.products?.subscription?.length;
    // const hasOdooProducts = odooOrder?.products?.common?.length || odooOrder?.products?.subscription?.length;
    // const shouldShowCartPreview = this.showCartPreview();

    // return hasSession && (hasProducts || hasOdooProducts) && shouldShowCartPreview && !this.isMobile();
    // return hasSession && shouldShowCartPreview && !this.isMobile();
  }

  private setUpJustAddedProductDisplay() {
    return this.signalStoreService.hasSession() &&
      this.signalStoreService.justAddedProduct() &&
      this.isMobile();
  }

  private validateCategoriesBarDisplay() {
    let signupCustomBox = this.localStorageService.get(LOCALSTORAGE_KEYS.SIGNUP_CUSTOM_BOX);
    if (signupCustomBox && this.routeSignal().length > 1 && !(this.routeSignal().includes('/shop/custom-box/subscription'))) {
      this.router.navigate(['/signup']);
    }
    this.signalStoreService.isCustomBoxSignupFlow.set(!!signupCustomBox);
    const isMobile = this.isMobile();

    const shouldHideHeaderCategoriesNav = !!!(this.signalStoreService.permissions()?.shop.allowed ?? true);
    if (shouldHideHeaderCategoriesNav) {
      this.signalStoreService.shouldShowCategoriesBar.set(false);
    } else {
      const shouldShowCategoriesBar = !signupCustomBox && (!isMobile || this.validateRoutesToShowComponent(['/shop', '/favorites', '/buy-again', '/', '/pages']));
      this.signalStoreService.shouldShowCategoriesBar.set(shouldShowCategoriesBar);
    }

    const shouldShowMobileFooter = isMobile && !signupCustomBox && !shouldHideHeaderCategoriesNav && this.validateRoutesToShowComponent(['/shop', '/favorites', '/buy-again', '/settings', '/order', '/cart']);
    this.showMobileFooter.set(shouldShowMobileFooter);
  }

  private trackKlaviyoLoginEvent() {
    const session: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    if (!session || !session?.accountInfo?.id) return;

    const comesFromLogin: boolean | null = this.localStorageService.get(LOCALSTORAGE_KEYS.FROM_LOGIN) || null;
    if (!comesFromLogin) return;

    this.localStorageService.set(LOCALSTORAGE_KEYS.FROM_LOGIN, false);

    // Register Klaviyo "Active on site" event.
    this.klaviyoService.trackEvent(KLAVIYOEVENTS.ActiveOnSite, { email: session.accountInfo.email });
  }

}

import { Component, ElementRef, HostListener, OnInit, Renderer2, WritableSignal, afterNextRender, computed, inject, signal } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FooterComponent } from "../shared/footer/footer.component";
import { RouterLink } from "@angular/router";
import { FirebaseCrudService } from '../shared/firebase-crud.service';
import { ResolutionService } from '../shared/resolution.service';
import { SignalsStoreService } from '../shared/signals-store.service';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [CommonModule, FooterComponent, RouterLink],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  host: { 'class': 'home-page' }
})
export class HomeComponent implements OnInit {

  private firebaseCrudService = inject(FirebaseCrudService);
  private resolutionService = inject(ResolutionService);
  private signalsStoreService = inject(SignalsStoreService);
  private meta = inject(Meta);
  private title = inject(Title);

  hasSession = computed(() => this.signalsStoreService.hasSession());

  flashCard = [
    {
      img: 'home/ripple/Home_Ripple_1-min.webp',
      title: 'Our impact spreads far beyond the plate.',
    },
    {
      img: 'home/ripple/Home_Ripple_2-min.webp',
      title: 'Empowering farmers, strengthening the local economy, and reducing carbon footprint.',
    },
    {
      img: 'home/ripple/Home_Ripple_3-min.webp',
      title: 'Celebrating relationships and fostering connections.',
    }
  ];

  sections: HTMLElement[] = [];

  videoUrl: WritableSignal<string> = signal('');

  isMobile = computed(() => this.resolutionService.isMobile())

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2) {

    afterNextRender(() => {
      this.firebaseCrudService.getHeroVideo().subscribe(url => {
        this.videoUrl.set(url);
        if (url) {
          setTimeout(() => {
            const videoElement = document.querySelector('video');
            if (videoElement) {
              videoElement.muted = true;
              videoElement.play().catch(error => {
                console.log('Error playing video:', error);
              });
            }
          }, 1);
        }
      });
    })

  }

  ngOnInit() {
    this.setUpSEOTags();
    // Retrieve all sections
    const sectionElements = this.elementRef.nativeElement.querySelectorAll('.has-animation');
    this.sections = Array.from(sectionElements);
  }

  @HostListener('scroll', ['$event'])
  onElementScroll(event: any) {
    const scrollPosition = event.target.scrollTop;
    const windowHeight = event.target.clientHeight;

    // Loop through each section
    this.sections.forEach(section => {
      const sectionOffset = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      // Check if the section is in the viewport
      if (scrollPosition >= sectionOffset - windowHeight && scrollPosition < sectionOffset + sectionHeight) {
        // Add a class to the section
        this.renderer.addClass(section, 'animated');
      } else {
        // Remove the class from the section
        this.renderer.removeClass(section, 'animated');
      }
    });
  }

  private setUpSEOTags() {

    const { mainTitle, mainDescription, baseKeywords: keywords, baseURL, type } = environment.config.seo;
    const url = 'home';

    // Page title
    this.title.setTitle(mainTitle);

    // Standart <meta> tag for SEO
    this.meta.addTag({ name: 'description', content: mainDescription });
    this.meta.addTag({ name: 'keywords', content: keywords.join(', ') });

    // Open Graph for social networks:
    this.meta.addTag({ property: 'og:title', content: mainTitle });
    this.meta.addTag({ property: 'og:description', content: mainDescription });
    this.meta.addTag({ property: 'og:url', content: `${baseURL}${url}` });
    this.meta.addTag({ property: 'og:type', content: type });
  }
}



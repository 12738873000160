import { Component, ElementRef, OnInit, ViewChild, WritableSignal, computed, inject, signal } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CUSTOM_DATE_FORMATS } from '../../../shared/constants/date-formtats';
import { CommonModule } from '@angular/common';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PaymentMethodService } from './payment-method.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { AddressService } from '../../../shared/address.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from '../../../../environments/environment';
import { NotificationService } from '../../../shared/notification/notification.service';
import { AccountService } from '../../../shared/account.service';
import { formatDateToYYYYMMDD } from '../../../shared/utils/formatting';
import { MatSelectModule } from '@angular/material/select';
import { ArrayOptionsItem, PaymentDataPayload } from '../../../shared/types/account.types';
import { ModalContentService } from '../../../shared/modal-content/modal-content.service';
import { ModalContentTypes } from '../../../shared/constants/modal-content-types';
import { OnlyNumberDirective } from '../../../shared/directives/only-number.directive';
import { CreditCardService } from '../../../shared/credit-card.service';
import { ANetPayload, ANetResponse } from '../../../shared/purchase/authorize/authorize.types';
import { AuthorizeService } from '../../../shared/purchase/authorize/authorize.service';

@Component({
  selector: 'app-payment-method',
  standalone: true,
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS.MONTH_AND_YEAR },
    PaymentMethodService,
    {
      provide: Loader,
      useValue: new Loader({
        apiKey: environment.apis.google.places,
        libraries: ['places'],
        region: 'US',
      })
    }
  ],
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    ReactiveFormsModule,
    CommonModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    FormsModule,
    MatProgressBarModule,
    MatSelectModule,
    OnlyNumberDirective
  ],
  templateUrl: './payment-method.component.html',
  styleUrl: './payment-method.component.scss'
})
export class PaymentMethodComponent implements OnInit {

  @ViewChild('autocompleteInput') autocompleteInput!: ElementRef<HTMLInputElement>;

  private formBuilder = inject(FormBuilder);
  private paymentMethodService = inject(PaymentMethodService);
  private addressService = inject(AddressService);
  private notificationService = inject(NotificationService);
  private accountService = inject(AccountService);
  private modalService = inject(ModalContentService);
  private creditCardService = inject(CreditCardService);
  #authorizeService = inject(AuthorizeService);

  private paymentMethodInfo = toObservable(this.paymentMethodService.paymentMethod);

  googleAddress: any = signal(undefined);
  hasDeliveryAddressCoverage: any = signal(false);
  isGoogleAddressFilled = signal(false);
  isLoadingContent: any = computed(() => this.addressService.isLoading())

  isEditionModeEnable = signal(false);

  toggleBillingForm = signal(false);

  creditCardForm!: FormGroup;
  billingForm!: FormGroup;

  states: WritableSignal<ArrayOptionsItem[]> = signal([]);

  creditCardType: WritableSignal<any> = signal(null);
  creditCardSecurityCodeMaxLen = computed(() => {
    const cctype = this.creditCardType();
    let maxlen = 4;
    if (cctype) maxlen = cctype.securityCodeLen || maxlen;

    if (this.creditCardForm) {
      const myControl = this.creditCardForm.get('securityCode');
      if (myControl) {
        myControl.setValidators([Validators.required, this.exactLengthValidator(maxlen)]);
        myControl.updateValueAndValidity();
      }
    }

    return maxlen;
  });

  constructor() {
    this.initForms();
  }

  ngOnInit(): void {
    this.getPaymentMethodInfo();
  }

  private initForms() {
    this.creditCardForm = this.formBuilder.group({
      creditCardNumber: [{ value: '', disabled: true }, [Validators.required]],
      firstName: [{ value: '', disabled: true }, [Validators.required]],
      lastName: [{ value: '', disabled: true }, [Validators.required]],
      expirationDate: [{ value: new Date(''), disabled: true }],
      securityCode: ['']
    })

    this.billingForm = this.formBuilder.group({
      street: [''],
      additional: [''],
      city: [''],
      state: [''],
      zipCode: ['']
    })
  }

  private exactLengthValidator(length: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isValid = control.value && control.value.length === length;
      return isValid ? null : { exactLength: { requiredLength: length, actualLength: control.value ? control.value.length : 0 } };
    };
  }

  private getPaymentMethodInfo() {
    this.paymentMethodService.getPaymenthMethodInfo();
    this.paymentMethodInfo.subscribe((data: any) => {
      if (!data) return;
      const creditCardControl = this.creditCardForm.get('creditCardNumber');
      if (creditCardControl) {
        creditCardControl.setValue(data.creditCardNumber);
        creditCardControl.setValue(data.creditCardNumber.toString().padStart(16, '*'));
        this.formatCreditCardNumber();
      }
      const firstNameControl = this.creditCardForm.get('firstName');
      if (firstNameControl) {
        firstNameControl.setValue(data.firstName);
      }
      const lastNameControl = this.creditCardForm.get('lastName');
      if (lastNameControl) {
        lastNameControl.setValue(data.lastName);
      }
    })
  }

  chosenMonthHandler(normalizedDate: Date, datepicker: MatDatepicker<Date>) {
    const date = new Date(normalizedDate);
    const dateControl = this.creditCardForm.get('expirationDate');
    if (dateControl) {
      dateControl.setValue(date);
    }
    datepicker.close();
  }

  discardChangesHandler() {
    this.isEditionModeEnable.set(false);
    this.toggleBillingForm.set(false);
    const creditCardNumberControl = this.creditCardForm.get('creditCardNumber');
    const firstName = this.creditCardForm.get('firstName');
    const lastName = this.creditCardForm.get('lastName');
    creditCardNumberControl?.disable();
    firstName?.disable();
    lastName?.disable();
    this.creditCardForm.get('expirationDate')?.removeValidators(Validators.required);
    this.creditCardForm.get('securityCode')?.removeValidators(Validators.required);
    this.billingForm.reset();
    this.creditCardForm.reset();
    this.getPaymentMethodInfo();
  }

  formatCreditCardNumber() {
    const control = this.creditCardForm.get('creditCardNumber');
    if (!control) return;
    let value = control.value;
    let cleaned = !this.isEditionModeEnable() ? value.replace(/[^\d*]/g, '') : value.replace(/\D/g, '');
    cleaned = cleaned.substring(0, 16);
    this.creditCardType.set(this.creditCardService.typeCard(cleaned));
    let formatted = cleaned.replace(/(.{4})/g, '$1 ').trim();
    control.setValue(formatted);
  }

  enableEditionMode() {
    this.isEditionModeEnable.set(true);
    const creditCardNumberControl = this.creditCardForm.get('creditCardNumber');
    const firstNameControl = this.creditCardForm.get('firstName');
    const lastNameControl = this.creditCardForm.get('lastName');
    creditCardNumberControl?.enable();
    creditCardNumberControl?.setValue('');
    firstNameControl?.enable();
    firstNameControl?.setValue('');
    lastNameControl?.enable();
    lastNameControl?.setValue('');
    this.creditCardForm.get('expirationDate')?.setValidators(Validators.required);
    this.creditCardForm.get('securityCode')?.setValidators(Validators.required);
  }

  validateErrors(controlName: string): string | null {
    const control = this.creditCardForm.get(controlName);
    if (control?.valid || (!control?.dirty && !control?.touched)) return null;
    if (control?.hasError('required')) {
      return `This field is required`
    } else if (control?.hasError('email')) {
      return 'Invalid email format'
    } else if (control?.hasError('exactLength')) {
      return `This field requires ${this.creditCardSecurityCodeMaxLen()} digits`;
    }
    return null;
  }

  handleSaveChangesClicked() {
    if (!this.creditCardForm.valid || !this.billingForm.valid) {
      this.creditCardForm.markAllAsTouched();
      return this.notificationService.show({ text: 'Validate mandatory fields', type: 'error' });
    }

    this.sendDataToANet();
  }

  sendDataToANet() {
    const creditCardFormValues = this.creditCardForm.getRawValue();
    const [year, month, day] = formatDateToYYYYMMDD(creditCardFormValues.expirationDate).split('-')
    const ANetData: ANetPayload = {
      cardCode: creditCardFormValues.securityCode,
      cardNumber: creditCardFormValues.creditCardNumber.replaceAll(' ', ''),
      year,
      month
    };
    this.#authorizeService.sendPaymentDataToAnet(ANetData, (response: ANetResponse) => this.updatePaymentMethod(response))
  }

  private updatePaymentMethod(opaqueData: ANetResponse) {
    const payload = this.setUpPaymentMethodPayload(opaqueData);
    this.accountService.addPaymentMethod(payload, false).subscribe({
      next: (res: any): any => {
        if (res.inProcess)
          return this.modalService.openModal(ModalContentTypes.CONFIRMATION, { title: 'Payment in progress', textContent: 'Your payment update is in progress and this can take a few minutes', confirmButtonText: 'I understand' });
        this.notificationService.show({ text: res.message, type: 'success' });
        this.discardChangesHandler();
      }
    });
  }

  private setUpPaymentMethodPayload(opaqueData: ANetResponse): PaymentDataPayload {
    const billingFormValues = this.billingForm.getRawValue();
    const creditCardFormValues = this.creditCardForm.getRawValue();
    const cardNumber = creditCardFormValues.creditCardNumber.replaceAll(' ', '');
    const cardLastNumbers = cardNumber.substring(cardNumber.length - 4, cardNumber.length);
    const [year, month, day] = formatDateToYYYYMMDD(creditCardFormValues.expirationDate).split('-')
    const date = `${year}-${month}`;
    return {
      billingAddress: {
        additional: billingFormValues.additional || undefined,
        city: billingFormValues.city || undefined,
        stateCode: billingFormValues.state || undefined,
        street: billingFormValues.street || undefined,
        zip: billingFormValues.zipCode || undefined
      },
      card: {
        expirationDate: date,
        firstName: creditCardFormValues.firstName,
        lastName: creditCardFormValues.lastName,
        number: cardLastNumbers,
      },
      opaqueData
    };
  }

  billingAddressToggleChangeHandler(checked: boolean) {
    if (checked && !this.states().length) {
      this.paymentMethodService.getStates().subscribe(states => this.states.set(states));
    }

    if (!checked) {
      this.billingForm?.reset();
    }
  }
}

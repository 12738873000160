import { CommonModule } from '@angular/common';
import { Component, OnInit, WritableSignal, computed, inject, input, signal } from '@angular/core';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { CartService } from '../../cart.service';
import { LocalStorageService } from '../../local-storage.service';
import { SignalsStoreService } from '../../signals-store.service';
import { ProductCard } from '../product-card.types';
import { handleQuantityChange, sanitizeNumericInput } from '../../common/utils';
import { FireBaseProductOrder } from '../../types/order.type';

@Component({
  selector: 'app-horizontal-card-v2',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    MatSelectModule,
    MatInputModule
  ],
  templateUrl: './horizontal-card-v2.component.html',
  styleUrl: './horizontal-card-v2.component.scss'
})

export class HorizontalCardV2Component implements OnInit {
  localStorageService = inject(LocalStorageService)
  signalsStoreService = inject(SignalsStoreService)
  cartService = inject(CartService)

  defaultCard: Partial<ProductCard> = {
    product: undefined,
    settings: {
      hasStock: true,
      showSizes: true,
      showFlavors: false,
      showContentInfo: true,
    }
  }

  card = input(this.defaultCard, {
    alias: 'card',
    transform: (value: any) => ({
      product: { ...this.defaultCard?.product, ...value?.product },
      settings: { ...this.defaultCard.settings, ...value?.settings },
    })
  })

  productInCart = computed(() => this.cartService.checkProductInCart(this.card().product?.id, this.cartService.productsSignal()?.map));

  hasProductInCart = computed(() => !!this.productInCart());
  hasSelectedCustomQuantity: boolean = false;
  hasSession: boolean = false
  isFavorite: boolean = false;
  selectedFlavor = ''
  selectedQuantity: WritableSignal<any> = signal(1);
  selectedSize = 'option1'
  showText: boolean = false;

  ngOnInit(): void {


  }

  onQuantityChange(target: any) {
    const { hasSelectedCustomQuantity, selectedQuantity } = handleQuantityChange(target);
    this.hasSelectedCustomQuantity = hasSelectedCustomQuantity;
    this.selectedQuantity.set(selectedQuantity);
  }

  onInputChange(event: any) {
    const newValue = sanitizeNumericInput(event);
    this.selectedQuantity.set(newValue);
    event.target.value = newValue;
  }

  toggleFavorite() {
    this.isFavorite = !this.isFavorite;
  }

  addProductToCart() {
    const productToAdd = {
      flavor: this.selectedFlavor,
      id: this.card().product?.id,
      image: this.card().product?.img,
      // TODO left logic to define if is a subscription selected by the client or what
      isASubscription: false,
      name: this.card().product?.name,
      price: this.card().product?.price,
      quantity: this.selectedQuantity(),
      size: this.selectedSize,
      totalPrice: this.selectedQuantity() * (this.card().product?.price as number),
    } as Partial<FireBaseProductOrder>

    this.cartService.addProduct(productToAdd)
  }


}

import { Component, computed, inject } from '@angular/core';
import { SignalsStoreService } from '../../../shared/signals-store.service';

@Component({
  selector: 'app-terms-and-conditions',
  standalone: true,
  imports: [],
  templateUrl: './terms-and-conditions.component.html',
  styleUrl: './terms-and-conditions.component.scss'
})
export class TermsAndConditionsComponent {
  private signalsStoreService = inject(SignalsStoreService);
  loading: boolean = false;

  termAndCondition = '';

  logoVersionNumber = computed(() => this.signalsStoreService.logoVersionNumber());

  constructor() {
    fetch('assets/terms_src.html')
      .then(response => response.text())
      .then(htmlContent => this.termAndCondition = htmlContent)
      .catch(error => console.error('Error loading HTML content:', error));
  }

}

import { Component, inject, OnInit } from '@angular/core';
import { RouterLink } from "@angular/router";
import { NgForOf } from "@angular/common";
import { DomSanitizer, Meta, SafeHtml, Title } from "@angular/platform-browser";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-procurement-standards',
  standalone: true,
  imports: [
    RouterLink,
    NgForOf
  ],
  templateUrl: './procurement-standards.component.html',
  styleUrl: './procurement-standards.component.scss'
})
export class ProcurementStandardsComponent implements OnInit {
  private meta = inject(Meta);
  private title = inject(Title);

  flashCard = [
    {
      img: 'standards/Produce-min.webp',
      title: 'Produce',
      desc: '<strong>We seek local, seasonal produce grown using regenerative and sustainable methods.</strong> We prioritize certified organic and beyond organic options, as well as non-GMO production methods. We also expand our reach to bring you variety from other regions, to demonstrate food systems of shared values, prioritizing sustainability.'
    },
    {
      img: 'standards/Meat_Dairy_&_Eggs-min.webp',
      title: 'Meats, Dairy & Eggs',
      desc: 'All of our products meet strict animal welfare standards; w<strong>e favor pasture-raised, free-range, and grass-fed options.</strong> We avoid CAFOs and prioritize vendors that refrain from added antibiotics and hormones.'
    },
    {
      img: 'standards/Fish_&_Seafood-min.webp',
      title: 'Fish & Seafood',
      desc: 'Enjoy sustainably sourced options, <strong>whether wild-caught or responsibly farm-raised,</strong> with traceability ensured.'
    }, {
      img: 'standards/Artisanal_Goods-min.webp',
      title: 'Artisanal Goods',
      desc: 'From beverages to baked goods and pantry items, <strong>we support small companies using real ingredients without additives or preservatives.</strong> We prioritize fair trade and sustainable sourcing.'
    }
  ];

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.setUpSEOTags();
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  private setUpSEOTags() {
    const { baseTitle, baseURL, type } = environment.config.seo;
    const url = 'pages/procurement-standards'
    const component = 'Procurement Standards'
    const title = `${component} | ${baseTitle}`;

    // Page title
    this.title.setTitle(title);

    // Open Graph for social networks:
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ property: 'og:url', content: `${baseURL}${url}` });
    this.meta.addTag({ property: 'og:type', content: type });
  }
}

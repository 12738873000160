@if (product()) {
<section class="mt-4 animated fadeInDown">
    <div class="border-bottom border-dark border-opacity-10">
        <div class="preorder-line">
            <div class="preordered-product">
                <div class="preordered-product__img">
                    <a [routerLink]="['/shop/'+(product()?.productUrl || '')]">
                        <img [src]="product()?.img || 'assets/images/product-placeholder.png'" class="thumb-img"
                            (error)="handleImageError($event)">
                    </a>
                </div>
                <div class="preordered-product__left">
                    <h6>{{product()?.name}}</h6>
                    <p class="text-success-emphasis m-0 fw-medium">
                        {{product()?.price | currency}}
                    </p>
                    <div class="quantity-controls gap-2">
                        <div class="input-plus-minus">

                            <button class="btn-plusminus" [class.disabled]="shouldDisableMinusButton()"
                                (click)="handleMinus()" [disabled]="shouldDisableMinusButton()"
                                [ngbTooltip]="minusButtonToolTipText()" placement="top" tooltipClass="tooltip-md"> -
                            </button>

                            <mat-form-field class="d-flex mb-0" subscriptSizing="dynamic">
                                <input type="text" matInput appNumberRestriction [minNumber]="1" [maxNumber]="100"
                                    readonly [value]="newQuantity()">
                            </mat-form-field>

                            <button class="btn-plusminus" (click)="handlePlus()"
                                [class.disabled]="!product()?.preOrder?.canUpdateProduct"
                                [disabled]="!product()?.preOrder?.canUpdateProduct" [ngbTooltip]="plusButtonToolTipText()"
                                placement="top" tooltipClass="tooltip-md"> + </button>

                        </div>
                        @if (hasPendingChanges()) {
                        <div class="d-flex gap-2">
                            <button type="button" (click)="updateQuantity()"
                                class="btn btn-sm btn-primary animated fadeIn w-max-content">
                                Update
                            </button>
                            <button type="button" (click)="discardChanges()"
                                class="btn btn-sm btn-outline-primary animated fadeIn w-max-content">
                                Discard
                            </button>
                        </div>
                        }
                    </div>
                </div>
            </div>
            <div class="px-4">
                <h6>Delivery Week</h6>
                <p class="m-0 text-success-emphasis">
                    From
                    <span class="fw-medium">
                        {{ product()?.preOrder?._readableStartDeliveryDate?.mmddyyyyFormat}}
                    </span>
                </p>
                <p class="m-0 text-success-emphasis">
                    to
                    <span class="fw-medium">
                        {{ product()?.preOrder?._readableEndDeliveryDate?.mmddyyyyFormat}}
                    </span>
                </p>
            </div>
            @if (!isMobile()) {
            <div class="px-4">
                <h6>Modification Deadline</h6>
                <p class="m-0 text-success-emphasis">
                    <span class="fw-medium">
                        {{ product()?.preOrder?._readableEndDate?.mmddyyyyFormat}}
                    </span>
                </p>
                @if (!product()?.preOrder?.canUpdateProduct) {
                <p class="m-0 fw-medium text-muted">
                    <small><i>{{deadlinePassedMessage()}}</i></small>
                </p>
                }
            </div>

            <div class="px-4">
                <h6>
                    Deposit:
                    <span class="text-success-emphasis">
                        {{product()?.preOrder?.deposit | currency}}
                    </span>
                </h6>
                <div [innerHTML]="depositConfig()?.additionalInfo"></div>
            </div>
            }
        </div>
        @if (isMobile()) {
        <small class="fst-italic">
            <p class="m-0">
                <b>Modification Deadline:</b>
                <span class="text-success-emphasis">
                    {{ product()?.preOrder?._readableEndDate?.mmddyyyyFormat}}
                </span>
            </p>
            @if (!product()?.preOrder?.canUpdateProduct) {
            <p class="m-0 fw-medium text-muted">
                <small><i>{{deadlinePassedMessage()}}</i></small>
            </p>
            }
        </small>
        <small class="fst-italic">
            <b>Deposit:</b>
            <span class="text-success-emphasis">
                {{product()?.preOrder?.deposit | currency}}
            </span>
        </small>
        <div class="h7-regular fst-italic" [innerHTML]="depositConfig()?.additionalInfo"></div>
        }
    </div>
</section>
}
import { Component, HostListener, Inject, OnInit, PLATFORM_ID, ViewChild, WritableSignal, computed, inject, signal } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { SignalsStoreService } from '../signals-store.service';
import { AuthService } from '../../authentication/auth.service';
import { LocalStorageService } from '../local-storage.service';
import { DeliveriesService } from '../../settings/account/deliveries/deliveries.service';
import { ModalContentService } from '../modal-content/modal-content.service';
import { ModalContentTypes } from '../constants/modal-content-types';
import { FormsModule } from '@angular/forms';
import { ModalContentData } from '../modal-content/modal-content';
import { isPlatformBrowser, CommonModule, Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { filter, tap } from 'rxjs';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { HeaderService } from './header.service';
import { MenuService } from '../menu.service';
import { ProductsService } from '../../product/products.service';
import { LOCALSTORAGE_KEYS } from '../constants/databases';
import { Session } from '../types/session.type';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from '../order.service';
import { SubscriptionsService } from '../../settings/account/subscriptions/subscriptions.service';
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatOption } from "@angular/material/autocomplete";
import { MatSelect } from "@angular/material/select";
import { ResolutionService } from '../resolution.service';
import { NAVIGATION_ITEMS, RIGHT_ELEMENTS, SETTINGS_PAGES } from './header.lists';
import { StockService } from '../../stock/stock.service';
import { AccountService } from '../account.service';
import { eCommercePermissions } from '../types/account.types';
import { formatDateToReadableString } from '../utils/formatting';
import { KlaviyoService } from '../klaviyo/klaviyo.service';
import { KLAVIYOEVENTS } from '../klaviyo/events';
import { formatStringForURL, isAddressUpdateRequired } from '../common/utils';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    RouterLink,
    FormsModule,
    SearchBarComponent,
    CommonModule,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    RouterLinkActive,
    NgbModule,
  ],
  providers: [HeaderService, SubscriptionsService],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  signalsStoreService = inject(SignalsStoreService);
  modalContentService = inject(ModalContentService);
  authService = inject(AuthService);
  HAS_MEMBERSHIP_FLOW: boolean = !!environment.config.flows.membership;

  private localStorageService = inject(LocalStorageService);
  private deliveriesService = inject(DeliveriesService);
  private router = inject(Router);
  private headerService = inject(HeaderService);
  private menuService = inject(MenuService);
  private productService = inject(ProductsService);
  private activeModal = inject(NgbModal);
  private orderService = inject(OrderService);
  private location = inject(Location);
  private subscriptionsService = inject(SubscriptionsService);
  private resolutionService = inject(ResolutionService);
  private stockService = inject(StockService);
  private productsService = inject(ProductsService);
  private accountService = inject(AccountService);
  private meta = inject(Meta);
  private title = inject(Title);
  private klaviyoService = inject(KlaviyoService);

  isSessionLoaded = computed(() => this.signalsStoreService.isSessionLoaded());
  shouldShowCategoriesBar = computed(() => this.signalsStoreService.shouldShowCategoriesBar());
  isCustomBoxSignupFlow = computed(() => this.signalsStoreService.isCustomBoxSignupFlow());

  routeSignal = signal(this.router.url);

  isShowingSearchBar = computed(() => !!(this.headerService.displaySearchBar() || this.signalsStoreService.filterByProducer()));
  isSidebarOpen = this.signalsStoreService.isSidebarOpen;

  isShowingSearchMobile = computed(() => (!!(this.headerService.displaySearchBar() || this.signalsStoreService.filterByProducer()) && this.isMobile()));

  userName: WritableSignal<string> = signal('');

  menuItems = this.menuService.menu;

  isMobile = computed(() => this.resolutionService.isMobile());

  landingPages = signal(NAVIGATION_ITEMS);
  settingsPages = computed(() => this.setUpSettingsPermissions());
  rightElements = computed(() => this.setUpRightElementPermissions());

  isShowingMobileFooter = this.signalsStoreService.isShowingMobileFooter;

  logoVersionNumber = computed(() => this.signalsStoreService.logoVersionNumber());

  isContentLoading = computed(() => this.stockService.isLoading() || this.productsService.isLoading());
  isContentLoaded = computed(() => this.stockService.isLoaded() || this.productsService.isLoaded());

  session = computed(() => this.signalsStoreService.sessionSignal());
  showBadgeStatus = computed(() => this.#shouldShowBadgeStatus());
  hasOdooOrders = computed(() => this.orderService.odooOrder()?.length ?? 0);
  hasFirebaseOrders = computed(() => {

    if (!this.signalsStoreService.firebaseOrder()?.length)
      return false;

    return this.signalsStoreService
      .firebaseOrder()
      .some(x => x.products.common.length || x.products.subscription.length);
  });

  @ViewChild(MatSelect) matSelect!: MatSelect;

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  logOut() {
    this.authService.logout();
  }

  ngOnInit(): void {
    if (this.signalsStoreService.filterByProducer()) {
      this.headerService.displaySearchBar.set(true);
    }
    this.subscribeToRouteChanges();
    this.setUpMenu();
    this.setUpUserSession();
    this.getSubscriptions();
  }

  private setUpMenu() {
    if (!isPlatformBrowser(this.platformId)) return;
    this.menuService.fetchMenuCategories().subscribe({
      next: () => this.setUpSelectedCategory(this.location.path()),
    });
  }

  private setUpUserSession() {
    const session: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) || null;
    const permissions: eCommercePermissions | null = this.localStorageService.get(LOCALSTORAGE_KEYS.PERMISSIONS);
    if (session?.accountInfo?.id) {
      this.deliveriesService.getAvailableDeliveryDates();
      this.accountService.getMembershipPermissions().subscribe();
      this.headerService.getFrequencies();
      this.userName.set(session.accountInfo.firstName.toUpperCase());
      if (
        !(!!(permissions?.settings.paymentMethod.allowed) && !(session?.settings?.hasPaymentMethod)) &&
        !(!!(session?.settings?.requireAddress) && !(session?.address?.street))
      ) {
        this.getOrder();
      }

      this.deliveriesService
        .getDeliveryZoneInfo()
        .pipe(
          tap(() => this.validateNewUserOrFirstLogin()),
          tap(result => this.signalsStoreService.getAndSyncCart(result.data))
        )
        .subscribe();
    }
  }

  toggleSearchBar() {
    // this.isShowingSearchBar.update((current: boolean) => !current);
    this.headerService.updateSearchBarDisplay();
  }
  toggleSearchMobile(clearFilters: boolean = false) {
    this.headerService.updateSearchBarDisplay();
    if (clearFilters) this.headerService.clearFilters(true)
  }

  toggleCategorySideMenu() {
    this.signalsStoreService.isSidebarOpen.update(
      (currentValue) => !currentValue
    );
  }

  private subscribeToRouteChanges() {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.routeSignal.set(this.router.url);
        this.setUpSelectedCategory(event.url);
      });
  }

  private getOrder() {
    if (!isPlatformBrowser(this.platformId)) return;
    if (!this.signalsStoreService.hasSession()) return;
    const currentUrl = this.router.url;
    if (((!currentUrl.includes('/order') || currentUrl.includes('/order-and-billing')) && !currentUrl.includes('/cart')) || this.signalsStoreService.isLimitedUser())
      this.orderService.getOrder(false);
  }

  openModalWelcomeMessage() {
    const sessionData = (): any => {
      const hasSession = this.signalsStoreService.hasSession();
      const userData: Session | null = hasSession ? this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) : null;
      const deliveryInfo = userData?.deliveryInfo;
      return {
        title: `Welcome to ${environment.application.name} Food`,
        hasSession,
        fullScreenOnMobile: true,
        welcomeMessage: {
          ...deliveryInfo,
        },
      } as ModalContentData;
    };
    const data: ModalContentData = sessionData();
    this.modalContentService.openModal(ModalContentTypes.WELCOME_MESSAGE, data);
  }

  triggerCategoryInfoFetch(
    category: { id: number; name: string },
    navigate: boolean = true
  ) {
    if (this.isContentLoading()) return;
    const formattedCategoryName = formatStringForURL(category.name);
    const parts = this.location.path().split('/');
    const currentCategoryName = parts[parts.length - 1].toLowerCase();
    if (formattedCategoryName === currentCategoryName && navigate && parts.length === 3) return;
    navigate ? this.router.navigate([`/shop/${formattedCategoryName}`]) : null;
    this.signalsStoreService.selectedCategory.set(category);
    this.setUpSEOTags(category.name, formattedCategoryName);
    this.localStorageService.set(LOCALSTORAGE_KEYS.SELECTED_CATEGORY, category);
  }

  private validateNewUserOrFirstLogin() {
    isAddressUpdateRequired(this.localStorageService, this.modalContentService, this.activeModal, this.router);
    const newUser = this.localStorageService.get(LOCALSTORAGE_KEYS.NEW_ACCOUNT);
    const firstLogin = this.localStorageService.get(LOCALSTORAGE_KEYS.FIRST_LOGIN);
    if (newUser || firstLogin) {
      this.openModalWelcomeMessage();
      this.localStorageService.remove(LOCALSTORAGE_KEYS.NEW_ACCOUNT);
      this.localStorageService.remove(LOCALSTORAGE_KEYS.FIRST_LOGIN);
      // Register klaviyo event:
      this.registerKlaviyoNewSignupEvent();
    }
  }

  private registerKlaviyoNewSignupEvent() {
    const session: Session | null = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION)
    if (!session) return;
    if (session && session.deliveryInfo?.deliveryDay) {
      // Sign Up Date Config:
      const currentDate = new Date();
      const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const currentDay = (currentDate.getDate()).toString().padStart(2, '0');
      const signupDate = `${currentDate.getFullYear()}-${currentMonth}-${currentDay}`;
      const formattedSignupDate = formatDateToReadableString(signupDate).mmddyyyyFormat.replace(/\//g, "-");
      // ***********************
      this.klaviyoService.trackEvent(KLAVIYOEVENTS.NewSignUp, {
        'Sign Up Date': formattedSignupDate,
        'Delivery Date': session.deliveryInfo?.deliveryDay,
        'Cut-Off Day and Time': `${session.deliveryInfo?.cutoffDay} ${session.deliveryInfo?.cutoffTime}`
      })
    };
  }

  private setUpSelectedCategory(url: string) {
    if (this.signalsStoreService.filterByProducer()) return;
    if (url.indexOf('/shop') > -1) {
      const parts = url.split('/');
      let categoryName = parts[parts.length - 1].toLowerCase();
      // Validation for query params:
      if (categoryName.indexOf('?') > -1) categoryName = categoryName.split('?')[0];
      if (!this.menuItems().length) return;
      let category = undefined;
      if (categoryName === 'shop') {
        if (
          this.signalsStoreService.isFiltering() &&
          this.signalsStoreService.selectedCategory().id > -1
        ) {
          category = this.signalsStoreService.selectedCategory();
        } else {
          category = this.menuItems()[0];
        }
      } else
        category = this.menuItems().find(
          (e: any) => formatStringForURL(e.name) === categoryName
        );
      if (!category) return;
      this.triggerCategoryInfoFetch(category, categoryName === 'shop');
      if (this.signalsStoreService.isFiltering()) {
        // If the user is making a filter, the, avoid to get products and should get stock for those products:
        const ids = this.productService.productsSignal().map((p: any) => p.id);
        this.stockService.getStock(undefined, ids);
        return
      };
      this.productService.getProducts(category.id);
    } else {
      this.signalsStoreService.selectedCategory.set({ id: -1, name: '' });
    }
  }

  private getSubscriptions() {
    if (!isPlatformBrowser(this.platformId)) return;
    if (!this.signalsStoreService.hasSession()) return;
    this.subscriptionsService.get();
  }

  private setUpSettingsPermissions() {
    const permissions = this.signalsStoreService.permissions();
    if (!permissions) return;

    // Set Up Settings Pages:
    const items = JSON.parse(JSON.stringify(SETTINGS_PAGES))
    const settingPages = items.map((p: { children: any[]; }) => {
      const children = p.children.filter((c: { permissionName: string; }) => {
        const keys = c.permissionName.split('.');
        let permission: any = permissions;
        for (const key of keys) {
          permission = permission[key]
        }
        return permission.allowed
      });
      p.children = children || [];
      return p
    }).filter((p: { children: string | any[]; }) => p.children?.length);

    return settingPages;
  }

  private setUpRightElementPermissions() {
    // Get the local storage permissions variable:
    const permissions: any = this.signalsStoreService.permissions();
    if (!permissions) return;

    // Set Up Right Elements:
    const items = JSON.parse(JSON.stringify(RIGHT_ELEMENTS));
    const rightElements = items.filter((p: { showWithoutPermission: any; permissionName: string; }) => {
      if (p.showWithoutPermission) return true;
      const keys = p.permissionName.split('.');
      let permission = permissions;
      for (const key of keys) {
        permission = permission[key]
      }
      return permission.allowed
    });

    return rightElements || []
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    const targetElement = event.target as HTMLElement;
    if (this.matSelect?.panelOpen && !this.matSelect?._elementRef.nativeElement.contains(targetElement)) {
      this.matSelect.close();
    }
  }

  private setUpSEOTags(categoryName: string, formattedCategoryName: string) {
    const { baseTitle, baseURL, type } = environment.config.seo;
    const url = `shop/${formattedCategoryName}`;
    const title = `${categoryName} | ${baseTitle}`;

    // Page title
    this.title.setTitle(title);

    // Open Graph for social networks:
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ property: 'og:url', content: `${baseURL}${url}` });
    this.meta.addTag({ property: 'og:type', content: type });
  }

  #shouldShowBadgeStatus() {
    return (this.orderService.hasGetOddooOrders() && this.orderService.odooOrder().some(o => o.id)) ||
      (this.signalsStoreService.hasGetFirebaseOrders() && this.signalsStoreService.firebaseOrder().some(order => {
        return !!(order.products.common.length || order.products.subscription.length)
      }));
  }
}

<div class="animated fadeIn">
  <div class="settings">
    <div class="settings__title">
      <h1>Friends Referral</h1>
    </div>
  </div>

  <div class="row">
    <div class="col-md-7">
      <p class="description">
        Local food is better shared. When you refer someone to Mother Earth you will earn <b>$50!</b> And your friend will also receive a <b>$25 discount</b> off their first order too.
      </p>

      <div class="referral-wrap my-5">
          <label class="label">Friend referral link:</label>
        <div class="input-group mb-3">
          <input type="text" class="form-control" readonly value="{{referralBaseUrl()}}{{referralProgram()?.referralCode}}">
          <button class="btn btn-primary" type="button" matTooltip="Copy referral link to clipboard"
                  (click)="copyCodeToClipboard()">Copy Link</button>
        </div>
      </div>

    </div>

    <div class="col-md-4 offset-md-1">
      <div class="referral__info-box my-5 clearfix">
      <h4 class="mt-4">Your Referrals</h4>

        <section class="d-grid">
            <div class="hstack justify-content-between py-2 border-bottom border-dark border-opacity-10">
                  <small>Total Referrals</small>
                  <h3>{{referralProgram()?.totalReferrals || 0}}</h3>
            </div>
            <div class="hstack justify-content-between py-2 border-bottom border-dark border-opacity-10">
                <small>Total Rewards</small>
                <h3>{{(referralProgram()?.totalRewards || 0) | currency}}</h3>
            </div>
              <div class="hstack justify-content-between py-2">
                @if(isMobile()) {
                  <div>
                    <h6 class="m-0 fw-normal">Pending Rewards</h6>
                    <p class="pending-rewards m-0">Friends who signed up but have not place an order yet</p>
                  </div>
                } @else {
                  <small>Pending Rewards <span class="material-symbols-outlined text-warning" ngbTooltip="Friends who signed up but have not place an order yet">info</span></small>
                }
                <h3>{{(referralProgram()?.pendingRewards || 0) | currency}} </h3>
            </div>
        </section>
      </div>
    </div>
  </div>

  @if (isMobile()) {
    <div class="border-top border-bottom border-dark border-opacity-10">
      <div class="w-100 mt-3" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" (click)="mobileReferralListCollapsed.set(!mobileReferralListCollapsed())">
        <section class="hstack">
          <h3 class="w-50">Referrals</h3>
          <section class="w-50 text-end">
            @if (mobileReferralListCollapsed()) {
              <span class="material-symbols-outlined">
                keyboard_arrow_down
              </span>
            } @else {
              <span class="material-symbols-outlined">
                keyboard_arrow_up
              </span>
            }
          </section>
        </section>
      </div>
      <div class="collapse" id="collapseExample">
        <section class="vstack gap-3 animated fadeIn">
          @for(referral of referralProgram()?.referrals; track $index) {
            <section class="vstack">
              <hr class="mb-3">
              <!-- Referral name and email -->
              <section class="hstack">
                <div class="w-50">
                  <b>{{referral.name}}</b>
                </div>
                <div class="w-50 text-end">
                  <span class="float-end">{{referral.email}}</span>
                </div>
              </section>
              <!-- Referral status and user signup date -->
              <section class="d-flex justify-content-between gap-3">
                <div class="d-flex gap-1">
                  <b>{{referral.state}}</b> <span class="float-end">{{referral.shownSignupDate}}</span>
                </div>
                <div class="d-flex gap-3">
                  <b>Reward</b> <span class="float-end">{{referral.reward | currency}}</span>
                </div>
              </section>
            </section>
          }
          <div class="clearfix pt-3"></div>
        </section>
      </div>
    </div>
  } @else {
    <div class="mt-5">
      @for (referral of referralProgram()?.referrals; track $index) {
          <section class="vstack mt-2">
              <div class="border-bottom border-dark border-opacity-10">
                  <div class="hstack justify-content-between">
                      <div class="px-4">
                          <h6>{{referral.name}}</h6>
                          <p class="text-success-emphasis">
                              {{referral.email}}
                          </p>
                      </div>
                      <div class="px-4">
                          <h6>{{referral.state}}</h6>
                          <p class="text-success-emphasis">
                              {{referral.shownSignupDate}}
                          </p>
                      </div>
                      <div class="px-4">
                          <h6>{{referral.reward | currency}}</h6>
                          <p class="text-success-emphasis">
                              reward
                          </p>
                      </div>
                  </div>
              </div>
          </section>
      }
    </div>
  }

  <section class="pt-2">
      <h3 class="h3-semibold text-center mt-5 mb-4">How it Works</h3>
      <div class="step-ref">
        @for (item of howItWorksData; track $index) {
          @if ($index > 0 && !isMobile()) {
            <div class="step-ref__arrow">
              <span class="material-symbols-outlined">
                chevron_right
              </span>
            </div>
          }
          <div class="step-ref__text">
                <span class="step-ref__count">{{item.step}}</span>
                <p class="card-text" [innerHTML]="item.textContet"></p>
          </div>
        }
      </div>
  </section>

</div>

import { Component, EventEmitter, Input, Output, WritableSignal, computed, inject, signal } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PersonalInformation } from '../signup.types';
import { MatStep, MatStepper, MatStepperModule } from '@angular/material/stepper';
import { PeopleShoppingFor } from '../../../shared/types/account.types';
import { AccountService } from '../../../shared/account.service';
import { CommonModule} from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterLink } from '@angular/router';
import { MatRadioModule } from '@angular/material/radio';

@Component({
  selector: 'app-preferences-validation-signup',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIcon,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    RouterLink,
    MatRadioModule,
  ],
  templateUrl: './preferences-validation-signup.component.html',
  styleUrl: './preferences-validation-signup.component.scss'
})
export class PreferencesValidationSignupComponent {
  accountService = inject(AccountService);

  @Input() stepper!: MatStepper;
  @Input() firstStep!: MatStep;
  @Input() dietaryRestrictions: string | any[] | undefined;
  @Input() peopleOptions: PeopleShoppingFor[] | undefined;
  @Output() onNextStep: EventEmitter<any> = new EventEmitter<any>()
  @Output() formValidityChanged = new EventEmitter<boolean>();
  @Output() shopforChanged = new EventEmitter<any>();
  @Output() dietaryChanged = new EventEmitter<any>();
  isLinear = true;

  personalInformation: WritableSignal<PersonalInformation | null> = signal(null);

  isCheckedDietaryRestrictions: boolean = false;

  isGoogleAddressFilled = signal(false);
  hasDeliveryAddressCoverage: any = signal(false);
  isValidFormShoppingPreferences = computed(() => this.validateShoppingInfoForm());

  updatePersonalInformation(newValue: any) {
      this.personalInformation.update(value => ({ ...value, ...newValue }));
  }

  private validateShoppingInfoForm() {
    let valid;

    const formFilled = !!(this.personalInformation()?.shopfor !== null)
    if(this.personalInformation()?.shopfor && formFilled) {
      valid = true;
    }
    this.formValidityChanged.emit(valid);
    return valid;
  }

  createNewClient() {
    this.firstStep.completed = true
    this.shopforChanged.emit(this.personalInformation()?.shopfor);
    this.dietaryChanged.emit(this.dietaryRestrictions);
    this.onNextStep.emit(this.firstStep);
  }

}
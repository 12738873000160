<section class="page-wrap">
  <div class="container">
    <h1 class="section-title"><span>Our Producers</span></h1>

    <div class="title is-center">
      <h2 class="title__main mb-3">Our Farmers<br />& Food Artisans</h2>
    </div>

    <div class="spacer-5"></div>

    <div class="container-fluid">
      <div class="row">
        @defer (on immediate; when producersCards().length) { @if
        (producersCards()) { @for (producer of producersCards(); track
        producer.id) { @defer (on viewport; prefetch on immediate) {
        <div class="col-xl-3 col-lg-4 col-sm-6 animated fadeIn">
          <div class="card card-producer">
            <div class="card-body">
              <img
                src="{{ producer.img }}"
                class="card-img"
                alt="{{ producer.name }}"
              />
              <a [routerLink]="['/pages/producers/' + producer.detailUrl]">
                <h3 class="card-title">{{ producer.name }}</h3>
              </a>
              <div class="card-detail mt-2" [innerHTML]="producer.description"></div>
            </div>
          </div>
        </div>
        } @placeholder {
        <div class="placeholder-glow animated fadeIn">
          <div class="col-xl-3 col-lg-4 col-sm-6 animated fadeIn bg-secondary"></div>
        </div>
        } } } }
      </div>
    </div>
  </div>
</section>

@if (producerDetails()) {
<section class="has-block-padding-sm">
  <div class="row align-items-center">
    <div class="col-6">
      <img
        src="{{ producerDetails()?.img }}"
        class="img-fluid"
        alt="{{ producerDetails()?.name }}"
      />
    </div>
    <div class="col-6">
      <h2 class="section-title">
        <span>{{ producerDetails()?.name }}</span>
      </h2>
      <div [innerHTML]="sanitizeHtml(producerDetails().description)"></div>
    </div>
  </div>
  <div class="spacer-4"></div>
  @if(producerDetails().products.length) {
  <h2 class="section-title">Their products</h2>
  <div class="row">
    <app-carousel
      [items]="carouselProducerProducts()"
      [type]="'producerProduct'"
      [hasMoreItems]="producerDetails()?.hasMoreProducts"
    ></app-carousel>
  </div>
  }
</section>
}

import { Component, Inject, OnInit, PLATFORM_ID, Signal, WritableSignal, computed, inject, signal, viewChild } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CarouselComponent } from '../shared/carousel/carousel.component';
import { HeaderComponent } from '../shared/header/header.component';
import { ProductCardComponent } from '../shared/product-card/product-card.component';
import { ProductsService } from './products.service';
import { NotificationService } from '../shared/notification/notification.service';
import { SignalsStoreService } from '../shared/signals-store.service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { StockService } from '../stock/stock.service';
import { LocalStorageService } from '../shared/local-storage.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { NotificationsComponent } from '../shared/notification/notification.component';
import { finalize, tap } from 'rxjs';
import { ApiResponse } from '../shared/common/types';
import { KlaviyoService } from '../shared/klaviyo/klaviyo.service';
import { KLAVIYOEVENTS } from '../shared/klaviyo/events';
import { capitalizeWords, unableOperationMessage } from '../shared/common/utils';
import { ModalContentService } from '../shared/modal-content/modal-content.service';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-product',
  standalone: true,
  imports: [
    RouterModule,
    HeaderComponent,
    ProductCardComponent,
    CarouselComponent,
    CommonModule,
    MatProgressBarModule,
    NotificationsComponent
  ],
  providers: [
    NotificationService,
    ProductsService,
  ],
  templateUrl: './product.component.html',
  styleUrl: './product.component.scss',
})

export class ProductComponent implements OnInit {
  private productCard = viewChild.required<ProductCardComponent>('productCard');
  private meta = inject(Meta);
  private title = inject(Title);
  private route = inject(ActivatedRoute);
  private productsService = inject(ProductsService);
  private signalsStoreService = inject(SignalsStoreService);
  private router = inject(Router);
  private modalContentService = inject(ModalContentService);
  localStorageService = inject(LocalStorageService)
  stockService = inject(StockService)
  private klaviyoService = inject(KlaviyoService);

  seoURL!: string;
  isContentLoaded: WritableSignal<boolean> = signal(false);

  product: Signal<any> = computed(() => this.productsService.productSignal() || null)
  productCardSummary: Signal<any> = computed(() => this.setUpProductCardSummary());
  carouselItems: Signal<any> = computed(() => this.setUpCarouselItems());
  productImages: Signal<any[]> = computed(() => this.setUpProductImages());

  isFavorite = computed(() => this.checkFavProduct());
  selectedAttribute: WritableSignal<any> = signal('')

  productId: WritableSignal<number> = signal(0);
  stockSignal = computed(() => this.stockService.mapStockSignal());
  obsStockSignal = toObservable(this.stockSignal);
  variant: WritableSignal<any> = signal(null);

  likeTotals = computed(() => this.setUpFavTotals());

  private stockMapKey: any = null;

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngOnInit(): void {
    if (!isPlatformBrowser(this.platformId)) return;
    this.handleRouteChanges();
    this.isContentLoaded = this.signalsStoreService.isContentLoaded;
  }

  private setUpSEOTags(product: string) {
    const { baseTitle, baseURL, type } = environment.config.seo;
    const url = `shop/${product}`
    const title = `${capitalizeWords(product.replace(/\//g, ' | '))} | ${baseTitle}`;

    // Page title
    this.title.setTitle(title);

    // Open Graph for social networks:
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ property: 'og:url', content: `${baseURL}${url}` });
    this.meta.addTag({ property: 'og:type', content: type });
  }

  private handleRouteChanges(): any {
    this.route.params.subscribe((params) => {
      console.log('params', params);
      this.seoURL = `${params['category']}/${params['subcategory']}/${params['product']}`;
      this.setUpSEOTags(this.seoURL);
      this.productsService.getProductByUrl(this.seoURL).pipe(
        tap(({ data: product }: ApiResponse<any>) => {
          if (!this.signalsStoreService.hasSession()) return;
          if (product) {
            this.klaviyoService.trackEvent(KLAVIYOEVENTS.ProductViewed, {
              bundle: product.bundle?.items || [],
              category: product.category,
              id: product.id,
              name: product.name,
              price: product.price,
              productUrl: product.productUrl,
              size: product.size || '',
              subcategory: product.subCategory,
              originalPrice: product.originalPrice,
              producer: product.producer,
              specialCategory: product.specialCategory,
              tags: product.tags
            });
          }
        })
      ).subscribe()
    });
  }

  setUpProductCardSummary() {
    const product = this.productsService.productSignal();
    localStorage.setItem('selectedProductId', product.id);
    return {
      product,
      settings: {
        isSummary: true
      }
    };
  }

  private setUpProductImages() {
    const product = this.productsService.productSignal();
    if (!product) return [];
    if (this.variant()?.image)
      return !!(this.variant()?.image || this.variant()?.additionalImages?.length) ? [this.variant()?.image, ...(this.variant()?.additionalImages || [])] : ['assets/images/product-placeholder.webp'];
    return !!(product?.img || product?.additionalImages?.length) ? [product?.img, ...product?.additionalImages] : ['assets/images/product-placeholder.webp'];
  }

  private setUpCarouselItems() {
    const product = this.productsService.productSignal();
    if (!product) return [];
    return product?.suggestedProducts?.map((suggestedProduct: any) => {
      suggestedProduct.image = 'assets/images/product-placeholder.webp';

      return ({
        content: {
          product: { ...suggestedProduct, isSubscription: false },
          settings: {
            showSizes: false,
            showFlavors: false,
            showContentInfo: false,
            hideWhenWasOOS: true
          }
        }
      })
    });
  }

  gotoCategory() {
    this.router.navigate(['/shop/' + (this.product()?.category?.name || '').toLowerCase().replace(/\s/g, '-')]);
  }

  onSelectedVariant(e: any) {
    this.variant.set(e);
  }

  handleFavoriteChange(event: any) {
    // If there is not stock yet, we shoul prevent for this acction:
    if (!this.stockService.mapStockSignal()?.size) return;
    // TODO VALIDATION, IF EP STOCK FAILS, MUST SHOW A ERROR MESSAGE
    const bundle = this.product().bundle || null;
    // If the product already has a variantId, must use it (it happens in buy-again or favorites)
    let variant = this.product().packages?.length ? this.getVariantFromPackage() : this.getVariant();
    let variantId;
    if (variant == null) {
      variant = this.variant()
      variantId = variant.variantId
    } else {
      variantId = variant.id
    }

    if (!variant)
      return unableOperationMessage(this.modalContentService);

    if (!bundle && !variant?.stock)
      return unableOperationMessage(this.modalContentService);

    if (event.target.checked)
      this.productsService.setFavorite(variantId).pipe(
        tap(() => {
          this.variant.update((current: any) => {
            return { ...current, fav: true }
          });
          this.updateProductSignalFavValue(true);
        })
      ).subscribe();
    else
      this.productsService.removeFavorite(variantId).pipe(
        finalize(() => {
          this.variant.update((current: any) => {
            return { ...current, fav: false }
          });
          this.updateProductSignalFavValue(false);
        })
      ).subscribe();
  }

  private updateProductSignalFavValue(fav: boolean) {
    this.productsService.productSignal.update((current: any) => {
      const variantIdToUpdate = this.variant().variantId;
      let mainAttributeIndex = -1;
      if (variantIdToUpdate)
        mainAttributeIndex = current.attributes.findIndex((item: any) => item.values.some((value: any) => value.variantId === variantIdToUpdate));

      if (mainAttributeIndex > -1) {
        const mainAttribute = current.attributes[mainAttributeIndex]
        if (mainAttribute) {
          mainAttribute.values = mainAttribute.values.map((value: any) => {
            if (value.variantId == variantIdToUpdate) {
              return { ...value, fav }
            }
            return value;
          })
        }
        current.attributes[mainAttributeIndex] = mainAttribute
      }

      return current;

    })
  }

  getVariant() {
    let stock;

    stock = this.stockService.mapStockSignal();

    if (!stock?.size) return null;

    this.stockMapKey = this.getKeyForStockMap();
    const variant = stock.get(this.stockMapKey);
    if (variant != null) {
      variant.id = variant.variantId;
    }
    return variant ?? null;
  }

  getVariantFromPackage() {
    let stock = this.stockService.mapStockSignal();

    if (!stock) return null;

    this.stockMapKey = this.getKeyForStockMap();
    const variant = stock.get(this.stockMapKey);
    variant.id = variant.variantId;
    variant.attribute = this.selectedAttribute() || null
    return variant ?? null;
  }

  private getKeyForStockMap() {
    const productId = this.product().id;
    let key: any = productId;
    if (this.selectedAttribute().id && this.selectedAttribute().value?.id)
      key = `${productId}_${this.selectedAttribute().id}_${this.selectedAttribute().value.id}`;

    return key;
  }

  private checkFavProduct() {
    let isFavorite = false;
    isFavorite = !!this.product().fav

    if (this.variant()) {
      isFavorite = this.variant().fav
    }

    return isFavorite;
  }

  private setUpFavTotals() {
    const product = this.product();
    const variant = this.variant();
    if (variant)
      return variant.likeTotals || 0;

    return product.likeTotals || 0;
  }

  whatsInside(event: Event) {
    this.productCard()
      .openModalWhatsInside(this.productCard().card().product, event);
  }
}

<section class="layout-orders">

  <!-- Breadcrumbs -->
  <aside class="row">
    <div class="col">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a [routerLink]="['/shop']">
              <span class="material-symbols-outlined">home</span>
            </a>
          </li>
          <li class="breadcrumb-item active " aria-current="page">
            <span class="fw-bold">Orders</span>
          </li>
        </ol>
      </nav>
    </div>
  </aside>

  <!-- Tabs -->
  @defer (when isLoaded()){

  <!-- Mobile tabs -->
  @if (isMobile()) {
  <section class="layout-orders__tabs flex-wrap mb-3">
    @for (tab of options(); track tab) {
    <div class="tab flex-grow-1 rounded-4" (click)="prevSelectOrder(tab)">
      <span class="tab__title">
        {{tab.shortDeliveryDate}}
      </span>
      @if (tab.hasPendingChanges) {
      <div class="tab__icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="red"
          class="icon icon-tabler icons-tabler-filled icon-tabler-exclamation-circle">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M17 3.34a10 10 0 1 1 -15 8.66l.005 -.324a10 10 0 0 1 14.995 -8.336m-5 11.66a1 1 0 0 0 -1 1v.01a1 1 0 0 0 2 0v-.01a1 1 0 0 0 -1 -1m0 -7a1 1 0 0 0 -1 1v4a1 1 0 0 0 2 0v-4a1 1 0 0 0 -1 -1" />
        </svg>
      </div>
      }
    </div>
    }
  </section>
  }

  <section class="layout-orders__tabs">
    @for (tab of tabsInView(); track tab) {
    <div class="tab" [class.selected]="tab.selected" (click)="prevSelectOrder(tab)">
      <span class="tab__title">
        {{tab.shortDeliveryDate}}
      </span>
      @if (tab.hasPendingChanges) {
      <div class="tab__icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="red"
          class="icon icon-tabler icons-tabler-filled icon-tabler-exclamation-circle">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path
            d="M17 3.34a10 10 0 1 1 -15 8.66l.005 -.324a10 10 0 0 1 14.995 -8.336m-5 11.66a1 1 0 0 0 -1 1v.01a1 1 0 0 0 2 0v-.01a1 1 0 0 0 -1 -1m0 -7a1 1 0 0 0 -1 1v4a1 1 0 0 0 2 0v-4a1 1 0 0 0 -1 -1" />
        </svg>
      </div>
      }
    </div>
    }
  </section>
  } @placeholder {
  <section class="row">
    <div class="col-md-12">

      <div class="layout-orders__tabs placeholder-glow w-100">
        @for (tab of [0,0,0,0]; track tab) {
        <div class="tab gap-3 width-skeleton-tab">
          <span class="tab__title placeholder col-8">
          </span>
          <span class="tab__subtitle placeholder">
          </span>
        </div>
        }
      </div>

      <div class="d-flex flex-column gap-3 placeholder-glow w-100 height-100">
        <div class="col-4 placeholder"></div>
        <div class="col-3 placeholder"></div>
        <div class="col-2 placeholder"></div>
      </div>

    </div>
  </section>
  }

  <!-- Order -->
  @if (isOrderSelected()) {
  <section>
    <app-order #orderComponent [inpOdooOrder]="currentOrder()" [inpOrderSelected]="tabSelected()"
      [minOrder]="minOrder()"></app-order>
  </section>
  }

</section>
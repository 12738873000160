import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-how-it-works',
  standalone: true,
  imports: [CommonModule, RouterLink],
  templateUrl: './how-it-works.component.html',
  styleUrl: './how-it-works.component.scss'
})
export class HowItWorksComponent implements OnInit {

  private meta = inject(Meta);
  private title = inject(Title);

  weeklyGroceries = [
    {
      title: 'Bakery',
      img: '../../../assets/images/landing/dummy/weekly/1.webp',
      url: ''
    },
    {
      title: 'Fruits',
      img: '../../../assets/images/landing/dummy/weekly/2.webp',
      url: ''
    },
    {
      title: 'Prepared Food',
      img: '../../../assets/images/landing/dummy/weekly/3.webp',
      url: ''
    },
  ]

  foodItem = [
    {
      title: 'Bakery',
      tag: 'Farmer Market',
      img: '../../../assets/images/landing/dummy/box/1.webp',
      price: '$35 - $40',
      url: ''
    },
  ]

  ngOnInit(): void {
    this.setUpSEOTags();
  }

  private setUpSEOTags() {
    const { baseTitle, baseURL, type } = environment.config.seo;
    const url = 'pages/how-it-works'
    const component = 'How it Works'
    const title = `${component} | ${baseTitle}`;

    // Page title
    this.title.setTitle(title);

    // Open Graph for social networks:
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ property: 'og:url', content: `${baseURL}${url}` });
    this.meta.addTag({ property: 'og:type', content: type });
  }
}

import { Injectable, WritableSignal, inject, signal } from "@angular/core";
import { ApiService } from "../../../shared/api.service";
import { RequestHandlerParams } from "../../../shared/types/api-service.types";
import { finalize, tap } from 'rxjs';
import { ApiResponse } from "../../../shared/common/types";
import { ORDER_STATUS_CLASSES, PAYMENT_STATUS_CLASSES } from "../order-and-billing/order-and-billing.constants";
import { InvoiceHistory, InvoiceHistoryResponse } from "./invoice-history.types";

@Injectable()
export class InvoiceHistoryService {
    private apiService = inject(ApiService);

    private endpoints = {
        base: '/account/invoice-history',
    };

    invoiceHistory: WritableSignal<InvoiceHistory[]> = signal([]);
    invoicesLength: WritableSignal<number> = signal(0);

    getInvoiceHistory(page: number, length: number) {
        const params: RequestHandlerParams = {
            endpoint: `${this.endpoints.base}?page=${page}&len=${length}`,
            method: 'GET',
            apiV3: true,
            showErrorMessage: false
        };

        this.apiService.handleRequest<ApiResponse<InvoiceHistoryResponse>>(params).pipe(
            tap((response: ApiResponse<InvoiceHistoryResponse>) => this.setUpInvoiceHistoryData(response.data))
        ).subscribe();
    }

    private setUpInvoiceHistoryData(data: InvoiceHistoryResponse) {
        const invoices = data.invoices;
        this.invoiceHistory.set(invoices.map(e => {
            e.paymentStatusClass = PAYMENT_STATUS_CLASSES[e.paymentStatus?.toLowerCase()];
            return e;
        }));

        this.invoicesLength.set(data.length);
    }

    getInvoiceDetails(id: number) {
        const params: RequestHandlerParams = {
            endpoint: `${this.endpoints.base}/${id}`,
            method: 'GET',
            apiV3: true,
            showErrorMessage: false
        };

        return this.apiService.handleRequest<ApiResponse<{ invoiceURL: string }>>(params).pipe(
            tap((response: ApiResponse<{ invoiceURL: string }>) => window.open(response.data.invoiceURL))
        )
    }

}

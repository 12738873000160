<div class="nav-content animated fadeIn">
    <ul class="navbar-nav text-nowrap d-flex align-items-center justify-content-center categories-container w-100">
        <li class="nav-item d-flex justify-content-center align-items-center animated fadeInLeft search-wrap">
            <mat-form-field class="w-100 searchbar h-75">
                <mat-chip-grid #chipGrid aria-label="Enter fruits">
                    @for (filter of filters().entries(); track $index) {
                    <mat-chip-row
                        (removed)="remove(filter[0])"
                        [editable]="true"
                        class="bg-secondary bg-opacity-25">
                        <div class="d-flex gap-1">
                            <b>{{filter[1].type}}:</b> <span class="fst-italic text-decoration-underline">{{filter[1].value}}</span>
                        </div>
                        <button matChipRemove [attr.aria-label]="'remove ' + filter[0]">
                            <span class="material-symbols-outlined">
                                close
                            </span>
                        </button>
                    </mat-chip-row>
                    }
                    <input 
                        #searchInput 
                        class="flex-nowrap search-input" 
                        placeholder="Type to filter..." 
                        (keydown)="onInputKeydown($event)"
                        [matChipInputFor]="chipGrid" 
                        [(ngModel)]="searchBarContent" 
                        (input)="inputSearchTypeHandler($event)" />
                </mat-chip-grid>
            </mat-form-field>
            @if (searchBarContent().length > 0) {
            <ul class="list-group position-absolute w-100 z-3 bg-white mt-1 suggestion">
                @for (filterType of filterTypes.entries(); track $index) {
                <li
                    class="
                    list-group-item
                    list-group-item-action
                    cursor-pointer
                    d-flex
                    align-items-center
                    gap-2
                    animated
                    fadeIn"
                    [class.active]="selectedIndex === $index"
                    (click)="onFilterTypeSelected(filterType[0])">
                    Search <b class="text-link">{{filterType[0]}}</b> for: <span
                        class="text-decoration-underline fst-italic">{{searchBarContent()}}</span>
                </li>
                }
            </ul>
            }
        </li>
        <li class="nav-item">
            <div class="btn-group" role="group" aria-label="Basic example">
                <!-- <button class="btn btn-primary btn-sm" type="button" (click)="search()" title="Search">
                    <i class="fa-solid fa-magnifying-glass"></i>
                </button> -->
                <button class="btn btn-sm nav-category--icon" type="button" (click)="toggleSearchBar()">
                    <span class="material-symbols-outlined">close</span>
                </button>
            </div>
        </li>
    </ul>
</div>

<div class="product-card">
  <div class="product-card__head">
      <h3 class="product-card__title">{{card().product?.name}}</h3>
      <h5 class="product-card__subtitle">{{ card().product?.producer?.name}}</h5>
  </div>
  <div class="product-card__body">
    <a [routerLink]="['/product/'+card().product?.id]">
      <img [src]="defaultCard.product?.img || 'assets/images/product-placeholder.webp'" class="product-card__img">
    </a>
    <div class="product-card__meta">
      <div class="product-card__meta--item is-col-2">
        <div>
          <h6 class="product-card__title text-primary mb-0">{{ card().product?.price | currency }}</h6>
        </div>
        <div>
          <mat-form-field class="rounded-selector d-flex" subscriptSizing="dynamic">
            @if(hasSelectedCustomQuantity){
              <input type="text"
                     matInput
                     [disabled]="hasProductInCart()"
                     [value]="productInCart()?.quantity || selectedQuantity()"
                     (input)="onInputChange($event)"
              >
            }
            @else{
              <mat-select
                [disabled]="hasProductInCart()"
                [value]="productInCart()?.quantity || selectedQuantity()"
                (selectionChange)="onQuantityChange($event)"
              >
                @for(number of [1,2,3,4,5,6,7,8,9]; track $index){
                  <mat-option [value]="number">{{ number }}</mat-option>
                }
                <mat-option value="10">10+</mat-option>
              </mat-select>
            }
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
  <div class="product-card__action">
    <button class="btn btn-secondary" type="button">Add to cart</button>
  </div>
  <div class="product-card__foot is-col-2">
    <div>
      <!-- Tags Icons -->
      <ul class="product-card__tags">
        <li><img src="assets/images/product-card-tags/gluten-free.svg"  ngbTooltip="Gluten free" /></li>
        <li><img src="assets/images/product-card-tags/kosher.svg"  ngbTooltip="Kosher" /></li>
        <li><img src="assets/images/product-card-tags/organic.svg"  ngbTooltip="Organic" /></li>
        <li><img src="assets/images/product-card-tags/vegan.svg"  ngbTooltip="Vegan" /></li>
      </ul>
    </div>
    <div>
      <!-- Fav Icon -->
      <label [for]="'isFavorite'+card().product?.id" class="custom-checkbox float-end">
        <input type="checkbox" [id]="'isFavorite'+card().product?.id"/>
        <i class="fa-heart fa-regular text-danger"></i>
        <i class="fa-heart fa-solid text-danger"></i>
      </label>
      <small class="fs-6 mt-1 pt-1"></small>
      <p class="card-text lh-1 mb-0">
        <small class="text-body-secondary"></small>
      </p>
    </div>
  </div>
</div>

<section class="page-wrap animated fadeIn">
  <div class="container">
    <h1 class="section-title"><span>About Us</span></h1>
    <div class="row gy-3">
      <div class="col-lg-8 col-md-6 d-none d-sm-block">
        <div class="bg-image-wrap d-flex align-items-end justify-content-start p-3" style="background-image: url('assets/images/landing/about/AboutUs_1_v2-min.webp')">
          <div><div class="w-75 text-white h3-semibold">Mother Earth Food is committed to sustainable local and regional food.</div></div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="h-100 d-flex flex-column">
          <img src="assets/images/landing/about/AboutUs_2-min.webp" class="img-fluid" alt="Convenient home delivery">
          <div class="h4-regular w-10 d-flex flex-fill align-items-center">
            <p class="mb-0 py-3 text-justify">With <strong>easy online ordering and convenient home delivery,</strong> we bring
              peak-season produce and artisanal goods <strong>directly to your door, year-round!</strong></p>
          </div>
        </div>
      </div>
    </div>
    <h4 class="h4-regular my-5 text-justify">Since 2012, <strong>Mother Earth Food’s mission has been to cultivate a
      resilient food system in Western North Carolina.</strong> As a local, family-owned business, we're dedicated to
      sourcing and delivering the finest sustainable local and regional <strong>produce and artisanal goods directly to
        your door.</strong></h4>

    <div class="mb-5">
      <h2 class="section-title"><span>What Sets Us Apart</span></h2>
      <div class="row gy-3">
        <div class="col-md-3 col-sm-6" *ngFor="let item of flashCard">
          <div class="flash-card__portrait" style="background-image: url('assets/images/landing/{{item.img}}')">
            <div class="flash-card__title">{{item.title}}</div>
            <div class="flash-card--hover">
              <div class="flash-card__title">{{item.title}}</div>
              <div class="flash-card__desc" [innerHTML]="sanitizeHtml(item.desc)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <h2 class="section-title"><span>Our Community Partnerships</span></h2>
      <h4 class="h4-regular text-justify"><strong>Mother Earth Food is deeply committed to fostering strong partnerships with farmers, makers, and local organizations</strong> alike, all of whom share our unwavering dedication to sustainability and ethical sourcing. These valued collaborators prioritize the well-being of their land, products, and employees, reflecting the core values that define our mission.</h4>
      <p class="h5-regular my-5 text-justify">Beyond our connections with farmers and makers, we actively engage with a variety of local organizations, including: </p>
      <ul class="partner-logo">
        <li><img src="assets/images/landing/partners/1.webp" class="img-fluid" alt="ASAP Connections"></li>
        <li><img src="assets/images/landing/partners/2.webp" class="img-fluid" alt="Appalachian Wildlife Refuge"></li>
        <li><img src="assets/images/landing/partners/3.webp" class="img-fluid" alt="ABCCM - Asheville Buncombe Community Christian Ministry "></li>
        <li><img src="assets/images/landing/partners/4.webp" class="img-fluid" alt="Bountiful Cities"></li>
        <li><img src="assets/images/landing/partners/5.webp" class="img-fluid" alt="Children First/Communities In Schools of Buncombe County"></li>
        <li><img src="assets/images/landing/partners/6.webp" class="img-fluid" alt="Manna FoodBank"></li>
        <li><img src="assets/images/landing/partners/7.webp" class="img-fluid" alt="Meals on Wheels America"></li>
        <li><img src="assets/images/landing/partners/8.webp" class="img-fluid" alt="Organic Growers School"></li>
        <li><img src="assets/images/landing/partners/9.webp" class="img-fluid" alt="FEAST"></li>
      </ul>
      <p class="h5-regular my-5 text-justify">Through these collaborations and others, we further our commitment to community-driven initiatives and providing well-being for all.</p>
    </div>

  </div>
</section>

import { Component, inject, input } from '@angular/core';
import { ProductCardComponent } from '../../../shared/product-card/product-card.component';
import { Location } from '@angular/common';
import { LocalStorageService } from '../../../shared/local-storage.service';
import { LOCALSTORAGE_KEYS } from '../../../shared/constants/databases';

@Component({
  selector: 'app-subscriptions-signup',
  standalone: true,
  imports: [ProductCardComponent],
  templateUrl: './subscriptions-signup.component.html',
  styleUrl: './subscriptions-signup.component.scss'
})
export class SubscriptionsSignupComponent {
  private location = inject(Location);
  private localStorageService = inject(LocalStorageService);

  subscriptionCard: any = input();
  selectedCard: any = null;

  selectCard(card: any) {
    this.selectedCard = card;
  }

  continue() {
    this.localStorageService.set(LOCALSTORAGE_KEYS.NEW_ACCOUNT, true);
    this.localStorageService.remove(LOCALSTORAGE_KEYS.SIGNUP_CUSTOM_BOX);
    this.location.replaceState('/shop');
    window.location.reload();
  }
}
@if (!orderDetail()) {
	<mat-progress-bar class=" rounded layout-shop__progress-bar" mode="indeterminate" value="accent"></mat-progress-bar>
}
@if (orderDetail()) {
    <div class="container-fluid animated fadeIn" >
        <div class="d-flex justify-content-between">
            <h1 
                [class.text-center]="isMobile()"
                [class.w-100]="isMobile()">
                Order #{{orderDetail()?.number}}
            </h1>

            @if (!isMobile()) {
                <button class="btn btn-primary h-25" [disabled]="!orderDetail()?.payment?.invoiceURL" (click)="printInvoice()">Print Invoice</button>
            }
        </div>

        @if(isMobile()) {
            <button class="d-grid col-12 btn btn-primary h-25" [disabled]="!orderDetail()?.payment?.invoiceURL" (click)="printInvoice()">Print Invoice</button>
        }
    
        <div #contentToPrint>
            @if(!isMobile()) {
                <section class="d-grid col-12">
                    <div class="card border border-primary border-opacity-10 p-2">
                        <div class="row row-cols-5">
                            <div class="col px-4 py-1 d-grid gap-2">
                                <small class="text-body-tertiary">Order Status</small>
                                <span class="badge rounded-pill {{orderDetail()?.orderStatusClass}}">{{orderDetail()?.orderStatus}}</span>
                            </div>
                            <div class="col px-4 py-1 d-grid gap-2">
                                <small class="text-body-tertiary">Delivery Date</small>
                                <span class="fw-bold">{{orderDetail()?.deliveryDate}}</span>
                            </div>
                            <div class="col px-4 py-1 d-grid gap-2">
                                <small class="text-body-tertiary">Payment Status</small>
                                <span class="badge rounded-pill {{orderDetail()?.payment?.class}}">{{orderDetail()?.payment?.status}}</span>
                            </div>
                            <div class="col px-4 py-1 d-grid gap-2">
                                <small class="text-body-tertiary">Invoice Date</small>
                                <span class="fw-bold">{{orderDetail()?.payment?.date}}</span>
                            </div>
                            <div class="col px-4 py-1 d-grid gap-2">
                                <small class="text-body-tertiary">Payment Method</small>
                                <span class="fw-bold"><i class="fas fa-credit-card"></i> {{orderDetail()?.payment?.cardNumber}}</span>
                            </div>
                        </div>
                    </div>
                </section>
            
                <div class="row mt-4">
                    <div class="col table-responsive table-container overflow-y-auto">
                        <table class="table table">
                            <thead class="sticky-top">
                                <tr>
                                    <td class="border-0" scope="col" width="20%">Product Name</td>
                                    <td class="border-0" scope="col" width="20%">Bundle Items</td>
                                    <td class="border-0" scope="col" width="12%">Variant</td>
                                    <td class="border-0" scope="col" width="12%">Frequency</td>
                                    <td class="border-0" scope="col" width="12%">Quantity</td>
                                    <td class="border-0" scope="col" width="12%">Unit Price</td>
                                    <td class="border-0 text-end" scope="col" width="12%" >Total Price</td>
                                </tr>
                            </thead>
                            <tbody class="">
                                @for (product of orderDetail()?.products; track $index) {
                                    <tr class="fw-semibold">
                                        <td>
                                            <b>{{product.name}}</b>
                                        </td>
                                        <td>
                                            @if (product.itemsInView().length) {

                                                @for (item of product.itemsInView(); track $index) {
                                                    <div class="d-flex justify-content-between items" [class.border-items]="$index !== (product.items.length - 1)">
                                                        <span class=" h8-regular d-flex align-items-center gap-2">
                                                            {{item.fullName}}
                                                        </span>
                                                        <span class=" h8-regular d-flex align-items-center gap-3">
                                                            <span class="badge text-bg-primary rounded-pill">{{item.quantity}}</span>
                                                        </span>
                                                    </div>
                                                }
                                                @if (product.items.length > 3) {
                                                    <div class="row text-end mt-2">
                                                        <a class="cursor-pointer col-12 h8-semibold link-primary fst-italic" (click)="viewMoreItems(product)">
                                                            {{product.isViewAllItems() ? 'Show less items' : 'Show all items'}}
                                                        </a>
                                                    </div>
                                                }
                                            }
                                        </td>
                                        <td>
                                            <span class="fw-normal"><b>{{product.attributes[0].name? product.attributes[0].name + ':' : ''}}</b> {{product.attributes[0].value.name || ''}}</span>
                                        </td>
                                        <td>
                                            <span class="fw-normal">{{product?.frequency?.name || ''}}</span>
                                        </td>
                                        <td>
                                            <span class="fw-normal">{{product.quantity.requested}}</span>
                                        </td>
                                        <td>
                                            <b>{{product.unitPrice | currency}}</b>
                                        </td>
                                        <td class="text-end">
                                            <b>{{product.total | currency}}</b>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            } @else {
                <!-- Order details -->
                <h4 class="fw-bold my-3">Order Details</h4>
                <div class="vstack gap-3 animated fadeIn">
                    <div class="vstack gap-1">
                        <!-- Order status -->
                        <div class="hstack gap-2">
                            <section class="w-50">
                                <p class="m-0">Order Status</p>
                            </section>
                            <section class="w-50">
                                <span class="float-end badge rounded-pill {{orderDetail()?.orderStatusClass}}">{{orderDetail()?.orderStatus}}</span>
                            </section>
                        </div>
                        <!-- Delivery date -->
                        <div class="hstack gap-2">
                            <section class="w-50">
                                <p class="m-0">Delivery Date</p>
                            </section>
                            <section class="w-50">
                                <span class="float-end fw-normal">{{orderDetail()?.deliveryDate}}</span>
                            </section>
                        </div>
                        <!-- Payment status -->
                        <div class="hstack gap-2">
                            <section class="w-50">
                                <p class="m-0">Payment Status</p>
                            </section>
                            <section class="w-50">
                                <span class="float-end badge rounded-pill {{orderDetail()?.payment?.class}}">{{orderDetail()?.payment?.status}}</span>
                            </section>
                        </div>
                        <!-- Invoice Date -->
                        <div class="hstack gap-2">
                            <section class="w-50">
                                <p class="m-0">Invoice Date</p>
                            </section>
                            <section class="w-50">
                                <span class="float-end fw-normal">{{orderDetail()?.payment?.date}}</span>
                            </section>
                        </div>
                        <!-- Payment method -->
                        <div class="hstack gap-2">
                            <section class="w-50">
                                <p class="m-0">Payment Method</p>
                            </section>
                            <section class="w-50">
                                <span class="fw-bold float-end"><i class="fas fa-credit-card"></i> {{orderDetail()?.payment?.cardNumber}}</span>
                            </section>
                        </div>
                    </div>
                </div>
    
                <hr class="">
    
                <!-- Order products -->
                <h4 class="fw-bold mb-3">Items</h4>
                <div class="vstack gap-2 animated fadeIn w-100">
                    @for (product of orderDetail()?.products; track $index) {
                        <section class="vstack">
                            <section class="hstack">
                                <section class="vstack w-50">
                                    <b>{{product.name}}</b>
                                    <small>{{product.attributes[0].value.name || ''}}</small>
                                </section>    
                                <span class="text-end w-50">
                                    x{{product.quantity.requested}}
                                </span>
                            </section>

                            <section class="hstack">
                                <span class="w-50 ">{{product?.frequency?.name || '---'}}</span>
                                <span class="w-50 text-end">{{product.unitPrice | currency}}</span>
                            </section>

                            @if (product.items.length) {
                                <section class="hstack">
                                    <span class="w-50 ">Bundle Items ({{product.items.length}})</span>
                                    <span class="w-50 text-end">
                                        <a class="cursor-pointer col-12 h6-semibold link-primary fst-italic" (click)="viewMoreItems(product)">
                                            {{product.isViewAllItems() ? 'Hide Items' : 'Show Items'}}
                                        </a>
                                    </span>
                                </section>

                                @if (product.items.length && product.isViewAllItems()) {
                                    <ol class="list-group list-group-flush w-100 mt-2 rounded-3 animated fadeInDown">
                                        @for (item of product.items; track $index) {
                                            <li class="bg-primary bg-opacity-10 border-0 d-flex gap-4 list-group-item d-flex justify-content-between align-items-start">
                                                <div class="me-auto">
                                                    <div class="fw-normal">{{item.fullName}}</div>
                                                </div>
                                                <div class="d-flex gap-4">
                                                    <span class="text-end">x{{item.quantity}}</span>
                                                </div>
                                            </li>
                                        }
                                    </ol>
                                }

                            }
                        </section>
                        <hr class="m-0">
                    }
                  </div>
            }
    
            <!-- TOTALS -->
            <section class="vstack w-100 mt-4">
                @for (item of billingDetail(); track $index) {
                    <section class="hstack">
                        <span 
                            class="w-50"
                            [ngClass]="
                            {
                                'fw-bold': item.bold,
                                'text-secondary': item.greenColor
                            }">
                            {{item.name}}
                        </span>
                        <span 
                            class="w-50 text-end"
                            [ngClass]="
                            {
                                'fw-bold': item.bold, 
                                'text-secondary': item.greenColor
                            }">
                            {{item.amount| currency}}
                        </span>
                    </section>
                }
            </section>
    
        </div>
    </div>
}
import { Injectable, inject } from "@angular/core";
import { ReferralProgram } from "./friend-referrals.types";
import { filter, map } from "rxjs";
import { ApiService } from "../../../shared/api.service";
import { RequestHandlerParams } from "../../../shared/types/api-service.types";
import { ApiResponse } from "../../../shared/common/types";


@Injectable()
export class FriendReferralsService {

    private apiService = inject(ApiService);

    private endpoints = {
        base: '/account/friend-referrals'
    };

    get() {
        const params: RequestHandlerParams = {
            endpoint: this.endpoints.base,
            method: 'GET',
            apiV3: true
        };
        return this.apiService.handleRequest<ApiResponse<ReferralProgram>>(params).pipe(
            filter((res) => !!res?.data),
            map((res) => res?.data)
        );
    }
}
<div class="row gap-4">
  <section class="col-12">
      <h2 class="title-text">Forgot your password?</h2>
      <p>Please enter your email and we will email password reset instructions</p>
      <div class="form-floating has-validation">
        <input
          class="form-control"
          [class.is-invalid]="invalid() && email()"
          [class.is-valid]="!invalid() && email()"
          type="email"
          [(ngModel)]="email"
          (input)="validateEmail($event)"
          placeholder="Enter your email">
        <label>Enter your email</label>
        @if (email() && invalid()) {
          <div class="row">
            <div
              class="col-12 d-flex gap-2 align-items-center"
              [ngClass]="{'text-danger': invalid(), 'text-success': !invalid()}">
              <i
                [ngClass]="{
                              'fas': true,
                              'fa-xmark': invalid(),
                              'fa-check': !invalid()
                          }">
              </i>
              Please enter a valid email address
            </div>
          </div>
        }
      </div>

      <div class="d-grid col-12 mx-auto mt-3">
        <button class="btn btn-primary w-100" (click)="checkEmail()">
          RESET PASSWORD
        </button>
      </div>
      <div [routerLink]="['/login']" class="login__footer-action">
            <span class="action-icon material-symbols-outlined">
                west
            </span>
        <span class="action-label">Back to login</span>
      </div>
  </section>
</div>


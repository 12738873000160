import { Component, computed, inject } from '@angular/core';
import { SignalsStoreService } from '../signals-store.service';

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent {
  #signalsStoreService = inject(SignalsStoreService);
  loading: boolean = false;

  privacyPolicy = '';

  logoVersionNumber = computed(() => this.#signalsStoreService.logoVersionNumber());

  constructor() {
    fetch('assets/privacy-policy_src.html')
      .then(response => response.text())
      .then(htmlContent => this.privacyPolicy = htmlContent)
      .catch(error => console.error('Error loading HTML content:', error));
  }
}

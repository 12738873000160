import { Routes } from "@angular/router";
import { PagesComponent } from "./pages.component";
import { ProducersComponent } from "./producers/producers.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { AboutUsComponent } from "./about-us/about-us.component";
import { HowItWorksComponent } from "./how-it-works/how-it-works.component";
import { MembersComponent } from "./faq/members/members.component";
import { ProcurementStandardsComponent } from "./procurement-standards/procurement-standards.component";
import { DeliveryAreaComponent } from "./how-it-works/delivery-areas/delivery-area.component";
import { ProducerDetailComponent } from "./producers/producer-detail/producer-detail.component";
import { maintenanceGuard } from "../shared/guards/auth-guard.guard";
import { DonationsComponent } from "./about-us/donations/donations.component";
import { GiftCardsComponent } from "./gift-cards/gift-cards.component";

export const pagesRoutes: Routes = [
    {
        path: 'pages',
        title: 'Pages',
        component: PagesComponent,
        canActivate: [maintenanceGuard],
        canActivateChild: [maintenanceGuard],
        children: [
            {
                path: 'how-it-works',
                title: 'How it works',
                component: HowItWorksComponent
            },
            {
                path: 'delivery-areas',
                title: 'Delivery Area',
                component: DeliveryAreaComponent
            },
            {
                path: 'about-us',
                title: 'About Us',
                component: AboutUsComponent
            },
            {
                path: 'hurricane-relief',
                title: 'Hurricane Relief',
                component: DonationsComponent
            },
            {
                path: 'faq',
                title: 'FAQ',
                component: MembersComponent,
            },
            {
                path: 'procurement-standards',
                title: 'Procurement standards',
                component: ProcurementStandardsComponent,
            },
            {
                path: 'producers',
                title: 'Producers',
                component: ProducersComponent
            },
            {
                path: 'producers/:producerUrl',
                title: 'Producer Detail',
                component: ProducerDetailComponent
            },
            {
                path: 'contact-us',
                title: 'Contact Us',
                component: ContactUsComponent
            },
            {
                path: 'gift-cards',
                title: 'Gift Cards',
                component: GiftCardsComponent
            },
        ]
    },
];

<div class="container-fluid animated fadeIn" >
    <div class="d-flex flex-column flex-md-row justify-content-between settings">
        <h1 class="settings__tile animated fadeInDown">Invoice History</h1>
    </div>

    @if (invoiceHistory().length) {
        @if (!isMobile()) {
            <div class="row mt-4 animated fadeIn">
                <div class="col table-responsive table-container overflow-y-auto">
                    <table class="table align-middle">
                        <thead class="">
                            <tr>
                                <th class="border-0 text-body-tertiary" scope="col">Delivery Date</th>
                                <th class="border-0 text-body-tertiary" scope="col">Payment Status</th>
                                <th class="border-0 text-body-tertiary" scope="col">Total</th>
                                <th class="border-0 text-body-tertiary" scope="col"></th>
                            </tr>
                        </thead>
                        <tbody class="">
                            @for (invoice of invoiceHistory(); track $index) {
                            <tr class="fw-semibold">
                                <td>
                                    <span class="fw-normal">{{invoice.deliveryDate}}</span>
                                </td>
                                <td>
                                    <span class="badge rounded-pill {{invoice.paymentStatusClass}}">{{invoice.paymentStatus}}</span>
                                </td>
                                <td>
                                    <b>{{invoice.total | currency}}</b>
                                </td>
                                <td class="td-150">
                                    <button class="btn btn-secondary w-100" [disabled]="invoice.isLoading" (click)="goToInvoice(invoice)">Print Invoice</button>
                                    @if (invoice.isLoading) {
                                        <mat-progress-bar class=" rounded layout-shop__progress-bar" mode="indeterminate" value="accent"></mat-progress-bar>
                                    }
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        } @else {
            <div class="mt-3 vstack gap-3 animated fadeIn">
                @for (invoice of invoiceHistory(); track $index) {
                    <div class="vstack gap-1">
                        <div class="hstack gap-2">
                            <section class="w-50">
                                <p class="m-0">Delivery Date</p>
                            </section>
                            <section class="w-50">
                                <span class="float-end fw-normal">{{invoice.deliveryDate}}</span>
                            </section>
                        </div>
                        <!-- Payment status -->
                        <div class="hstack gap-2">
                            <section class="w-50">
                                <p class="m-0">Payment Status</p>
                            </section>
                            <section class="w-50">
                                <span class="float-end badge rounded-pill {{invoice.paymentStatusClass}}">{{invoice.paymentStatus}}</span>
                            </section>
                        </div>
                        <!-- Total -->
                        <div class="hstack gap-2">
                            <section class="w-50">
                                <p class="m-0">Total</p>
                            </section>
                            <section class="w-50">
                                <b class="float-end">{{invoice.total | currency}}</b>
                            </section>
                        </div>
                        <!-- actions -->
                        <div class="vstack gap-2 mt-2">
                            <button class="d-grid col-12 w-100 btn btn-outline-primary w-100" [disabled]="invoice.isLoading" (click)="goToInvoice(invoice)">Print Invoice</button>
                            @if (invoice.isLoading) {
                                <mat-progress-bar class=" rounded layout-shop__progress-bar" mode="indeterminate" value="accent"></mat-progress-bar>
                            }
                        </div>
                    </div>
                    <hr class="mt-2 mb-0">
                }
            </div>
        }

        <mat-paginator #paginator
               class="demo-paginator"
               (page)="handlePageEvent($event)"
               [length]="invoicesLength()"
               [pageSize]="pageSize"
               [showFirstLastButtons]="true"
               [hidePageSize]="false"
               [pageSizeOptions]="pageSizeOptions"
               [pageIndex]="pageIndex"
               aria-label="Select page">
        </mat-paginator>
        
    } @else {
        <app-empty-message [message]="emptyMessage"></app-empty-message>
    }
</div>

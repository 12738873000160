import { environment as env } from '../environments/environment';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withFetch, withInterceptors } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { httpInterceptor } from './shared/interceptors/http.interceptor';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideNativeDateAdapter } from '@angular/material/core';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideClientHydration(),
    provideAnimations(),
    provideNativeDateAdapter(),
    provideHttpClient(
      withFetch(),
      withInterceptors([httpInterceptor])
      ),
    importProvidersFrom([
      AngularFirestoreModule,
      AngularFireModule.initializeApp(env.apis.firebase),
    ]), provideAnimationsAsync()
  ]
};

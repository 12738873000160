import { Component, inject, computed, WritableSignal, signal, AfterViewChecked, AfterViewInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { AccountRecoveryService } from '../account-recovery.service';
import { NotificationService } from '../../../shared/notification/notification.service';
import { tap } from 'rxjs';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-check-code',
  standalone: true,
  imports: [FormsModule, RouterLink],
  templateUrl: './check-code.component.html',
})
export class CheckCodeComponent implements AfterViewInit {
  private accountRecoveryService = inject(AccountRecoveryService);
  private notificationService = inject(NotificationService);
  private router = inject(Router);

  code: WritableSignal<string> = signal('');
  email = computed(() => this.accountRecoveryService.email());

  ngAfterViewInit(): void {
    if (!this.email()) {
      this.router.navigate(['/account-recovery/check-email']);
    }
  }

  checkCode() {
    if (!this.code()) return this.notificationService.show({ text: 'Please, enter de verification code', type: 'warning' });
    this.accountRecoveryService.verifyCode(this.code()).pipe(
      tap(() => this.router.navigate(['/account-recovery/new-password']))
    ).subscribe();
  }
}

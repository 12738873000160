import { Component, computed, inject, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import { ProductsService } from '../../../product/products.service';
import { ProductCardComponent } from '../../../shared/product-card/product-card.component';

@Component({
  selector: 'app-donations',
  standalone: true,
  imports: [ProductCardComponent],
  templateUrl: './donations.component.html',
  styleUrl: './donations.component.scss'
})
export class DonationsComponent implements OnInit {
  private meta = inject(Meta);
  private title = inject(Title);
  #productsService = inject(ProductsService);

  #hurricaneProductURL = 'farm-boxes/farm-boxes/"build-your-own"-produce-box-|-small';

  productSignal = computed(() => this.#productsService.productSignal());

  ngOnInit(): void {
    this.setUpSEOTags();
    this.#getHurricaneProduct();
  }

  private setUpSEOTags() {
    const { baseTitle, baseURL, type } = environment.config.seo;
    const url = 'pages/hurricane-relief'
    const component = 'Hurricane Relief'
    const title = `${component} | ${baseTitle}`;

    // Page title
    this.title.setTitle(title);

    // Open Graph for social networks:
    this.meta.addTag({ property: 'og:title', content: title });
    this.meta.addTag({ property: 'og:url', content: `${baseURL}${url}` });
    this.meta.addTag({ property: 'og:type', content: type });
  }

  #getHurricaneProduct() {
    this.#productsService.getProductByUrl(this.#hurricaneProductURL).subscribe();
  }
}

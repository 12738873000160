@if(isMobile()){
<div class="mobile-nav-bottom">
  <button type="button" class="btn-action" routerLinkActive="active-link" routerLink="/shop">
    <span class="btn-action__icon material-symbols-outlined">nutrition</span>
    <span class="btn-action__text">Products</span>
  </button>
  <button type="button" class="btn-action" routerLinkActive="active-link" routerLink="/favorites">
    <span class="btn-action__icon material-symbols-outlined">favorite</span>
    <span class="btn-action__text">Favorites</span>
  </button>
  <button type="button" class="btn-action" routerLinkActive="active-link" routerLink="/buy-again">
    <span class="btn-action__icon material-symbols-outlined">verified</span>
    <span class="btn-action__text">Buy again</span>
  </button>
  <button type="button" class="btn-action" routerLinkActive="active-link" routerLink="/orders">
    <span class="btn-action__icon material-symbols-outlined">shopping_cart</span>

    <span class="btn-action__text">Cart</span>
    @if (showBadgeStatus()) {
    <div>
      @if (hasFirebaseOrders()) {
      <span class="quanity-badge red-quantity-badge">
        <span class="material-symbols-outlined">
          exclamation
        </span>
      </span>
      } @else if (hasOdooOrders()) {
      <span class="quanity-badge green-quantity-badge">
        <span class="material-symbols-outlined">
          check
        </span>
      </span>
      }
    </div>
    }
  </button>
</div>
}
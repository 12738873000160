import { Injectable, WritableSignal, inject, signal } from "@angular/core";
import { ApiService } from "../../shared/api.service";
import { delay, of, tap } from "rxjs";
import { NotificationService } from "../../shared/notification/notification.service";
import { RequestHandlerParams } from "../../shared/types/api-service.types";

@Injectable()
export class AccountRecoveryService {

    private apiService = inject(ApiService);
    private notificationService = inject(NotificationService);
    
    showSideLogo: WritableSignal<boolean> = signal(true);

    private endpoints = {
        base: '/account/recovery',
        generateCode: '/code',
        verifyCode: '/verify-code',
        password: '/update-password'
    }

    email: WritableSignal<string> = signal('');
    code: WritableSignal<string> = signal('');

    generateCode(email: string) {
        const params: RequestHandlerParams = {
            method: 'POST',
            endpoint: `${this.endpoints.base}${this.endpoints.generateCode}`,
            body: { email },
            apiV3: true
        };

        return this.apiService.handleRequest(params).pipe(
            tap((response: any) => {
                this.notificationService.show({ text: response.message, type: 'success' });
                this.email.set(email);
            })
        );
    }

    verifyCode(code: string) {
        const body = {
            code: code.trim(),
            email: this.email()
        };
        const params: RequestHandlerParams = {
            method: 'POST',
            endpoint: `${this.endpoints.base}${this.endpoints.verifyCode}`,
            body,
            apiV3: true
        };
        return this.apiService.handleRequest(params).pipe(
            tap((response: any) => {
                this.notificationService.show({ text: response.message, type: 'success' });
                this.code.set(code.trim());
            })
        );
    }

    resetPassword(password: string) {
        const body = {
            code: this.code(),
            email: this.email(),
            password
        };
        const params: RequestHandlerParams = {
            method: 'POST',
            endpoint: `${this.endpoints.base}${this.endpoints.password}`,
            body,
            apiV3: true
        };

        return this.apiService.handleRequest(params).pipe(
            tap((response: any) => this.notificationService.show({ text: response.message, type: 'success' }))
        );
    }

}

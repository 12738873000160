import { Injectable, WritableSignal, inject, signal } from "@angular/core";
import { MenuItem } from "./producers/interfaces";
import { RequestHandlerParams } from "../shared/types/api-service.types";
import { ApiService } from "../shared/api.service";
import { tap } from "rxjs";
import { ApiResponse } from "../shared/common/types";
import { NotificationService } from "../shared/notification/notification.service";

@Injectable()
export class PagesService {

    sideMenuSignal: WritableSignal<MenuItem[] | undefined> = signal(undefined);
    private apiService = inject(ApiService);
    private notificationService = inject(NotificationService);
    
    setSideMenu(menu: MenuItem[]) {
        this.restartSideMenu();
        this.sideMenuSignal.set(menu);
    }

    restartSideMenu() {
        this.sideMenuSignal.set(undefined);
    }

    contact(body: any) {
        const params: RequestHandlerParams = {
            endpoint: '/contact',
            method: 'POST',
            apiV3: true,
            showErrorMessage: true,
            body: body
        };

        return this.apiService.handleRequest<ApiResponse<any>>(params).pipe(
            tap((response: ApiResponse<any>) => this.notificationService.show({ text: response.message, type: 'success' }))
        );
    }
}
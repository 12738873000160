import { Component, Input, computed, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CartService } from '../cart.service';
import { ProductCardComponent } from '../product-card/product-card.component';
import { ProductsService } from '../../product/products.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-product-selection-list',
  standalone: true,
  imports: [RouterLink, ProductCardComponent, CommonModule],
  templateUrl: './product-selection-list.component.html',
  styleUrl: './product-selection-list.component.scss'
})
export class ProductSelectionListComponent {

  @Input() productListType = '';

  private cartService = inject(CartService);
  productsService = inject(ProductsService);

  productsInCart = computed(() => this.cartService.productsSignal()?.map ? this.cartService.productsSignal().map : new Map());
}

<form class="needs-validation">
    <p class="signup__sub-head-text text-center">How many people you usually shop for?</p>
    <div class="d-flex justify-content-center gap-5 mt-4 animated fadeIn">
        <div class="" *ngFor="let option of peopleOptions">
          <div class="form-floating">
              <div class="d-flex flex-column">
                <img [src]="option.img" alt="Option Image" class="option-image d-none d-lg-block">
              <div class="form-check form-check__highlight d-flex flex-row align-items-center mt-2 gap-2">
                <div class="check-1">
                  <input
                  class="form-check-input"
                  type="radio"
                  name="peopleOptions"
                  [id]="'option' + option.name"
                  [value]="option.id"
                  [ngModel]="personalInformation()?.shopfor"
                  (ngModelChange)="updatePersonalInformation({shopfor: $event })"
                />
                  <label class="form-check-label" [for]="'option' + option.name">
                    <small>{{ option.name }}</small>
                  </label>
                </div>
                </div>
              </div>
          </div>
        </div>
    </div>
        <div class="row form-row">
          @if (this.dietaryRestrictions?.length) {
            <div class="row form-row">
            <div class="col-12 mt-3 pt-5">
              <p class="signup__sub-head-text text-center">Please select from the options below to help us understand your dietary preferences and restrictions. Thank you for helping us serve you better:</p>
                <div class="d-flex flex-wrap gap-3 animated fadeIn justify-content-center">
                  @for (dietaryRestriction of this.dietaryRestrictions; track dietaryRestriction) {
                    <mat-checkbox
                      [(ngModel)]="dietaryRestriction.completed"
                      [ngModelOptions]="{standalone: true}"
                    >
                      {{ dietaryRestriction.name }}
                    </mat-checkbox>
                  }
                </div>
            </div>
            </div>
          }
        </div>
        <div class="form-row-action">
          <div class="d-grid gap-2 mt-4 col-md-2 col-12 mx-auto">
            <button
              class="btn btn-secondary"
              [disabled]="!isValidFormShoppingPreferences() || !firstStep.completed"
              (click)="createNewClient()">
              Continue
            </button>
          </div>
        </div>
  </form>

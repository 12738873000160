import { Injectable, WritableSignal, inject, signal } from "@angular/core";
import { ApiService } from "../../../shared/api.service";
import { filter, map, tap } from "rxjs";
import { PaymentMethod } from "../../../shared/account.service";
import { RequestHandlerParams } from "../../../shared/types/api-service.types";
import { ApiResponse } from "../../../shared/common/types";

@Injectable()
export class PaymentMethodService {

    private apiService = inject(ApiService);

    paymentMethod: WritableSignal<any> = signal(null);

    private endpoints = {
        paymentMethod: '/account/payment-method',
        states: '/states'
    }

    getPaymenthMethodInfo() {
        const params: RequestHandlerParams = {
            method: 'GET',
            endpoint: this.endpoints.paymentMethod,
            apiV3: true
        };

        this.apiService.handleRequest(params).pipe(
            filter((response: any) => response?.data),
            tap((response: any) => this.paymentMethod.set(response.data))
        ).subscribe();
    }

    getStates() {
        const params: RequestHandlerParams = {
            method: 'GET',
            endpoint: this.endpoints.states,
            apiV3: true,
            showErrorMessage: true
        };

        return this.apiService.handleRequest<ApiResponse<{ code: string, name: string }[]>>(params).pipe(
            filter(res => !!res.data?.length),
            map(res => res.data.map(e => { return { id: e.code, name: e.name } }))
        );
    }
}
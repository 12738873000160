import { PermissionNames } from "../shared/types/account.types";
import { SidebarSettingsParentPage } from "./settings.types";

export const SIDEBAR_SETTINGS_PAGES: SidebarSettingsParentPage[] = [
    {
        title: 'Account',
        display: true,
        permissionName: PermissionNames.settings,
        items: [
            {
                title: 'Profile',
                active: false,
                link: 'account/profile',
                display: true,
                permissionName: PermissionNames.settingsProfile,
            },
            {
                title: 'Subscriptions',
                active: false,
                link: 'account/subscriptions',
                display: true,
                permissionName: PermissionNames.settingsSubscription,
            },
            {
                title: 'Deliveries',
                title2: 'Pick-Ups',
                active: false,
                link: 'account/deliveries',
                display: true,
                permissionName: PermissionNames.settingsDeliveries,
            },
            {
                title: 'Friend Referrals',
                active: false,
                link: 'account/friend-referrals',
                display: true,
                permissionName: PermissionNames.settingsFriednReferrals,
            },
        ]
    },
    {
        title: 'Billing',
        display: true,
        permissionName: PermissionNames.settings,
        items: [
            {
                title: 'Payment Method',
                active: false,
                link: 'billing/payment-method',
                display: true,
                permissionName: PermissionNames.settingsPaymentMethod,
            },
            {
                title: 'Membership',
                active: false,
                link: 'billing/membership',
                display: true,
                permissionName: PermissionNames.settingsMemebership,
            },
            {
                title: 'Orders & Billing',
                active: false,
                link: 'billing/order-and-billing',
                display: true,
                permissionName: PermissionNames.settingsOrderHistory,
            },
            {
                title: 'Invoice History',
                active: false,
                link: 'billing/invoice-history',
                display: true,
                permissionName: PermissionNames.settingsInvoiceHistory,
            },
        ]
    }
]
<div class="container-fluid animated fadeIn" >
    <div class="d-flex flex-column flex-md-row justify-content-between settings">
        <h1 class="settings__tile animated fadeInDown">Order & Billing History</h1>
        <mat-form-field class="w-auto animated fadeInRight">
            <mat-label>Find Orders</mat-label>

            <mat-date-range-input [formGroup]="range" [rangePicker]="picker" disabled>
                <input matStartDate formControlName="start" placeholder="Start date" >
                <input matEndDate formControlName="end" placeholder="End date" >
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker disabled="false"></mat-date-range-picker>
        </mat-form-field>
    </div>

    <div class="d-grid cl-xl-4 col-lg-4 col-md-4 col-sm-12 col-12 mt-4 mb-4">
        <div class="alert bg-primary bg-opacity-25" role="alert">
            <div>
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="m-0">Available Credits</h5>
                    <i class="fas fa-circle-info" ngbTooltip="Credits will be applied to your orders automatically; check your order details for more information"></i>
                </div>
            <small class="m-0">{{credits() | currency}}</small>
            </div>
        </div>
    </div>


    @if (orderHistory().length) {
        @if (!isMobile()) {
            <div class="row mt-4 animated fadeIn">
                <div class="col table-responsive table-container overflow-y-auto">
                    <table class="table align-middle">
                        <thead class="">
                            <tr>
                                <th class="border-0 w-25 text-body-tertiary" scope="col">Order Number</th>
                                <th class="border-0 text-body-tertiary" scope="col">Order Status</th>
                                <th class="border-0 text-body-tertiary" scope="col">Delivery Date</th>
                                <th class="border-0 text-body-tertiary" scope="col">Payment Status</th>
                                <th class="border-0 text-body-tertiary" scope="col">Total</th>
                                <th class="border-0 text-body-tertiary" scope="col"></th>
                            </tr>
                        </thead>
                        <tbody class="">
                            @for (order of orderHistory(); track $index) {
                            <tr class="fw-semibold">
                                <td>
                                    <b>{{order.number}}</b>
                                </td>
                                <td>
                                    <span class="badge rounded-pill {{order.orderStatusClass}}">{{order.orderStatus}}</span>
                                </td>
                                <td>
                                    <span class="fw-normal">{{order.deliveryDate}}</span>
                                </td>
                                <td>
                                    <span class="badge rounded-pill {{order.paymentStatusClass}}">{{order.paymentStatus}}</span>
                                </td>
                                <td>
                                    <b>{{order.total | currency}}</b>
                                </td>
                                <td class="td-150">
                                    @if(!isLimitedUser()) {
                                        <button class="btn btn-primary w-100" (click)="openModalBuyAgain(order.id)">Buy Again</button>
                                    }
                                    <small><a href="javascript:void(0)" class="link-primary fw-bold float-end" (click)="goToOrderDetail(order.id)">Order Details ></a></small>
                                </td>
                            </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        } @else {
            <div class="mt-3 vstack gap-3 animated fadeIn">
                @for (order of orderHistory(); track $index) {
                    <div class="vstack gap-1">
                        <!-- Order number -->
                        <div class="hstack gap-2">
                            <section class="w-50">
                                <p class="m-0">Order Number</p>
                            </section>
                            <section class="w-50">
                                <b class="float-end">{{order.number}}</b>
                            </section>
                        </div>
                        <!-- Order status -->
                        <div class="hstack gap-2">
                            <section class="w-50">
                                <p class="m-0">Order Status</p>
                            </section>
                            <section class="w-50">
                                <span class="float-end badge rounded-pill {{order.orderStatusClass}}">{{order.orderStatus}}</span>
                            </section>
                        </div>
                        <!-- Delivery date -->
                        <div class="hstack gap-2">
                            <section class="w-50">
                                <p class="m-0">Delivery Date</p>
                            </section>
                            <section class="w-50">
                                <span class="float-end fw-normal">{{order.deliveryDate}}</span>
                            </section>
                        </div>
                        <!-- Payment status -->
                        <div class="hstack gap-2">
                            <section class="w-50">
                                <p class="m-0">Payment Status</p>
                            </section>
                            <section class="w-50">
                                <span class="float-end badge rounded-pill {{order.paymentStatusClass}}">{{order.paymentStatus}}</span>
                            </section>
                        </div>
                        <!-- Total -->
                        <div class="hstack gap-2">
                            <section class="w-50">
                                <p class="m-0">Total</p>
                            </section>
                            <section class="w-50">
                                <b class="float-end">{{order.total | currency}}</b>
                            </section>
                        </div>
                        <!-- actions -->
                        <div class="vstack gap-2 mt-2">
                            @if (!isLimitedUser()) {
                                <button class="d-grid col-12 w-100 btn btn-primary w-100" (click)="openModalBuyAgain(order.id)">Buy Again</button>
                            }
                            <button class="d-grid col-12 w-100 btn btn-outline-primary w-100" (click)="goToOrderDetail(order.id)">Order Details</button>
                        </div>
                    </div>
                    <hr class="mt-2 mb-0">
                }
            </div>
        }
    } @else {
        <app-empty-message [message]="emptyMessage"></app-empty-message>
    }
</div>

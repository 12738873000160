import { Component, AfterViewInit, ViewChild, inject, signal, WritableSignal, computed, Inject, PLATFORM_ID, ChangeDetectorRef } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStep, MatStepper, MatStepperModule } from '@angular/material/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AddressService, NavegoAddressResponse } from '../../shared/address.service';
import { PersonalInformation } from './signup.types';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { AccountService } from '../../shared/account.service';
import { RouterLink } from '@angular/router';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { CUSTOM_DATE_FORMATS } from '../../shared/constants/date-formtats';
import { LocalStorageService } from '../../shared/local-storage.service';
import { SignalsStoreService } from '../../shared/signals-store.service';
import { Session } from '../../shared/types/session.type';
import { LOCALSTORAGE_KEYS } from '../../shared/constants/databases';
import { ArrayOptionsItem, NewAccountPayload, AccountSettings, DeliveryOptionsResponseItem } from '../../shared/types/account.types';
import { PaymentMethodService } from '../../settings/billing/payment-method/payment-method.service';
import { MatRadioModule } from '@angular/material/radio';
import { PersonalInformationSignupComponent } from './personal-information-signup/personal-information-signup.component';
import { PreferencesValidationSignupComponent } from './preferences-validation-signup/preferences-validation-signup.component';
import { CheckAddressSignupComponent } from './check-address-signup/check-address-signup.component';
import { PaymentMethodSignupComponent } from './payment-method-signup/payment-method-signup.component';
import { SubscriptionsSignupComponent } from './subscriptions-signup/subscriptions-signup.component';
import { ProfileService } from '../../settings/account/profile/profile.service';
import { finalize, tap } from 'rxjs';
import { ProductsService } from '../../product/products.service';
import { DeliveriesService } from '../../settings/account/deliveries/deliveries.service';

@Component({
  selector: 'app-signup',
  standalone: true,
  providers: [provideNativeDateAdapter(),
  { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS.MONTH_AND_YEAR },
    PaymentMethodService,
    ProfileService
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIcon,
    MatSelectModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatProgressBarModule,
    RouterLink,
    MatRadioModule,
    CheckAddressSignupComponent,
    PersonalInformationSignupComponent,
    PreferencesValidationSignupComponent,
    PaymentMethodSignupComponent,
    SubscriptionsSignupComponent
  ],
  templateUrl: './signup.component.html',
  styleUrl: './signup.component.scss',
})

export class SignupComponent implements AfterViewInit {
  public localStorageService = inject(LocalStorageService);
  private signalsStoreService = inject(SignalsStoreService);
  private addressService = inject(AddressService);
  private profileService = inject(ProfileService);
  private productsService = inject(ProductsService);
  #deliveriesService = inject(DeliveriesService);
  accountService = inject(AccountService);
  cdr = inject(ChangeDetectorRef)

  @ViewChild('stepper', { static: false }) matStepper!: MatStepper;
  @ViewChild('firstStep', { static: false }) firstStep!: MatStep;
  formValidityPersonalInformation: boolean | undefined;
  formValidityShoppingPreferences: boolean | undefined;
  formValidityPaymentMethod: boolean | undefined;
  deliveryAddressInformation: any;
  deliverySelectedDay: any;
  deliverySelectedTimeWindow: any;
  deliveryOptionSelected: any;
  personalInformationData: any;
  shopforValue: any;
  dietaryRestrictionsValues: any;

  paymentMethodStepControl: FormControl = new FormControl(true);

  personalInformation: WritableSignal<PersonalInformation | null> = signal(null)
  skipPaymentMethodStep: boolean = false;

  isLoadingContent: any = this.addressService.isLoading
  isWaitingResponse: any = signal(false)

  shouldDisableCreateClientButton = signal(false);

  shouldSetUpSettings: boolean = true;

  subscriptionCard: WritableSignal<any[]> = signal([])

  logoVersionNumber = computed(() => this.signalsStoreService.logoVersionNumber());

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngAfterViewInit() {
    if (!isPlatformBrowser(this.platformId)) return;

    const signupSelectedMembership: any = this.localStorageService.get(LOCALSTORAGE_KEYS.SIGNUP_SELECTED_MEMBERSHIP);
    if (signupSelectedMembership?.id)
      this.deliveryOptionSelected = signupSelectedMembership;

    const sessionStored: Session = this.localStorageService.get(LOCALSTORAGE_KEYS.SESSION) as any
    const hasPaymentMethod = !!(sessionStored)?.settings?.hasPaymentMethod;
    const stepToLoad = this.signalsStoreService.hasSession() && !hasPaymentMethod ? 3 : this.signalsStoreService.hasSession() && hasPaymentMethod ? 4 : 0;
    // Load first step or payment method step if hasSession
    if (stepToLoad > 0) {
      for (let index = 0; index < stepToLoad; index++) {
        this.matStepper.steps.toArray()[index].completed = true;
      }
    }

    // Get suscription singup products:

    if (stepToLoad === 4) {
      this.getSignupProducts();
      this.#deliveriesService.getDeliveryZoneInfo().subscribe();
    }

    this.matStepper.selectedIndex = stepToLoad;

    this.cdr.detectChanges()
  }


  createNewClient() {
    if (this.shouldDisableCreateClientButton()) return;
    this.shouldDisableCreateClientButton.set(true);
    const payload = this.setUpNewClientPayload();
    if (!payload) return;
    this.accountService.create(payload).pipe(
      finalize(() => this.shouldDisableCreateClientButton.set(false))
    ).subscribe({
      next: (data: Session) => {
        if (data?.isTimeSlotCapacityFull) {
          this.firstStep.editable = true;
          this.matStepper.previous();
        } else if (data) {
          this.#deliveriesService.getDeliveryZoneInfo().subscribe();
          this.matStepper.next();
          this.shouldDisableCreateClientButton.set(false);
        }
      }
    });
  }

  private setUpNewClientPayload(): NewAccountPayload | null {
    try {
      const { firstName, lastName, phone, email, password, birthday, advertisement, dietaryRestrictions = this.dietaryRestrictionsValues, contactPerson: contact, receiveNewsletter, receiveText, receiveTextDelivery } = this.personalInformationData() || {};
      const { additionalAddress: additional, zipCode: zip, deliveryNotes: noteDriver } = this.deliveryAddressInformation()
      const { street, zoneId, zoneName, city, state: stateCode, latitude, longitude } = this.addressService.navegoAddress() as NavegoAddressResponse || {}
      const res = {
        accountInfo: {
          dietaryPreferences: dietaryRestrictions?.filter((value: any) => value.completed).map((value: any) => { return { id: value.id, name: value.name } }) || null,
          email,
          firstName,
          lastName,
          noteDriver,
          password,
          phone,
          birthday
        },
        settings: {
          receiveNewsletter,
          receiveText,
          receiveTextDelivery,
          delivery: {
            type: this.deliveryOptionSelected.id,
            dayId: this.deliverySelectedDay.id,
            timeWindowId: this.deliverySelectedTimeWindow === -1 ? null : this.deliverySelectedTimeWindow
          },
          peopleShoppingForId: this.shopforValue
        },
        contact
      } as NewAccountPayload

      if (this.deliveryOptionSelected.configuration.requireAddress) {
        res.address = {
          additional: additional,
          city: city,
          latitude: latitude,
          longitude: longitude,
          stateCode: stateCode,
          street: street,
          zip: zip,
          zoneId: zoneId || this.deliverySelectedDay.zoneId
        }
      } else {
        res.address = {
          zoneId: this.deliverySelectedDay.zoneId
        }
      }

      return res;
    } catch (error) {
      console.log('error >>>', error);
      return null;
    }
  }

  updateCreatedClient() {
    if (this.matStepper.selectedIndex === 2 && !this.deliveryOptionSelected.configuration.requirePaymentMethod
    ) {
      this.paymentMethodStepControl.disable();
      this.skipPaymentMethodStep = true;
      this.matStepper.selectedIndex = 3;
      this.getSignupProducts();
      this.matStepper.next();
    } else {
      this.accountService.setMustFetchStates();
    }
    const payload = this.setUpUpdateClientPayload();
    this.profileService.putPreferences(payload).pipe(
      tap(() => {
        this.matStepper.next();
      })
    ).subscribe()
  }

  private setUpUpdateClientPayload(): any | null {
    try {
      const { dietaryRestrictions = this.dietaryRestrictionsValues } = this.personalInformationData() || {};
      return {
        accountInfo: {
          dietaryPreferences: dietaryRestrictions?.filter((value: any) => value.completed).map((value: any) => { return { id: value.id, name: value.name } }) || null,
        },
        settings: {
          peopleShoppingForId: this.shopforValue
        }
      } as any
    } catch (error) {
      console.log('error >>>', error);
      return null;
    }
  }


  continue(customBoxStep: boolean = false) {
    if (customBoxStep) this.getSignupProducts();
    this.firstStep.completed = true
    this.matStepper.next();
    if (this.matStepper.selectedIndex === 1) {
      this.firstStep.editable = false;
      if (this.shouldSetUpSettings) {
        this.getAccountSettings();
        this.shouldSetUpSettings = false;
      }
    }
  }

  private getSignupProducts() {
    this.productsService.getSignupProducts().pipe(
      tap((response) => {
        this.subscriptionCard.set(response.data)
      })
    ).subscribe()
  }

  private getAccountSettings() {
    this.accountService.getAccountSettings().subscribe({
      next: (accountSettings: AccountSettings | null) => {
        if (accountSettings) {
          this.setUpAccountSettings(accountSettings);
        }
      }
    });

  }

  private setUpAccountSettings(accountSettings: AccountSettings) {
    this.personalInformation.set({
      contactPerson: {
        email: null,
        firstName: null,
        lastName: null,
        phone: null,
      },
      dietaryRestrictions: accountSettings.dietaryPreferences?.map((preference: ArrayOptionsItem) => {
        return { ...preference, completed: false };
      }) || [],
      peopleShoppingFor: accountSettings.peopleShoppingFor || [],
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      phone: '',
      referralSource: '',
      advertisement: '',
      shopfor: null,
      receiveNewsletter: true,
      receiveText: true
    });
  }

}

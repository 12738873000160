import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-password-restored',
  standalone: true,
  imports: [RouterLink],
  template: `
    <div class="container text-center px-5">
      <div class="row gap-4">
          <section class="col-12">
              <h1 class="fw-bolder">Password reset</h1>
              <p>Your password has been successfully reset</p>
          </section>
          <section class="d-grid col-12 mx-auto">
              <button class="btn btn-primary w-100" [routerLink]="['/login']">
                  LOG IN
              </button>
          </section>
      </div>
  </div>
`
})
export class PasswordRestoredComponent { }

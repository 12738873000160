import { Routes } from "@angular/router";
import { AccountRecoveryComponent } from "./account-recovery.component";
import { CheckEmailComponent } from "./check-email/check-email.component";
import { CheckCodeComponent } from "./check-code/check-code.component";
import { NewPasswordComponent } from "./new-password/new-password.component";
import { PasswordRestoredComponent } from "./password-restored/password-restored.component";
import { NewEcommerceExperienceComponent } from "./new-ecommerce-experience/new-ecommerce-experience.component";
import { maintenanceGuard } from "../../shared/guards/auth-guard.guard";

export const accountRecoveryRoutes: Routes = [
    {
        path: 'account-recovery',
        title: 'Account recovery',
        component: AccountRecoveryComponent,
        canActivate: [maintenanceGuard],
        canActivateChild: [maintenanceGuard],
        children: [
            {
                path: 'new-ecommerce',
                title: 'Welcome to Our New e-Commerce',
                component: NewEcommerceExperienceComponent
            },
            {
                path: 'check-email',
                title: 'Check email',
                component: CheckEmailComponent
            },
            {
                path: 'check-code',
                title: 'Check code',
                component: CheckCodeComponent
            },
            {
                path: 'new-password',
                title: 'New password',
                component: NewPasswordComponent
            },
            {
                path: 'password-restored',
                title: 'Password restored',
                component: PasswordRestoredComponent
            },
        ]
    }
]
<div class="container text-center px-5">
    <div class="row gap-4">
        <section class="col-12">
            <h1 class="fw-bolder">Set new password</h1>
            <p>Your new password must be different to your previously used passwords</p>
        </section>
        @for (input of inputConfig; track $index) {
            <div class="col-12">
                <div class="form-floating has-validation">
                    <input
                        class="form-control custom-validation-control"  
                        [class.is-invalid]="input.isInvalid"
                        [class.is-valid]="!input.isInvalid && input.ngModel()"
                        [type]="input.hidePassword() ? 'password' : 'text'"
                        placeholder="{{input.label}}"
                        [(ngModel)]="input.ngModel"
                        [disabled]="input.disabled"
                        (input)="validatePassword($event, input)">
                    <label>{{input.label}}</label>
                    @if (input.hidePassword()) {
                        <span class="material-symbols-outlined text-body-tertiary show-password-icon" (click)="togglePasswordVisibility(input)">
                            visibility
                        </span>
                    } @else {
                        <span class="material-symbols-outlined text-body-tertiary show-password-icon" (click)="togglePasswordVisibility(input)">
                            visibility_off
                        </span>
                    }
                    @if (input.ngModel()) {
                        <div class="row">
                            @for(validation of input.passwordValidations; track $index) {
                                <div 
                                    class="col-12 d-flex gap-2 align-items-center text-start"
                                    [ngClass]="{'text-danger': validation.error, 'text-success': !validation.error}">
                                    <i 
                                        [ngClass]="{
                                            'fas': true,
                                            'fa-xmark': validation.error,
                                            'fa-check': !validation.error
                                        }">
                                    </i>
                                    {{validation.label}}
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        }
        <section class="d-grid col-12 mx-auto">
            <button class="btn btn-primary w-100" (click)="resetPassword()">
                RESET PASSWORD
            </button>
            <a href="javascript:void(0)" [routerLink]="['/login']" class="link-primary link-underline-opacity-0 w-100 d-flex align-items-center justify-content-end gap-2">
                <span class="material-symbols-outlined">
                    west
                </span>
                Back to login
            </a>
        </section>
    </div>
</div>